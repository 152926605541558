import React, { Fragment } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import StepZilla from "react-stepzilla";
import Source from "./Source";
import Target from "./Target";
import MigrationConfiguration from "./MigrationConfiguration";
import { useParams } from "react-router";
import axios from "axios";
import { setToast } from "../../../components/common/Toast";
import { toast } from "react-toastify";

export const defaultConnectionParameters = {
  host: "",
  port: 5432,
  databaseName: "",
  dbVersion: ""
};

const CreatePostgres2PostgresMigration = () => {
  const [sourceConnectionParameters, setSourceConnectionParameters] = React.useState(defaultConnectionParameters);
  const [targetConnectionParameters, setTargetConnectionParameters] = React.useState(defaultConnectionParameters);
  const [migrationConfiguration, setMigrationConfiguration] = React.useState({});

  const { id } = useParams();

  React.useEffect(() => {
    if (id) {
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/postgres-2-postgres/migrations/${id}`)
        .then((res) => {
          setSourceConnectionParameters(res.data.sourceConnectionParameters);
          setTargetConnectionParameters(res.data.targetConnectionParameters);
          setMigrationConfiguration(res.data.migrationConfiguration);
        })
        .catch(() => {
          setToast(toast.error, "Unable to retrieve migration details");
          window.location.assign("/database-factory/postgres-to-postgres");
        });
    }
  }, [id]);

  const steps =
    [
      {
        name: "Source",
        component: <Source
          sourceConnectionParameters={sourceConnectionParameters}
          setSourceConnectionParameters={setSourceConnectionParameters}
        />
      },
      {
        name: "Target",
        component: <Target
          targetConnectionParameters={targetConnectionParameters}
          setTargetConnectionParameters={setTargetConnectionParameters}
        />
      },
      {
        name: "Migration Configuration",
        component: <MigrationConfiguration
          sourceConnectionParameters={sourceConnectionParameters}
          targetConnectionParameters={targetConnectionParameters}
          migrationConfiguration={migrationConfiguration}
          setMigrationConfiguration={setMigrationConfiguration}
          id={id}
        />
      }
    ];

  return (
    <Fragment>
      <Breadcrumb title="Create Migration" parent="Postgres To Postgres" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Create Postgres To Postgres Migration</h5>
              </div>
              <div className="card-body">
                <StepZilla
                  steps={steps}
                  showSteps={true}
                  showNavigation={true}
                  stepsNavigation={true}
                  prevBtnOnLastStep={true}
                  dontValidate={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CreatePostgres2PostgresMigration;