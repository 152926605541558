import React from "react";

const FilterComponent = ({ filterText, onFilter, onClear, placeholder }) => (
  <div className="row">
    <div className="col-8">
      <input
        className="form-control-custom"
        id="search"
        type="text"
        placeholder={`Filter ${placeholder}`}
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
      />
    </div>
    <div>
      <button id="clear-filter" className="btn btn-primary" onClick={onClear}>
        Clear
      </button>
    </div>
  </div>
);
export default FilterComponent;