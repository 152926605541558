import React from "react";
import Modal from "react-responsive-modal";

const questions = [
  "What is the estimated total number of active concurrent users for this application? (Translated as a % of total user base)",
  "What is the quantity of application objects/modules with unsupported technologies?",
  "Roughly what percentage of the  Technology Stack is  close to End of Life or at End of Life?",
  "How critical is the application to the business? (i.e. The impact if the application is not available)",
  "How was the the availability of the application during the previous 12 months?",
  "Does a remediation Roadmap/Plan to patch and retain exist?",
  "Is the application of strategic importance? Does the application support the core business?",
  "Do other applications have affinity with, or are dependent upon data/traffic produced by, this application?",
  "What is the estimated effort for remediating this application to replace unsupported technology",
  "Is Funding Available to Modernize/Transform (Re-Architect, Refactor or Re-Platform)?  Or Repurchase?",
  "Is Funding Available to Remediate & Retain (and removing all unsupported technology)?",
  "Is there a Plan to Retire the Application?",
  "Are skills and knowledge to support, develop and maintain the application available?",
  "What is the SLA/RTO for this application?",
  "What is the total cost of ownership (TCO) of the application? (Add Support, Maintenance and Licensing together and translate as a % of total IT budget for the dept/unit/business)",
  "Is the application a COTS or internally developed (bespoke) application?",
  "If the application is provided by a third party (COTS,SaaS, etc.), does your company control the source code and is the app older than 10 years?",
  "Is there an agreed strategy for the evolution of each application? Has the client made a decision to move forward with a modernization approach already?",
  "Does the application support hosting in the cloud?",
  "What is the user population of the department/unit/business/organisation where the application is used?",
  "What is the IT Budget of the depertment/unit/business/organisation where the application is used?"
];

const SurveyModal = (props) => {
  return (
    <Modal open={props.openResult} onClose={() => props.setOpenResult(false)}>
      <div className="container-fluid">
        <div className="row">
          <h6 className="f-w-600 text-align-centre">Survey Data</h6>
          <div className="col-sm-12">
            <div className="card">
              <div id="assessment-rating" className="card-header" style={{ display: "inline-flex" }}>
                <h1 className="text-align-centre">Result: </h1><h1 style={{ color: "red", paddingLeft: "0.5rem" }}>{props.rating}</h1>
              </div>
              <div className="card-body">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Question</th>
                      <th scope="col">Answer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      props.completedAssessment !== undefined && (
                        Object.values(props.completedAssessment).slice(0, 20).map((value, index) => {
                          return (
                            <tr key={index}>
                              <td>{questions[index]}</td>
                              <td>{props.completedAssessment[`question${index + 1}`][`Q${index + 1}`]["Answer"]}</td>
                            </tr>
                          );
                        })
                      )
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SurveyModal;