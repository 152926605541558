import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import StepZilla from "react-stepzilla";
import ConfigureResource from "./ConfigureResource";
import SelectNetwork from "./SelectNetwork";
import Review from "./Review";
import { FormWizardWithIcon } from "../../../constant";
import { useParams } from "react-router";
import axios from "axios";

const OrderAzureDevopsGCPBuild = () => {  
  const { id } = useParams();
  const [vmName, setVmName] = useState("");
  const [instanceMachineType, setInstanceMachineType] = useState("");
  const [numberOfInstances, setNumberOfInstances] = useState(1);
  const [region, setRegion] = useState("");
  const [networkName, setNetworkName] = useState("");
  const [networkSubnet, setNetworkSubnet] = useState("");
  const [orderName, setOrderName] = useState("");
  const [action, setAction] = useState("");

  useEffect(() => {
    if (id) {
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/orders/get-azuredevopsvm-order/${id}`)
        .then((res) => {
          setVmName(res.data.order.vmName);
          setInstanceMachineType(res.data.order.instanceMachineType);
          setNumberOfInstances(res.data.order.numberOfInstances);
          setRegion(res.data.order.region);
          setNetworkName(res.data.order.networkName);
          setNetworkSubnet(res.data.order.networkSubnet);
          setOrderName(res.data.name);
          setAction(res.data.action);
        })
        .catch((err) => {
          console.log(err);
          window.location = "/infrastructure/google/azuredevops-gcp-vm";
        });
    }
  }, [id]);

  useEffect(() => {
    if (networkName === "" || region === "") {
      return;
    }

    setNetworkSubnet(`${networkName}-${region}-vpc`);
  }, [networkName, region]);

  const steps =
    [
      {
        name: "Configure Resource",
        component: <ConfigureResource
          vmName={vmName}
          setVmName={setVmName}
          instanceMachineType={instanceMachineType}
          setInstanceMachineType={setInstanceMachineType}
          numberOfInstances={numberOfInstances}
          setNumberOfInstances={setNumberOfInstances}
        />
      },
      {
        name: "Select Network",
        component: <SelectNetwork
          region={region}
          setRegion={setRegion}
          networkName={networkName}
          setNetworkName={setNetworkName}
          networkSubnet={networkSubnet}
          setNetworkSubnet={setNetworkSubnet}
        />
      },
      {
        name: "Review",
        component: <Review
          vmName={vmName}
          instanceMachineType={instanceMachineType}
          numberOfInstances={numberOfInstances}
          region={region}
          networkName={networkName}
          networkSubnet={networkSubnet}
          orderName={orderName}
          setOrderName={setOrderName}
          action={action}
          setAction={setAction}
          id={id}
        />
      }
    ];

  return (
    <Fragment>
      <Breadcrumb title="Order" parent="GCP Build" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{FormWizardWithIcon}</h5>
              </div>
              <div className="card-body">
                <StepZilla
                  steps={steps}
                  showSteps={true}
                  showNavigation={true}
                  stepsNavigation={true}
                  prevBtnOnLastStep={true}
                  dontValidate={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OrderAzureDevopsGCPBuild;