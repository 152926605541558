import React, { Fragment } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { OracleWebAppGoogle, OracleWebAppAWS, OracleWebAppAKS } from "../../constant";

const handleClickGoogle = () => {
  window.open("https://react-app-dev.kong.uk.datahubnow.com/", "_blank");
};

const handleClickAWS = () => {
  window.open("https://react-app-dev-eks.uk.datahubnow.com/", "_blank");
};

const handleClickAKS = () => {
  window.open("https://react-app-dev.aks.uk.datahubnow.com/", "_blank");
};

const OracleWebApplication = () => {
  return (
    <Fragment>
      <Breadcrumb title="Oracle Web Application" parent="API" />
      <div className="container-fluid">
        <div className="card-body btn-showcase">
          <button id="google-application" className="btn btn-pill btn-primary btn-air-primary" onClick={handleClickGoogle} type="button">{OracleWebAppGoogle}</button>
          <button id="aws-application" className="btn btn-pill btn-primary btn-air-primary" onClick={handleClickAWS} type="button">{OracleWebAppAWS}</button>
          <button id="aks-application" className="btn btn-pill btn-primary btn-air-primary" onClick={handleClickAKS} type="button">{OracleWebAppAKS}</button>
          <hr />
        </div>
      </div>
    </Fragment>
  );
};

export default OracleWebApplication;