import React, { Fragment, Children, isValidElement, cloneElement } from "react";

const ComponentLayout = ({ children, section }) => {
  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, { section });
    }

    return child;
  });

  return (
    <Fragment>
      {childrenWithProps}
    </Fragment>
  );
};

export default ComponentLayout;