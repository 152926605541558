import React, { Fragment } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import Breadcrumb from "../../components/common/breadcrumb";
import FilterComponent from "../../components/common/FilterComponent";
import { tableStyles } from "../../components/common/CustomStyles";
import { setToast } from "../../components/common/Toast";
import { toast } from "react-toastify";

const ServerPowerRatings = () => {
  const [serverData, setServerData] = React.useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");
  const [pending, setPending] = React.useState(true);

  const getServerPower = React.useCallback(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/sustainability/server-power-rating`)
      .then((response) => {
        setServerData(response.data);
        setPending(false);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve server power ratings");
        setPending(false);
      });
  }, []);

  React.useEffect(() => {
    setPending(true);
    getServerPower();
  }, [getServerPower]);

  const filteredItems = serverData.filter(
    (item) => item.Manufacturer && item.Manufacturer.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholder={"Manufacturer"} />
    );
  }, [filterText, resetPaginationToggle]);

  /* istanbul ignore next */
  const columns = [
    {
      name: "Manufacturer",
      selector: (server) => server.Manufacturer,
      center: true,
      wrap: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Type",
      selector: (server) => server.Type,
      center: true,
      wrap: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Model",
      selector: (server) => server.Model,
      center: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "PSU Power Watts Rating",
      selector: (server) => server.PSUPowerWattsRating,
      center: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Max Rating Or Operational",
      selector: (server) => server.MaxRatingOrOperational,
      center: true,
      wrap: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Idle Rating",
      selector: (server) => server.IdleRating,
      center: true,
      wrap: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Standby Rating",
      selector: (server) => server.StandbyRating,
      center: true,
      wrap: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Total PSU Power Watts Infrastructure",
      selector: (server) => server.TotalPSUPowerWattsInfrastructure,
      center: true,
      wrap: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Off Rating",
      selector: (server) => server.OffRating,
      center: true,
      wrap: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    }
  ];

  return (
    <Fragment>
      <Breadcrumb title="Server Power Ratings" parent="Sustainability" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{"Server Power Ratings"}</h5>
              </div>
              <div className="card-body datatable-react">
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  striped={true}
                  center={true}
                  customStyles={tableStyles}
                  progressPending={pending}
                  subHeaderComponent={subHeaderComponentMemo}
                  subHeader
                  persistTableHead
                  pagination
                  responsive
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment >
  );
};

export default ServerPowerRatings;