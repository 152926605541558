import React, { Fragment } from "react";

const CreateUser = (props) => {

  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12">
          <form className="needs-validation" noValidate>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="Vault Username">{"Vault Username"}</label>
                <input
                  className="form-control"
                  type="string"
                  placeholder="Vault Username"
                  required
                  value={props.vaultUser}
                  onChange={(e) => props.setVaultUser(e.target.value)}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="Vault Password">{"Vault Password"}</label>
                <input
                  className="form-control"
                  type="string"
                  placeholder="Vault Password"
                  required
                  value={props.vaultPassword}
                  onChange={(e) => props.setVaultPassword(e.target.value)}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default CreateUser;
