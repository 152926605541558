import axios from "axios";

export const fetchProductApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/product.json`);
};

export const fetchChatApi1 = () => {
  return axios.get(`${process.env.PUBLIC_URL}/chatMember.json`);
};

export const fetchChatApi2 = () => {
  return axios.get(`${process.env.PUBLIC_URL}/chat.chats.json`);
};


export const fetchTodoApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/todo.json`);
};

export const fetchTaskApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/task.json`);
};

