import React, { Fragment } from "react";
import * as SurveyCreator from "survey-creator-react";
import "../../constant/modern.css";
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";
import axios from "axios";
import Breadcrumb from "../../components/common/breadcrumb";
import { setToast } from "../../components/common/Toast";
import { toast } from "react-toastify";

function CreateAssessment() {
  const [assessmentId, setAssessmentId] = React.useState(undefined);
  const [loadAssessment, setLoadAssessment] = React.useState(false);
  const [selectOption, setSelectOption] = React.useState(true);
  const [assessmentName, setAssessmentName] = React.useState("");
  const [listAllAssessment, setListAllAssessment] = React.useState([]);
  const [createNew, setCreateNew] = React.useState(false);
  const [selectedAssessment, setSelectedAssessment] = React.useState(undefined);
  const [assessmentJson, setAssessmentJson] = React.useState({});

  let creator = new SurveyCreator.SurveyCreator({
    showJSONEditorTab: true,
    showLogicTab: true,
    isAutoSave: false
  });

  /* istanbul ignore next */
  creator.saveSurveyFunc = async (saveNo, callback) => {
    axios.post(assessmentId ? `${process.env.REACT_APP_BACKEND_URL}/api/assessment/${assessmentId}` : `${process.env.REACT_APP_BACKEND_URL}/api/assessments/add`, {
      name: assessmentName,
      assessment: creator.JSON
    })
      .then((res) => {
        setAssessmentId(res.data.id);
        setAssessmentJson(creator.JSON);
        callback(saveNo, true);
      })
      .catch(() => {
        setToast(toast.error, "Unable to save assessment");
        callback(saveNo, false);
      });
  };

  const onLoadAssessmentsClick = () => {
    setSelectOption(!selectOption);
    setLoadAssessment(!loadAssessment);

    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/assessments/get-all`)
      .then((res) => {
        setListAllAssessment(res.data);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve assessments");
      });
  };

  const onCreateNewAssessment = () => {
    setSelectOption(!selectOption);
    setCreateNew(!createNew);
  };

  const onCreateAssessment = () => {
    setCreateNew(!createNew);
  };

  const loadChange = (e) => {
    setSelectedAssessment(e.target.value);
  };

  const onLoadAssessments = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/assessment/${selectedAssessment}`)
      .then((res) => {
        creator.JSON = res.data.assessment;
        setLoadAssessment(!loadAssessment);
        setAssessmentId(res.data._id);
        setAssessmentName(res.data.name);
        setAssessmentJson(res.data.assessment);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve assessment");
      });
  };

  creator.JSON = assessmentJson;

  return (
    <Fragment>
      <Breadcrumb title="Create Assessment" parent="Assessment" />
      {selectOption ?
        <div className="card">
          <div className="card-header">
            <h5>{"CHOOSE AN OPTION"}</h5>
          </div>
          <div className="card-body datatable-react">
            <div className="card-footer text-center">
              <button id="create-assessment" className="btn btn-primary m-r-10 " type="submit" onClick={onCreateNewAssessment}>Create New</button>
              <button id="load-assessment" className="btn btn-primary" type="submit" onClick={onLoadAssessmentsClick}>Load</button>
            </div>
          </div>
        </div>
        :
        loadAssessment ?
          <div className="card">
            <div className="card-header">
              <h5>{"SELECT ASSESSMENT TO LOAD"}</h5>
            </div>
            <div className="card-body datatable-react">
              <form>
                <select id="assessments" className="form-control btn-square" onChange={loadChange}>
                  <option value={undefined} disabled={selectedAssessment !== undefined} hidden>Select one</option>
                  {listAllAssessment.map((assessments, index) => (
                    <option key={index} value={assessments._id}>{assessments.name}</option>
                  ))}
                </select>
                <div className="card-footer text-right">
                  <button id="next" className="btn btn-primary" type="submit" onClick={onLoadAssessments}>Next</button>
                </div>
              </form>
            </div>
          </div>
          :
          createNew ?
            <div className="card">
              <div className="card-header">
                <h5>{"NAME ASSESSMENT"}</h5>
              </div>
              <div className="card-body datatable-react">
                <form>
                  <input id="assessment-name" className="form-control btn-square" type="text" value={assessmentName} onChange={(e) => setAssessmentName(e.target.value)}>
                  </input>
                  <div className="card-footer text-right">
                    <button id="next" className="btn btn-primary" type="submit" onClick={onCreateAssessment}>Next</button>
                  </div>
                </form>
              </div>
            </div>
            :
            <div id="survey-creator">
              <SurveyCreator.SurveyCreatorComponent
                creator={creator}
              />
            </div>
      }
    </Fragment>
  );
}

export default CreateAssessment;