import React, { Fragment } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { PrimaryButton, SuccessButton, Terraform } from "../../constant";

const handleClick = () => {
  window.open("https://console.cloud.google.com/kubernetes/workload/overview?project=new-project-12365&pageState=(%22savedViews%22:(%22i%22:%22e7296e8f7adf4b85bd8e43592a1492a7%22,%22c%22:%5B%5D,%22n%22:%5B%5D))");
};

const handleClickterraform = () => {
  window.open("https://gitlab-master.datahubnow.com/gitlab-instance-d3a86964/runners/-/tree/HSBC-247");
};

const handleClickdns = () => {
  window.open("https://console.cloud.google.com/net-services/dns/zones?project=new-project-12365");
};

const GCPConsole = () => {
  return (
    <Fragment>
      <Breadcrumb title="Google Cloud Platform" parent="Springboard" />
      <div className="container-fluid">
        <div className="card-body btn-showcase">

          <button className="btn btn-pill btn-primary btn-air-primary" onClick={handleClick} type="button">{PrimaryButton}</button>
          <button className="btn btn-pill btn-success btn-air-success" onClick={handleClickdns} type="button">{SuccessButton}</button>
          <button className="btn btn-pill btn-success btn-air-success" onClick={handleClickterraform} type="button">{Terraform}</button>
          <hr />
          <ul id="post-list"></ul>
        </div>
      </div>
    </Fragment>
  );
};

export default GCPConsole;