import React from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import Links from "../../components/common/Links";

const Kong = () => {
  return (
    <div>
      <Breadcrumb title="Kong API Gateway" parent="Kong" />
      <Links pathname={window.location.pathname} />
    </div>
  );
};
export default Kong;
