import React, { Fragment } from "react";
import "survey-react/survey.css";
import * as Survey from "survey-react";
import Breadcrumb from "../../components/common/breadcrumb";
import axios from "axios";
import { setToast } from "../../components/common/Toast";
import { toast } from "react-toastify";

export const sendDataToServer = async (e, applicationId) => {
  axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/add-completed-application-assessment`, {
    applicationId: applicationId,
    completedAssessment: e.data
  })
    .then(() => {
      setToast(toast.success, "Assessment submitted");
    })
    .catch(() => {
      setToast(toast.error, "Unable to submit assessment");
    });
};

const ApplicationAssessment = () => {
  const [showAssessment, setShowAssessment] = React.useState(false);
  const [applications, setApplications] = React.useState([]);
  const [applicationId, setApplicationId] = React.useState("");
  const [surveyJSON, setSurveyJSON] = React.useState({});

  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/business-applications`)
      .then((response) => {
        setApplications(response.data);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve applications");
      });

    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/assessments/get-all`)
      .then((res) => {
        setSurveyJSON(res.data[0].assessment);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve assessments");
      });
  }, []);

  return (
    <Fragment>
      <Breadcrumb title="Application Assessment" parent="Assessments" />
      {showAssessment ?
        <Fragment>
          <Survey.Survey json={surveyJSON} onComplete={(e) => {
            /* istanbul ignore next */
            sendDataToServer(e, applicationId);
          }} />
        </Fragment>
        :
        <div className="card">
          <div className="card-header">
            <h5 id="title">{"SELECT AN APPLICATION"}</h5>
          </div>
          <div className="card-body datatable-react">
            <form>
              <select id="applications" className="form-control btn-square" value={applicationId} onChange={(e) => setApplicationId(e.target.value)}>
                {applications.map((application, index) => (
                  <option key={index} value={application._id}>{application.application}</option>
                ))}
              </select>
              <div className="card-footer text-right">
                <button id="next" className="btn btn-primary" type="submit" onClick={() => setShowAssessment(!showAssessment)}>Next</button>
              </div>
            </form>
          </div>
        </div>}
    </Fragment>
  );
};

export default ApplicationAssessment;