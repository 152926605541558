import React, { Fragment } from "react";
import man from "../../../assets/images/dashboard/user.png";
import { Link } from "react-router-dom";
import { Edit } from "react-feather";
import { useOktaAuth } from "@okta/okta-react";
import { useAuthUser } from "../../../authUserContext";

const UserPanel = () => {
  const { authState } = useOktaAuth();
  const { authUser } = useAuthUser();

  if (!authState || authUser === undefined) {
    return <p style={{ color: "white", textAlign: "center", marginTop: "50px", marginBottom: "30px" }}>Loading...</p>;
  } else if (authState.isAuthenticated) {
    return (
      <Fragment>
        <div className="sidebar-user text-center">
          <div>
            <img className="img-60 rounded-circle lazyloaded blur-up" src={man} alt="#" />
            <div className="profile-edit">
              <Link to={`${process.env.PUBLIC_URL}/users/userEdit`}>
                <Edit />
              </Link>
            </div>
          </div>
          <p className="mt-3 f-9">{authUser.name}</p>
          {authUser.groups.includes("Administrators") ?
            <p>Administrator</p>
            :
            <p>User</p>
          }
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <div className="sidebar-user text-center">
          <div>
            <img className="img-60 rounded-circle lazyloaded blur-up" src={man} alt="#" />
            <div className="profile-edit">
              <Link to={`${process.env.PUBLIC_URL}/users/userEdit`}>
                <Edit />
              </Link>
            </div>
          </div>
          <p className="mt-3 f-14">Not Logged In</p>
          <p>USER</p>
        </div>
      </Fragment>
    );
  }
};

export default UserPanel;
