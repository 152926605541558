import React, { Fragment } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import DataTable from "react-data-table-component";
import axios from "axios";
import FilterComponent from "../../components/common/FilterComponent";
import { tableStyles } from "../../components/common/CustomStyles";
import { setToast } from "../../components/common/Toast";
import { toast } from "react-toastify";

const SourceServers = () => {
  const [sourceServers, setSourceServers] = React.useState(undefined);
  const [filteredItems, setFilteredItems] = React.useState([]);
  const [clearRows, setClearRows] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/application-migration/get-all-source-servers`)
      .then((response) => {
        setSourceServers(response.data.items);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve source servers");
      });
  }, []);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div style={{ display: "flex", justifyContent: "end", width: "100%" }}>
        <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholder={"Application"} />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "Name",
      selector: (server) => server.tags.Name,
      wrap: true,
      sortable: true,
      center: true,
      width: "auto",
      style: {
        borderLeft: "1px solid #E5E5E5",
        padding: "1rem"
      }
    },
    {
      name: "View Job",
      cell: (server) => <button className="btn btn-primary" style={{ margin: "5px 0px 5px 0px", width: "10rem" }} onClick={() => window.open(`https://eu-west-2.console.aws.amazon.com/mgn/home?region=eu-west-2#/sourceServers/${server.sourceServerID}/migration_dashboard`)}>View</button>,
      center: true,
      sortable: true,
      wrap: true,
      width: "auto",
      style: {
        borderLeft: "1px solid #E5E5E5"
      }
    },
    {
      name: "Replication Type",
      selector: (server) => server.replicationType.toLowerCase().replace(/_/g, " "),
      center: true,
      sortable: true,
      wrap: true,
      width: "auto",
      style: {
        borderLeft: "1px solid #E5E5E5"
      }
    },
    {
      name: "Migration Lifecycle",
      selector: (server) => server.lifeCycle.state.toLowerCase().replace(/_/g, " "),
      center: true,
      sortable: true,
      wrap: true,
      width: "auto",
      style: {
        borderLeft: "1px solid #E5E5E5"
      }
    },
    {
      name: "Initiate Lifecycle",
      center: true,
      sortable: true,
      wrap: true,
      width: "12rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (server) =>
        <div>
          <button className={server.lifeCycle.state === "READY_FOR_TEST" ? "btn btn-primary" : "btn btn-primary disabled"} style={{ margin: "5px 0px 5px 0px", width: "10rem" }} disabled={server.lifeCycle.state !== "READY_FOR_TEST" ? true : false} onClick={handleStartTest(server.sourceServerID)} >Start Test</button>
          <button className={server.lifeCycle.state === "READY_FOR_CUTOVER" ? "btn btn-primary" : "btn btn-primary disabled"} style={{ margin: "5px 0px 5px 0px", width: "10rem" }} disabled={server.lifeCycle.state !== "READY_FOR_CUTOVER" ? true : false} onClick={handleStartCutover(server.sourceServerID)}>Start Cutover</button>
        </div>
    }
  ];


  React.useEffect(() => {
    setFilteredItems((sourceServers || []).filter(
      (item) => item.tags.Name && item.tags.Name.toLowerCase().includes(filterText.toLowerCase())));
  }, [filterText, sourceServers]);

  React.useEffect(() => {
    setClearRows(false);
  }, [clearRows]);


  const handleStartTest = (serverId) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/application-migration/start-test`, {
      serverId: serverId
    }).catch((error) => {
      console.log(error);
    });
  };
  const handleStartCutover = (serverId) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/application-migration/start-cutover`, {
      serverId: serverId
    }).catch((error) => {
      console.log(error);
    });
  };

  return (
    <Fragment>
      <Breadcrumb title="Application Migration" parent="Source Servers" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{"Source Servers"}</h5>
              </div>
              <div className="card-body datatable-react">
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  striped={true}
                  center={true}
                  customStyles={tableStyles}
                  subHeaderComponent={subHeaderComponentMemo}
                  subHeader
                  persistTableHead
                  pagination
                  responsive
                  selectableRows
                  clearSelectedRows={clearRows}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default SourceServers;
