import { combineReducers } from "redux";
import TodoApp from "./todo/reducer";
import ChatApp from "./chat/reducer";
import Ecommerce from "./ecommerce/product/reducer";
import Cart from "./ecommerce/cart/reducer";
import Wishlist from "./ecommerce/wishlist/reducer";
import Filters from "./ecommerce/filter/reducer";
import Taskapp from "./task/reducer";
import Projectapp from "./project/reducer";
import Customizer from "./customizer/reducer";

const reducers = combineReducers({
  TodoApp,
  ChatApp,
  Product: Ecommerce,
  Wishlist,
  Cart,
  Filters,
  Taskapp,
  Projectapp,
  Customizer
});

export default reducers;