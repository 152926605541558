import React, { Fragment } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import DataTable from "react-data-table-component";
import axios from "axios";
import { useParams } from "react-router";
import FilterComponent from "../../../components/common/FilterComponent";
import { tableStyles } from "../../../components/common/CustomStyles";
import FdmButton from "./FdmButton";
import { setToast } from "../../../components/common/Toast";
import { toast } from "react-toastify";
import MigrationProcessModal from "./MigrationProcessModal";

export const defaultValues = {
  processName: "",
  source: {
    host: undefined,
    sid: undefined,
    type: undefined
  },
  target: {
    host: undefined,
    sid: undefined,
    type: undefined
  },
  schemas: [],
  migrationParameters: {
    expdpDir: "DATA_PUMP_DIR",
    exdpDumpFile: "expdat.dmp",
    exdpLogFile: "export.log",
    networkLink: "",
    oracleVersion: undefined,
    cloudProvider: undefined,
    bucketName: "",
    oracleHostOld: ""
  }
};

const MigrationList = () => {
  const [migrations, setMigrations] = React.useState([]);
  const [migrationModal, setMigrationModal] = React.useState(false);
  const [migrationDetails, setMigrationDetails] = React.useState(defaultValues);
  const [migrationProcessId, setMigrationProcessId] = React.useState(undefined);
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");

  const { id } = useParams();

  const getMigrations = React.useCallback(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/fdm/migration-projects/${id}`)
      .then((response) => {
        setMigrations(response.data);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve migrations");
      });
  }, [id]);

  React.useEffect(() => {
    setInterval(getMigrations(), 5000);
  }, []);

  React.useEffect(() => {
    getMigrations();
  }, [getMigrations]);

  const handleEditMigration = (data) => {
    setMigrationProcessId(data.migrationProcessId);
    setMigrationModal(true);
    setMigrationDetails({
      processName: data.migrationProcessName,
      source: {
        host: data.srcDbHost,
        type: data.srcDbType,
        sid: data.srcDbDatabase
      },
      target: {
        host: data.targetDbHost,
        type: data.targetDbType,
        sid: data.targetDbDatabase
      },
      schemas: data.schemas.map((schema) => ({
        value: schema.schemaName,
        label: schema.schemaName,
        schemaName: schema.schemaName
      })),
      state: data.migrationProcessState,
      processId: data.migrationProcessId
    });
  };

  const generateTasks = (data) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/fdm/migration-process/generate-tasks`, {
      migrationProcessId: data.migrationProcessId
    })
      .then(() => {
        setToast(toast.success, "Tasks generated");
        getMigrations();
      })
      .catch(() => {
        setToast(toast.error, "Unable to generate tasks");
      });
  };

  const runMigrationProcess = (data) => {
    axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/fdm/migration-process/start-process`, {
      migrationProcessId: data.migrationProcessId
    })
      .then((response) => {
        setToast(toast.success, "Migration started");
        window.open(response.data.url, "_blank");
        getMigrations();
      })
      .catch(() => {
        setToast(toast.error, "Unable to start migration");
      });
  };

  const getMigrationProcessLogs = (data) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/fdm/migration-process/get-logs`, {
      migrationProcessId: data.migrationProcessId
    })
      .then((response) => {
        setToast(toast.success, "Logs downloaded");
        const url = window.URL.createObjectURL(new Blob([JSON.stringify(response.data)], { type: "application/json" }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${data.migrationProcessId}-logs.json`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve logs");
      });
  };

  /* istanbul ignore next */
  const columns = [
    {
      name: "Name",
      selector: (migration) => migration.migrationProcessName,
      center: true,
      wrap: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Status",
      selector: (migration) => migration.migrationProcessState,
      center: true,
      wrap: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Start Time",
      selector: (migration) => migration.processStartDate,
      center: true,
      wrap: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Stop Time",
      selector: (migration) => migration.processStopDate,
      center: true,
      wrap: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Duration",
      selector: (migration) => migration.processDuration,
      center: true,
      wrap: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Actions",
      center: true,
      width: "13rem",
      cell: (data) => <div>
        <button id={`edit-${data.migrationProcessId}`} className="btn btn-primary" style={{ margin: "5px 0px 5px 0px", width: "175px" }} onClick={() => handleEditMigration(data)}>Edit</button>
        {!data.taskAllCount &&
          <button id={`generate-${data.migrationProcessId}`} className="btn btn-primary" style={{ margin: "5px 0px 5px 0px", width: "175px" }} onClick={() => generateTasks(data)}>Generate Tasks</button>
        }
        {!(!data.taskAllCount || data.processStartDate) &&
          <button id={`run-${data.migrationProcessId}`} className="btn btn-primary" style={{ margin: "5px 0px 5px 0px", width: "175px" }} onClick={() => runMigrationProcess(data)}>Run</button>
        }
        {(data.processStartDate || data.processDuration) &&
          <button id={`logs-${data.migrationProcessId}`} className="btn btn-primary" style={{ margin: "5px 0px 5px 0px", width: "175px" }} onClick={() => getMigrationProcessLogs(data)}>Logs</button>
        }
      </div>
    }
  ];

  const filteredItems = migrations.filter(
    (item) => item.migrationProcessName && item.migrationProcessName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <div>
          <button id="create-migration-process" className="btn btn-primary" onClick={() => setMigrationModal(true)} type="button">Create Migration</button>
        </div>
        <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholder={"Project"} />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <Fragment>
      <Breadcrumb title="Oracle-To-Postgres Migrations" parent="Database Factory" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{"Project Migrations"}</h5>
              </div>
              <div className="card-body datatable-react">
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  striped={true}
                  center={true}
                  customStyles={tableStyles}
                  subHeaderComponent={subHeaderComponentMemo}
                  subHeader
                  persistTableHead
                  pagination
                  responsive
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <MigrationProcessModal
        migrationModal={migrationModal}
        setMigrationModal={setMigrationModal}
        getMigrations={getMigrations}
        migrationDetails={migrationDetails}
        setMigrationDetails={setMigrationDetails}
        migrationProcessId={migrationProcessId}
        setMigrationProcessId={setMigrationProcessId}
        id={id}
      />
      <FdmButton />
    </Fragment>
  );
};

export default MigrationList;