import React, { Fragment } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import DataTable from "react-data-table-component";
import axios from "axios";
import FilterComponent from "../../components/common/FilterComponent";
import { tableStyles } from "../../components/common/CustomStyles";
import { setToast } from "../../components/common/Toast";
import { toast } from "react-toastify";
import ImportFactorsModal from "./ImportFactorsModal";

const FactorsRepository = () => {
  const [factorsRepository, setFactorsRepository] = React.useState([]);
  const [openUpload, setOpenUpload] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [pending, setPending] = React.useState(true);

  const getFactorsRepository = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/factor-repository/get-all-factors`)
      .then((response) => {
        setFactorsRepository(response.data);
        setPending(false);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve factors");
        setPending(false);
      });
  };

  React.useEffect(() => {
    setPending(true);
    getFactorsRepository();
  }, []);

  const filteredItems = factorsRepository.filter(
    (item) => item.type && item.type.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <div>
          <button id="import-factors" className="btn btn-primary" onClick={() => setOpenUpload(true)} type="button">Import Factors</button>
        </div>
        <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholder={"Type"} />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  /* istanbul ignore next */
  const columns = [
    {
      name: "Type",
      selector: (factor) => factor.type,
      center: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Description",
      selector: (factor) => factor.description,
      center: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Value",
      selector: (factor) => factor.value,
      center: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Reference",
      selector: (factor) => factor.reference,
      center: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Date",
      selector: (factor) => factor.date,
      center: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Added",
      selector: (factor) => factor.added,
      center: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Added By",
      selector: (factor) => factor.addedBy,
      center: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    }
  ];

  return (
    <Fragment>
      <Breadcrumb title="Factors Repository" parent="Sustainability" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{"Table of Factors"}</h5>
              </div>
              <div className="card-body datatable-react">
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  customStyles={tableStyles}
                  striped={true}
                  progressPending={pending}
                  center={true}
                  subHeaderComponent={subHeaderComponentMemo}
                  subHeader
                  persistTableHead
                  pagination
                  responsive
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ImportFactorsModal
        openUpload={openUpload}
        setOpenUpload={setOpenUpload}
        getFactorsRepository={getFactorsRepository}
      />
    </Fragment>
  );
};
export default FactorsRepository;