import React, { Fragment, useState, useEffect } from "react";
import logo from "../../../assets/images/fj-logo.png";
import logo_compact from "../../../assets/images/fj-logo.png";
import UserPanel from "./userPanel";
import { MENUITEMS } from "../../../components/common/sidebar-component/menu";
import { NavLink } from "react-router-dom";
import configDB from "../../../data/customizer/config";
import { useAuthUser } from "../../../authUserContext";

/* istanbul ignore next */
const Sidebar = () => {
  const { authUser } = useAuthUser();
  const [margin, setMargin] = useState(0);
  const [width, setWidth] = useState(0);
  const [hideLeftArrowRTL, setHideLeftArrowRTL] = useState(true);
  const [hideRightArrowRTL, setHideRightArrowRTL] = useState(true);
  const [mainmenu, setMainMenu] = useState(undefined);
  const [authmainmenu, setAuthMainMenu] = useState(undefined);
  const wrapper = configDB.data.settings.sidebar.wrapper;

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    if (!authUser || mainmenu !== undefined) {
      return;
    }

    const currentUrl = window.location.pathname;

    const tempmainmenu = MENUITEMS.filter((items) => {
      if (items.children) {
        items.children = items.children.filter((child) => {
          if (child.section) {
            return authUser.sections.includes(child.section);
          }
          return false;
        });
        return items.children.length > 0;
      } else if (!authUser.sections.includes(items.section)) {
        return false;
      } else {
        return true;
      }
    }).filter((items) => {
      if (items.path === currentUrl)
        setNavActive(items);
      if (!items.children) return true;
      items.children.filter((subItems) => {
        if (subItems.path === currentUrl)
          setNavActive(subItems);
        if (!subItems.children) return true;
        subItems.children.filter((subSubItems) => {
          if (subSubItems.path === currentUrl) {
            setNavActive(subSubItems);
            return true;
          }
          else {
            return false;
          }
        });
        return subItems;
      });
      return items;
    });

    setMainMenu(tempmainmenu);
    setAuthMainMenu(tempmainmenu);

    const timeout = setTimeout(() => {
      const elmnt = document.getElementById("myDIV");
      const menuWidth = elmnt.offsetWidth;
      if (menuWidth > window.innerWidth) {
        setHideLeftArrowRTL(false);
      } else {
        setHideLeftArrowRTL(true);
      }
    }, 500);

    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(timeout);
    };
  }, [authUser]);

  const handleResize = () => {
    setWidth(window.innerWidth - 310);
  };

  const setNavActive = (item) => {
    if (!mainmenu) return;

    let items = mainmenu;
    items.filter((menuItem) => {
      if (menuItem !== item)
        menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true;
      if (menuItem.children) {

        menuItem.children.filter((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
            return true;
          } else {
            return false;
          }
        });
      }
      return menuItem;
    });
    item.active = !item.active;
    setMainMenu(items);
  };

  // Click Toggle menu
  const toggletNavActive = (item) => {
    let items = [...authmainmenu];
    if (!item.active) {
      items.forEach((menuItem) => {
        if (menuItem !== item)
          menuItem.active = false;
        if (menuItem === item)
          menuItem.active = !menuItem.active;
        if (menuItem.children && menuItem.children.includes(item))
          menuItem.active = true;
        if (menuItem.children) {
          menuItem.children.forEach((submenuItems) => {
            if (submenuItems.children && (submenuItems.children.includes(item) || submenuItems === item)) {
              menuItem.active = true;
              submenuItems.active = true;
              return true;
            } else {
              return false;
            }
          });
        }
        return menuItem;
      });
    }
    setMainMenu(items);
  };

  const scrollToRight = () => {
    const elmnt = document.getElementById("myDIV");
    const menuWidth = elmnt.offsetWidth;
    const temp = menuWidth + margin;
    if (temp < menuWidth) {
      setMargin(-temp);
    }
    else {
      setMargin((margin) => margin += (-width));
    }
  };

  const scrollToLeft = () => {
    // If Margin is reach between screen resolution
    if (margin >= -width) {
      setMargin(0);
    }
    else {
      setMargin((margin) => margin += width);
    }
  };

  const scrollToLeftRTL = () => {
    if (margin <= -width) {
      setMargin((margin) => margin += -width);
      setHideLeftArrowRTL(true);
    }
    else {
      setMargin((margin) => margin += -width);
      setHideRightArrowRTL(false);
    }
  };

  const scrollToRightRTL = () => {
    const temp = width + margin;
    // Checking condition for remaing margin
    if (temp === 0) {
      setMargin(temp);
      setHideRightArrowRTL(true);
    }
    else {
      setMargin((margin) => margin += width);
      setHideRightArrowRTL(false);
      setHideLeftArrowRTL(false);
    }
  };

  return (
    <Fragment>
      <div className="page-sidebar">
        <div className="main-header-left d-none d-lg-block">
          <div className="logo-wrapper compactLogo">
            <a href={`${process.env.PUBLIC_URL}/`} className="sidebar-logo">
              <img className="sidebar-logo blur-up lazyloaded" src={logo_compact} alt="" />
              <img className="sidebar-logo blur-up lazyloaded" src={logo} alt="" width={"150"} height={"75"} />
            </a>
          </div>
        </div>
        <div className="sidebar custom-scrollbar">
          <UserPanel />
          <ul
            className="sidebar-menu"
            id="myDIV"
            style={wrapper === "horizontal_sidebar" ? { "marginRight": margin + "px" } : { margin: "0px" }}
          >
            <li className={`left-arrow ${hideLeftArrowRTL ? "d-none" : ""}`}
              onClick={(wrapper === "horizontal_sidebar") ? scrollToLeftRTL : scrollToLeft}><i className="fa fa-angle-left"></i></li>
            {mainmenu && mainmenu.map((menuItem, i) =>
              <li className={`${menuItem.active ? "active" : ""}`} key={i}>
                {(menuItem.sidebartitle) ?
                  <div className="sidebar-title">{menuItem.sidebartitle}</div>
                  : ""}

                {(menuItem.type === "sub") ?
                  <a className="sidebar-header" onClick={() => toggletNavActive(menuItem)}>
                    <menuItem.icon />
                    <span>{menuItem.title}</span>
                    <i className="fa fa-angle-right pull-right"></i>
                  </a>
                  : ""}

                {(menuItem.type === "link") ?
                  <NavLink
                    to={`${process.env.PUBLIC_URL}${menuItem.path}`}
                    className={`sidebar-header ${menuItem.active ? "active" : ""}`}

                    onClick={() => toggletNavActive(menuItem)}
                  >
                    <menuItem.icon /><span>{menuItem.title}</span>
                    {menuItem.children ?
                      <i className="fa fa-angle-right pull-right"></i> : ""}
                  </NavLink>
                  : ""}

                {menuItem.children ?
                  <ul
                    className={`sidebar-submenu ${menuItem.active ? "menu-open" : ""}`}
                    style={menuItem.active ? { opacity: 1, transition: "opacity 500ms ease-in" } : {}}
                  >
                    {menuItem.children.map((childrenItem, index) =>
                      <li key={index} className={childrenItem.children ? childrenItem.active ? "active" : "" : ""}>
                        {(childrenItem.type === "sub") ?
                          <a onClick={() => toggletNavActive(childrenItem)} >
                            <i className="fa fa-circle"></i>{childrenItem.title} <i className="fa fa-angle-right pull-right"></i></a>
                          : ""}

                        {(childrenItem.type === "link") ?
                          <NavLink
                            to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                            className={childrenItem.active ? "active" : ""}
                            onClick={() => toggletNavActive(childrenItem)}
                          >
                            <i className="fa fa-circle"></i>{childrenItem.title} </NavLink>
                          : ""}

                        {childrenItem.children ?
                          <ul className={`sidebar-submenu ${childrenItem.active ? "menu-open" : "active"}`}>
                            {childrenItem.children.map((childrenSubItem, key) =>
                              <li className={childrenSubItem.active ? "active" : ""} key={key}>
                                {(childrenSubItem.type === "link") ?
                                  <NavLink
                                    to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                                    className={childrenSubItem.active ? "active" : ""}
                                    onClick={() => toggletNavActive(childrenSubItem)}
                                  >
                                    <i className="fa fa-circle"></i>{childrenSubItem.title}</NavLink>
                                  : ""}
                              </li>
                            )}

                          </ul>
                          : ""}

                      </li>
                    )}

                  </ul>
                  : ""}

              </li>
            )}
            <li className={`right-arrow ${hideRightArrowRTL ? "d-none" : ""}`}
              onClick={(wrapper === "horizontal_sidebar") ? scrollToRightRTL : scrollToRight}><i className="fa fa-angle-right"></i></li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default Sidebar;

