// import axios from "axios";
import React, { Fragment } from "react";

const ConfigureAccount = (props) => {
  //const [ous, setOus] = React.useState([]);
  // const [guardrails, setGuardrails] = React.useState([]);

  // React.useEffect(() => {
  //   axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/organizations/ous`)
  //     .then((res) => {
  //       setOus(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  // React.useEffect(() => {
  //   axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/organizations/guardrails`)
  //     .then((res) => {
  //       setGuardrails(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12">
          <form className="needs-validation" noValidate>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="Account Name">{"Account Name"}</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Account Name"
                  value={props.accountName}
                  onChange={(e) => {
                    props.setAccountName(e.target.value);
                    props.setSsoEmail(e.target.value + "-account@lz.datahubnow.com");
                  }}
                  required
                />
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="Account Email">{"Account Email"}</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="example-account@lz.datahubnow.com"
                  readOnly
                  value={props.accountEmail}
                  onChange={(e) => props.setAccountEmail(e.target.value)}
                  required
                />
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="Account User">{"Account User"}</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Git Username (eg: sb)"
                  value={props.accountUser}
                  onChange={(e) => props.setAccountUser(e.target.value)}
                  required
                />
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="Organisational Unit">{"Organisational Unit"}</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Sandbox"
                  readOnly
                  value={props.ou}
                  onChange={(e) => props.setOu(e.target.value)}
                  required
                />
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="Organisational Unit ID">{"Organisational Unit ID"}</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="ou-vzz4-ul2c46fk"
                  readOnly
                  value={props.ouId}
                  onChange={(e) => props.setOuId(e.target.value)}
                  required
                />
              </div>

            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default ConfigureAccount;