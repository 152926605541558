import React from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import Breadcrumb from "../../components/common/breadcrumb";
import { Container } from "reactstrap";
import { setToast } from "../../components/common/Toast";
import { toast } from "react-toastify";

const Recommendations = () => {
  const [recommendationsData, setRecommendationData] = React.useState([]);
  const [selectRecommendationType, setSelectRecommendationType] = React.useState("google.cloudsql.instance.OutOfDiskRecommender");
  const [selectRegions, setSelectRegions] = React.useState("europe-west2-c");
  const [pending, setPending] = React.useState(true);
  const [recommends, setRecommends] = React.useState([]);
  const [availableRegions, setAvailableRegions] = React.useState([]);

  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/sustainability/clean-recommendations`)
      .then((data) => {
        setPending(false);
        setRecommends(data.data);
      })
      .catch(() => {
        setPending(true);
        setToast(toast.error, "Unable to retrieve recommendation list");
      });
  }, []);

  const getRecommendations = () => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/sustainability/get-compute-recommendations`, {
      recommendationType: selectRecommendationType,
      region: selectRegions
    })
      .then((res) => {
        setRecommendationData(res.data.Recommendations);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve recommendations");
      });
  };

  const selectedRecommendation = (recommendation) => {
    let filtered = recommends.filter((item) => item.RecommenderName === recommendation);
    setAvailableRegions(filtered[0].regions);
  };

  /* istanbul ignore next */
  const columns = [
    {
      name: "Service Name",
      selector: "name",
      center: true,
      sortable: true,
      wrap: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Recommendation",
      selector: "description",
      center: true,
      sortable: true,
      wrap: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Potential Savings",
      selector: "primaryImpact",
      format: (row) => {
        if (row.primaryImpact.costProjection.cost.units < 0) {
          return ("$" + row.primaryImpact.costProjection.cost.units * -1);
        } else {
          return null;
        }
      },
      center: true,
      sortable: true,
      wrap: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    }
  ];

  return (
    <div>
      <Breadcrumb title="Recommendations" parent="Sustainbility" />
      <Container fluid={true}>
        <select id="recommendations-list" className='form-control btn-square' onChange={(e) => {
          setSelectRecommendationType(e.target.value);
          selectedRecommendation(e.target.value);
        }}>
          {!pending ?
            recommends.map((e) => {
              const recommenderNameSplit = e.RecommenderName.split(".");
              return (
                <option key={e.RecommenderName} value={e.RecommenderName}>{recommenderNameSplit[1] + " " + recommenderNameSplit[2] + " " + recommenderNameSplit[3]}</option>
              );
            })
            :
            <option>Loading</option>
          }
        </select>
        {availableRegions.length > 0 &&
          <select id="regions" className='form-control btn-square' onChange={(e) => setSelectRegions(e.target.value)}>
            {availableRegions.map((region) => {
              return (
                <option key={region} value={region}>{region}</option>
              );
            })}
          </select>
        }
        <button id="get-recommendations" className="btn btn-pill btn-primary btn-air-primary" onClick={getRecommendations}>Get Recommendations</button>
        <DataTable
          columns={columns}
          data={recommendationsData}
        />
      </Container>
    </div>
  );
};

export default Recommendations;