import React from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import Links from "../../components/common/Links";
import CarbonGraph from "./CarbonGraph";

const CarbonFootprint = () => {
  return (
    <div>
      <Breadcrumb title="Carbon Footprint" parent="Sustainability" />
      <Links pathname={window.location.pathname} />
      <CarbonGraph />
    </div>
  );
};

export default CarbonFootprint;