import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import StepZilla from "react-stepzilla";
import ConfigureResource from "./ConfigureResource";
import SelectRegion from "./SelectRegion";
import Review from "./Review";
import { FormWizardWithIcon } from "../../../constant";
import { useParams } from "react-router";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const OrderGCPSQL = () => {  
  const { id } = useParams();
  const [vmName, setVmName] = useState("");
  const [instanceMachineType, setInstanceMachineType] = useState("");
  const [cloudSqlDBVersion, setCloudSqlDBVersion] = useState("");
  const [bootDiskSize, setBootDiskSize] = useState("");
  const [region, setRegion] = useState("");
  const [zone, setZone] = useState("");
  const [orderName, setOrderName] = useState("");
  const [action, setAction] = useState("");
  const [statusId, setStatusId] = useState(uuidv4());
  const [status, setStatus] = useState("Waiting");

  useEffect(() => {
    if (id) {
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/orders/get-sql-order/${id}`)
        .then((res) => {
          setVmName(res.data.order.vmName);
          setInstanceMachineType(res.data.order.instanceMachineType);
          setCloudSqlDBVersion(res.data.order.version);
          setBootDiskSize(res.data.order.bootDiskSize);
          setRegion(res.data.order.region);
          setZone(res.data.order.zone);
          setOrderName(res.data.name);
          setAction(res.data.action);
          setStatusId(res.data.order.statusId);
        })
        .catch((err) => {
          console.log(err);
          window.location = "/infrastructure/google/cloudsql-build";
        });
    }
  }, [id]);

  const steps =
    [
      {
        name: "Configure Resource",
        component: <ConfigureResource
          vmName={vmName}
          setVmName={setVmName}
          instanceMachineType={instanceMachineType}
          setInstanceMachineType={setInstanceMachineType}
          cloudSqlDBVersion={cloudSqlDBVersion}
          setCloudSqlDBVersion={setCloudSqlDBVersion}
          bootDiskSize={bootDiskSize}
          setBootDiskSize={setBootDiskSize}
        />
      },
      {
        name: "Select Region",
        component: <SelectRegion
          region={region}
          setRegion={setRegion}
          zone={zone}
          setZone={setZone}
        />
      },
      {
        name: "Review",
        component: <Review
          vmName={vmName}
          instanceMachineType={instanceMachineType}
          region={region}
          zone={zone}
          cloudSqlDBVersion={cloudSqlDBVersion}
          bootDiskSize={bootDiskSize}
          orderName={orderName}
          setOrderName={setOrderName}
          action={action}
          setAction={setAction}
          statusId={statusId}
          setStatusId={setStatusId}
          status={status}
          setStatus={setStatus}
          id={id}
        />
      }
    ];

  return (
    <Fragment>
      <Breadcrumb title="Order" parent="CloudSQL Build" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{FormWizardWithIcon}</h5>
              </div>
              <div className="card-body">
                <StepZilla
                  steps={steps}
                  showSteps={true}
                  showNavigation={true}
                  stepsNavigation={true}
                  prevBtnOnLastStep={true}
                  dontValidate={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OrderGCPSQL;