import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Media } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { setToast } from "../../components/common/Toast";

const Links = (props) => {
  const [links, setLinks] = useState(undefined);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/links`, {
      params: {
        page: encodeURIComponent(props.pathname)
      }
    })
      .then((res) => {
        setLinks(res.data);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve links");
        setLinks(null);
      });
  }, []);

  return (
    <div>
      {links ?
        links.length === 0 ?
          <div id="empty" className="col-xl-3 col-sm-6">
            <p>There are no links on this page</p>
          </div>
          :
          <Container fluid={true}>
            <Row>
              <Col sm="12">
                <Card id="discovery-tool-card">
                  <CardBody className="my-gallery row">
                    {links.map((link) => {
                      return (
                        <figure key={link._id} className="col-xl-3 col-sm-6 on-hover" onClick={() => window.open(link.link, "_blank")}>
                          <Media
                            src={link.image}
                            alt="Gallery"
                            className="img-thumbnail"
                          />
                          <h5>{link.name}</h5>
                        </figure>
                      );
                    })}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        :
        links === undefined ?
          <div id="loading" className="col-xl-3 col-sm-6">
            <p>Loading...</p>
          </div>
          :
          <div id="error" className="col-xl-3 col-sm-6">
            <p>Unable to retrieve links.</p>
            <p>Please try again.</p>
          </div>
      }
    </div>
  );
};
export default Links;
