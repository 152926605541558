import axios from "axios";
import React, { Fragment } from "react";
import { toast } from "react-toastify";
import Breadcrumb from "../../components/common/breadcrumb";
import { setToast } from "../../components/common/Toast";

const Globals = () => {
  const [initialValues, setInitialValues] = React.useState({});
  const [globals, setGlobals] = React.useState({});

  const getGlobals = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/globals`)
      .then((res) => {
        setInitialValues(res.data);
        setGlobals(res.data);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve globals");
      });
  };

  React.useEffect(() => {
    getGlobals();
  }, []);

  const handleOnSubmit = () => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/admin/globals`, {
      ...globals
    })
      .then(() => {
        setToast(toast.success, "Globals updated");
        setInitialValues(globals);
      })
      .catch(() => {
        setToast(toast.error, "Unable to save globals");
        getGlobals();
      });
  };

  return (
    <Fragment>
      <Breadcrumb title="Globals" parent="Admin" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{"Globals"}</h5>
              </div>
              {["database", "virtualserver", "physicalserver", "kubernetes"].map((item) => {
                return (
                  <div key={item} className="card-body">
                    <h6>{item === "database" ? "Database" : item === "virtualserver" ? "Virtual Server" : item === "physicalserver" ? "Physical Server" : "Kubernetes"} Discovery Merge Strategy</h6>

                    {["Discovery", "Manual"].map((mergeStrategy) => {
                      return (
                        <div key={mergeStrategy} className="radio col-sm-6 col-md-12">
                          <input
                            className="form-control"
                            id={`mergestrategy-${item}-${mergeStrategy.toLowerCase()}`}
                            type="radio"
                            value={mergeStrategy}
                            checked={globals[`${item}mergestrategy`] === mergeStrategy}
                            onChange={() => setGlobals({ ...globals, [`${item}mergestrategy`]: mergeStrategy })}
                            name={`mergestrategy-${item}-${mergeStrategy.toLowerCase()}`}
                          />
                          <label htmlFor={`mergestrategy-${item}-${mergeStrategy.toLowerCase()}`}>{mergeStrategy}</label>
                          {mergeStrategy === "Discovery" &&
                            <p style={{ width: "100%", color: "grey" }}>Allow new discovery data to override existing values.</p>
                          }

                          {mergeStrategy === "Manual" &&
                            <p style={{ width: "100%", color: "grey" }}>Override discovery data with existing values.</p>
                          }
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              <div className="card-footer">
                <button id="reset" className="btn btn-primary float-left" onClick={getGlobals} disabled={initialValues === globals} type="submit">Reset</button>
                <button id="submit" className="btn btn-primary float-right" onClick={handleOnSubmit} type="submit">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment >
  );
};
export default Globals;




