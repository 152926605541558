
import React, { Fragment } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { BuildVM, DeleteVM, OpenGitlab, OpenGCPVMs } from "../../constant";
import axios from "axios";
import { setToast } from "../../components/common/Toast";
import { toast } from "react-toastify";

const handleClickactivity = () => {
  window.open("https://console.cloud.google.com/home/activity?project=new-project-12365", "_blank");
};

const handleClickgit = () => {
  window.open("https://gitlab-master.datahubnow.com/demo/remote-trigger/-/pipelines", "_blank");
};

const handleClick = () => {
  axios.post("https://gitlab-master.datahubnow.com/api/v4/projects/9/trigger/pipeline",
    {
      token: "eff30f6ba6cc22ea919130122d82e8",
      ref: "main"
    },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
    .then(() => {
      setToast(toast.success, "Pipeline initiated");
    })
    .catch(() => {
      setToast(toast.error, "Unable to initiate pipeline");
    });
};

const handleClickdelete = () => {
  axios.post("https://gitlab-master.datahubnow.com/api/v4/projects/9/trigger/pipeline",
    {
      token: "eff30f6ba6cc22ea919130122d82e8",
      ref: "main",
      variables: {
        DESTROY: "true"
      }
    },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
    .then(() => {
      setToast(toast.success, "Infrastructure deleted");
    })
    .catch(() => {
      setToast(toast.error, "Unable to delete infrastructure");
    });
};

const GitLabTriggers = () => {
  return (
    <Fragment>
      <Breadcrumb title="GitLab Trigger" parent="Catalogue" />
      <div className="container-fluid">
        <div className="card-body btn-showcase">
          <button id="build-google-vm" className="btn btn-pill btn-primary btn-air-primary" onClick={handleClick} type="button">{BuildVM}</button>
          <button id="delete-google-vm" className="btn btn-pill btn-primary btn-air-primary" onClick={handleClickdelete} type="button">{DeleteVM}</button>
          <button id="gcp-console-vms" className="btn btn-pill btn-primary btn-air-primary" onClick={handleClickactivity} type="button">{OpenGCPVMs}</button>
          <button id="gitlab-pipeline" className="btn btn-pill btn-primary btn-air-primary" onClick={handleClickgit} type="button">{OpenGitlab}</button>
          <hr />
        </div>
      </div>
    </Fragment>
  );
};

export default GitLabTriggers;