/* TODO APP*/
export const WATCH_TODO_LIST = "WATCH_TODO_LIST";
export const GET_TODO_LIST = "GET_TODO_LIST";
export const GET_LIST_SUCCESS = "GET_LIST_SUCCESS";
export const MARK_ALL_ITEMS = "MARK_ALL_ITEMS";
export const ADD_NEW_ITEM = "ADD_NEW_ITEM";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const SELECTED_ITEM = "SELECTED_ITEM";


/* ECOMMERRCE APP */
export const GET_LIST = "GET_LIST";
export const WATCH_PRODUCT_LIST = "WATCH_PRODUCT_LIST";
export const WATCH_SINGLE_ITEM = "WATCH_SINGLE_ITEM";
export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT";
export const SELECTED_PRODUCT = "SELECTED_PRODUCT";
export const CHANGE_CURRENCY = "CHANGE_CURRENCY";
export const SEARCH_MEMBER_PRODUCT = "SEARCH_MEMBER_PRODUCT";


/* Bookmark App */
export const WATCH_BOOKMARK_LIST = "WATCH_BOOKMARK_LIST";
export const GET_BOOKMARK_LIST = "GET_BOOKMARK_LIST";
export const ADD_TO_MY_BOOKMARK = "ADD_TO_MY_BOOKMARK";
export const ADD_NEW_BOOKMARK = "ADD_NEW_BOOKMARK";
export const REMOVE_BOOKMARK = "REMOVE_BOOKMARK";
export const REMOVE_FROM_MY_BOOKMARK = "REMOVE_FROM_MY_BOOKMARK";
export const UPDATE_BOOKMARK = "UPDATE_BOOKMARK";
export const UPDATE_MY_BOOKMARK = "UPDATE_MY_BOOKMARK";

/* Task App */
export const WATCH_TASK_LIST = "WATCH_TASK_LIST";
export const FETCH_ALL_TASK = "FETCH_ALL_TASK";
export const NEW_TASK = "NEW_TASK";
export const REMOVE_TASK = "REMOVE_TASK";

// Cart

export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const INCREMENT_QTY = "INCREMENT_QTY";
export const DECREMENT_QTY = "DECREMENT_QTY";
export const GET_SINGLE_ITEM = "GET_SINGLE_ITEM";

// WishList
export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const REMOVE_FROM_WISHLIST = "REMOVE_FROM_WISHLIST";

// CheckOut Process
export const CHECKOUT_REQUEST = "CHECKOUT_REQUEST";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_FAILURE = "CHECKOUT_FAILURE";

// FILTERS
export const FILTER_BRAND = "FILTER_BRAND";
export const FILTER_COLOR = "FILTER_COLOR";
export const FILTER_PRICE = "FILTER_PRICE";
export const SORT_BY = "SORT_BY";
export const SEARCH_BY = "SEARCH_BY";

/* CHAT APP*/
export const GET_MEMBERS = "GET_MEMBERS";
export const WATCH_CHAT_MEMBERS = "WATCH_CHAT_MEMBERS";
export const WATCH_CHAT_SUCCESS = "WATCH_CHAT_SUCCESS";
export const WATCH_ALL_CHAT_SUCCESS = "WATCH_ALL_CHAT_SUCCESS";
export const GET_MEMBERS_SUCCESS = "GET_MEMBERS_SUCCESS";
export const GET_CHATS = "GET_CHATS";
export const GET_CHATS_SUCCESS = "GET_CHATS_SUCCESS";
export const UPDATE_SELECTED_USER = "UPDATE_SELECTED_USER";
export const CHANGE_CHAT = "CHANGE_CHAT";
export const CREATE_CHAT = "CREATE_CHAT";
export const CREATE_CHAT_WATCHER = "CREATE_CHAT_WATCHER";
export const SEARCH_MEMBER = "SEARCH_MEMBER";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEND_MESSAGE_WATCHER = "SEND_MESSAGE_WATCHER";
export const REPLY_BY_SELECTED_USER = "REPLY_BY_SELECTED_USER";
export const REPLY_MESSAGE_WATCHER = "REPLY_MESSAGE_WATCHER";

// EMAIL APP
export const WATCH_EMAIL = "WATCH_EMAIL";
export const WATCH_ALL_TYPE_EMAIL = "WATCH_ALL_TYPE_EMAIL";
export const GET_ALL_EMAIL_ASYN = "GET_ALL_EMAIL_ASYN";
export const GET_ALL_TYPE_ASYN = "GET_ALL_TYPE_ASYN";
export const GET_EMAILS = "GET_EMAILS";
export const GET_ALL_EMAILS = "GET_ALL_EMAILS";
export const GET_EMAIL_TYPES = "GET_EMAIL_TYPES";
export const UPDATE_EMAIL_TYPES = "UPDATE_EMAIL_TYPES";


// Project 
export const ADD_NEW_PROJECT = "ADD_NEW_PROJECT";

// AUTHENTICATION APP
export const LOGIN = "LOGIN";
export const INITIALIZE_FIREBASE = "INITIALIZE_FIREBASE";

//CUSTOMIZER 
export const ADD_COSTOMIZER ="ADD_COSTOMIZER";
export const ADD_LAYOUT = "ADD_LAYOUT";
export const ADD_SIDEBAR_TYPES = "ADD_SIDEBAR_TYPES";
export const ADD_SIDEBAR_SETTINGS ="ADD_SIDEBAR_SETTINGS";
export const ADD_COLOR = "ADD_COLOR";
export const ADD_MIXlAYOUT = "ADD_MIXlAYOUT";

