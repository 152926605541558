import React, { Fragment } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { AlibabaButtons } from "../../constant";

const handleClick = () => {
  window.open("https://homenew-intl.console.aliyun.com/");
};

const AlibabaCloud = () => {
  return (
    <Fragment>
      <Breadcrumb title="Alibaba Cloud" parent="Springboard" />
      <div className="container-fluid">
        <div className="card-body btn-showcase">
          <button className="btn btn-pill btn-primary btn-air-primary" onClick={handleClick} type="button">{AlibabaButtons}</button>
          <hr />
          <ul id="post-list"></ul>
        </div>
      </div>
    </Fragment>
  );
};

export default AlibabaCloud;