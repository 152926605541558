import React, { Fragment } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import axios from "axios";
import "react-dropzone-uploader/dist/styles.css";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { setToast } from "../../../components/common/Toast";
import { ExternalLink, Trash } from "react-feather";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

const KubernetesEdit = () => {
  const [kubernetes, setKubernetes] = React.useState({ name: "", status: true, vendor: "", cluster: "", namespace: "", ip: [], region: [], notes: "", jirakey: "INVALID", jiradomain: "", cloudlink: "", datadoglink: "", transformationphase: "", transformationstrategy: "", transformationgroup: "", discovered: "Manual", mergestrategy: "Global" });
  const [jiradomain, setJiraDomain] = React.useState(undefined);
  const [transformationgroups, setTransformationgroups] = React.useState([]);
  const [globalMergeStrategy, setGlobalMergeStrategy] = React.useState(undefined);
  const { discovery, id } = useParams();

  const getKubernetes = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/kubernetes/get-pod/${discovery}/${id}`)
      .then((response) => {
        /* istanbul ignore next */
        setKubernetes({
          ...response.data.pod,
          ip: response.data.pod.ip ? response.data.pod.ip.map((item) => {
            return {
              value: item.ip,
              label: item.ip,
              _id: item._id
            };
          }) : []
        });
        /* istanbul ignore next */
        setJiraDomain(response.data.jiradomain);

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/globals/kubernetes`)
          .then((response) => {
            /* istanbul ignore next */
            setGlobalMergeStrategy(response.data);
          })
          .catch(() => {
            /* istanbul ignore next */
            setToast(toast.error, "Unable to retrieve kubernetes global merge strategy");
          });
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve kubernetes pod");
      });
  };

  const getTransformationGroups = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/migration-groups`)
      .then((response) => {
        const migGroups = response.data.migrationGroups.map((item) => {
          return {
            value: item._id,
            label: item.name
          };
        });

        setTransformationgroups([
          {
            value: "",
            label: "None"
          },
          ...migGroups
        ]);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve transformation groups");
      });
  };

  React.useEffect(() => {
    if (typeof kubernetes.transformationgroup === "string" && transformationgroups.length > 0) {
      setKubernetes({
        ...kubernetes,
        transformationgroup: transformationgroups.find((item) => item.value === kubernetes.transformationgroup)
      });
    }
  }, [kubernetes, transformationgroups]);

  React.useEffect(() => {
    if (id && discovery) {
      getKubernetes();
    } else {
      setKubernetes({
        ...kubernetes,
        discovered: "Manual",
        mergestrategy: "Manual"
      });
    }

    getTransformationGroups();
  }, []);

  const handleOnChange = (value, name) => {
    setKubernetes({ ...kubernetes, [name]: value });
  };

  const handleOnSubmit = () => {
    axios.post(id ? `${process.env.REACT_APP_BACKEND_URL}/api/kubernetes/${discovery}/${id}` : `${process.env.REACT_APP_BACKEND_URL}/api/kubernetes/${discovery}`, {
      pod: {
        ...kubernetes,
        ip: kubernetes.ip.map((item) => ({ _id: item._id, ip: item.value })),
        transformationgroup: kubernetes.transformationgroup.value
      }
    })
      .then(() => {
        window.location.assign("/discovery/kubernetes");
      })
      .catch(() => {
        setToast(toast.error, `Unable to ${id ? "update" : "create"} kubernetes pod`);
      });
  };

  const createJiraTicket = () => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/jira`, {
      items: [kubernetes],
      type: "kubernetes",
      discoveryId: discovery
    })
      .then(() => {
        setToast(toast.success, "Jira ticket created");
        setTimeout(() => getKubernetes(), 1000);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setToast(toast.error, "No longer part of a migration group");
          getKubernetes();
        } else {
          setToast(toast.error, "Unable to create Jira ticket");
        }
      });
  };

  const deleteJiraTicket = () => {
    axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/jira`, {
      data: {
        jirakey: kubernetes.jirakey,
        type: "kubernetes",
        discoveryId: discovery,
        kubernetesId: id
      }
    })
      .then(() => {
        setToast(toast.success, "Jira ticket deleted");
        setTimeout(() => getKubernetes(), 1000);
      })
      .catch(() => {
        setToast(toast.error, "Unable to delete Jira ticket");
      });
  };

  return (
    <Fragment>
      <Breadcrumb title={"Edit"} parent="Kubernetes" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Edit Kubernetes Information</h5>
              </div>
              <div className="card-body datatable-react">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title mb-0">{`Server Information: ${kubernetes.name}`}</h4>
                    <div className="card-options">
                      <a className="card-options-collapse" href="javascript" data-toggle="card-collapse">
                        <i className="fe fe-chevron-up"></i>
                      </a>
                      <a className="card-options-remove" href="javascript" data-toggle="card-remove">
                        <i className="fe fe-x"></i>
                      </a>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className={`col-sm-6 ${kubernetes.name.length > 35 ? "col-md-6" : "col-md-3"}`}>
                        <div className="form-group">
                          <label className="form-label">Name</label>
                          {id ?
                            <input id="name" className="form-control" type="text" name="name" placeholder="Name" value={kubernetes.name} readOnly />
                            :
                            <input id="name" className="form-control" type="text" name="name" placeholder="Name" value={kubernetes.name} onChange={(e) => handleOnChange(e.target.value, "name")} />
                          }
                        </div>
                      </div>
                      <div className={`col-sm-6 ${kubernetes.name.length > 35 ? "col-md-6" : "col-md-3"}`}>
                        <div className="form-group">
                          <label className="form-label">Status</label>
                          <select id="status" className="form-control" name="status" value={kubernetes.status} disabled={kubernetes.mergestrategy !== "Manual" || (kubernetes.mergestrategy === "Global" && globalMergeStrategy === "Discovery")} onChange={(e) => handleOnChange(e.target.value, "status")}
                          >
                            <option value={true}>On</option>
                            <option value={false}>Off</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label">IP</label>
                          <CreatableSelect id="ip" name="ip" isMulti onChange={(e) => {
                            /* istanbul ignore next */
                            handleOnChange(e, "ip");
                          }} value={kubernetes.ip} components={{ DropdownIndicator: null }} placeholder="IP" isDisabled={kubernetes.mergestrategy !== "Manual" || (kubernetes.mergestrategy === "Global" && globalMergeStrategy === "Discovery")}/>
                        </div>
                      </div>
                      <div className={`col-sm-6 ${kubernetes.cloudlink.length > 35 ? "col-md-6" : "col-md-3"}`}>
                        <div className="form-group">
                          <label className="form-label">Cloud Link</label>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <input id="cloudlink" className="form-control" type="text" name="cloudlink" placeholder="Cloud Link" value={kubernetes.cloudlink} onChange={(e) => handleOnChange(e.target.value, "cloudlink")} />
                            <button id={`cloud-link-${kubernetes._id}`} className="btn btn-pill" style={{ margin: "5px 10px 10px 10px", backgroundColor: "#007bff" }} disabled={!new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&\/\/=]*)/).test(kubernetes.cloudlink)} onClick={() => window.open(kubernetes.cloudlink, "_blank")}>
                              <ExternalLink style={{ height: "15px", width: "auto", color: "white" }} />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className={`col-sm-6 ${kubernetes.datadoglink.length > 35 ? "col-md-6" : "col-md-3"}`}>
                        <div className="form-group">
                          <label className="form-label">Datadog Link</label>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <input id="datadoglink" className="form-control" type="text" name="datadoglink" placeholder="Datadog Link" value={kubernetes.datadoglink} onChange={(e) => handleOnChange(e.target.value, "datadoglink")} />
                            <button id={`datadog-link-${kubernetes._id}`} className="btn btn-pill" style={{ margin: "5px 10px 10px 10px", backgroundColor: "#007bff" }} disabled={!new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&\/\/=]*)/).test(kubernetes.datadoglink)} onClick={() => window.open(kubernetes.datadoglink, "_blank")}>
                              <ExternalLink style={{ height: "15px", width: "auto", color: "white" }} />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className={`col-sm-6 ${kubernetes.transformationphase.length > 35 ? "col-md-6" : "col-md-3"}`}>
                        <div className="form-group">
                          <label className="form-label">Transformation Phase</label>
                          <input id="transformationphase" className="form-control" type="text" name="transformationphase" placeholder="Transformation Phase" value={kubernetes.transformationphase} onChange={(e) => handleOnChange(e.target.value, "transformationphase")} />
                        </div>
                      </div>
                      <div className={`col-sm-6 ${kubernetes.transformationstrategy.length > 35 ? "col-md-6" : "col-md-3"}`}>
                        <div className="form-group">
                          <label className="form-label">Transformation Strategy</label>
                          <input id="transformationstrategy" className="form-control" type="text" name="transformationstrategy" placeholder="Transformation Strategy" value={kubernetes.transformationstrategy} onChange={(e) => handleOnChange(e.target.value, "transformationstrategy")} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label" style={{ marginBottom: "12px" }}>Transformation Group</label>
                          <Select id="transformationgroup" name="transformationgroup" onChange={(e) => {
                            /* istanbul ignore next */
                            handleOnChange(e, "transformationgroup");
                          }} value={kubernetes.transformationgroup} options={transformationgroups} placeholder={"Transformation Group"} isDisabled={
                            kubernetes.transformationgroup.value === "" && kubernetes.jirakey !== "INVALID"} />
                          {kubernetes.transformationgroup.value === "" && kubernetes.jirakey !== "INVALID" && <p style={{ textAlign: "center", marginTop: "10px" }}>Already a part of an application component.</p>}
                        </div>
                      </div>
                      {id && <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label">Jira Ticket</label>
                          {kubernetes.jirakey && kubernetes.jirakey !== "" && kubernetes.jirakey !== "INVALID" ?
                            <div style={{
                              display: "flex",
                              justifyContent: "space-between"
                            }}>
                              <button id={`jira-view-${kubernetes._id}`} className="btn btn-pill" style={{ margin: "5px 0px 5px 0px", backgroundColor: "#007bff", width: "47%" }} onClick={() => window.open(`${jiradomain}/browse/${kubernetes.jirakey}`, "_blank")}>
                                <ExternalLink style={{ height: "15px", width: "auto", color: "white" }} />
                              </button>
                              <button id={`jira-delete-${kubernetes._id}`} className="btn btn-pill" style={{ margin: "5px 0px 5px 0px", backgroundColor: "#e83845", width: "47%" }} onClick={() => deleteJiraTicket()}>
                                <Trash style={{ height: "15px", width: "auto", color: "white" }} />
                              </button>
                            </div>
                            :
                            <div>
                              <button id={`jira-create-${kubernetes._id}`} className="btn form-control btn-primary" disabled={kubernetes.jirakey === "INVALID"} onClick={() => createJiraTicket()}>Create</button>
                              {kubernetes.jirakey === "INVALID" && <p style={{ textAlign: "center" }}>Must be assigned to a migration group.</p>}
                            </div>
                          }
                        </div>
                      </div>
                      }
                      <div className={`col-sm-6 ${kubernetes.discovered.length > 35 ? "col-md-6" : "col-md-3"}`}>
                        <div className="form-group">
                          <label className="form-label">Discovered</label>
                          <input id="discovered" className="form-control" type="text" name="discovered" placeholder="Discovered" value={kubernetes.discovered} readOnly />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label">Tags</label>
                          <CreatableSelect id="tags" name="tags" isMulti onChange={(e) => {
                            /* istanbul ignore next */
                            handleOnChange(e, "tags");
                          }} value={kubernetes.tags} components={{ DropdownIndicator: null }} placeholder="Tags" isDisabled={kubernetes.mergestrategy !== "Manual" || (kubernetes.mergestrategy === "Global" && globalMergeStrategy === "Discovery")}/>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Notes</label>
                          <textarea id="notes" className="form-control" rows={5} type="text" name="notes" placeholder="Notes" value={kubernetes.notes} onChange={(e) => handleOnChange(e.target.value, "notes")} disabled={kubernetes.mergestrategy !== "Manual" || (kubernetes.mergestrategy === "Global" && globalMergeStrategy === "Discovery")}/>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Merge Strategy</label>
                          {["Global", "Discovery", "Manual"].map((mergeStrategy) => {
                            return (
                              <div key={mergeStrategy} className="radio col-sm-6 col-md-12">
                                <input
                                  className="form-control"
                                  id={`mergestrategy-${mergeStrategy.toLowerCase()}`}
                                  type="radio"
                                  value={mergeStrategy}
                                  checked={kubernetes.mergestrategy === mergeStrategy}
                                  onChange={() => handleOnChange(mergeStrategy, "mergestrategy")}
                                  name={mergeStrategy.toLowerCase()}
                                  disabled={["Global", "Discovery"].includes(mergeStrategy) && kubernetes.discovered === "Manual"}
                                />
                                <label htmlFor={`mergestrategy-${mergeStrategy.toLowerCase()}`}>{mergeStrategy}</label>
                                {mergeStrategy === "Global" &&
                                  <p style={{ width: "100%", color: "grey" }}>Merge according to the global setting set by administrators.</p>
                                }

                                {mergeStrategy === "Discovery" &&
                                  <p style={{ width: "100%", color: "grey" }}>Allow new discovery data to override these values.</p>
                                }

                                {mergeStrategy === "Manual" &&
                                  <p style={{ width: "100%", color: "grey" }}>Override discovery data with these values.</p>
                                }
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <Link id="back" to={"/discovery/kubernetes"}>
                      <button className="btn btn-primary text-left" type="submit">Back</button>
                    </Link>
                    <button id="submit" className="btn btn-primary float-right" onClick={() => handleOnSubmit()} type="submit">{id ? "Update" : "Create"}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default KubernetesEdit;




