import React, { Fragment } from "react";
import { terraformActions } from "../pd-list/TerraformActions";


const ConfigureTerraform = (props) => {

  const handleChangeGitlab = () => {
    props.setGitlab(!props.gitlab);
  };
  const handleChangeAwx = () => {
    props.setAwx(!props.awx);
  };
  const handleChangeHarbor = () => {
    props.setHarbor(!props.harbor);
  };
  const handleChangeSonarqube = () => {
    props.setSonarqube(!props.sonarqube);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12">
          <form className="needs-validation" noValidate>
            <div className="form-row">

              <div className="col-md-12 mb-3">
                <label htmlFor="Terraform Action">{"Terraform Action"}</label>
                <select
                  className="form-control"
                  placeholder="apply"
                  value={props.terraformAction}
                  onChange={(e) => props.setTerraformAction(e.target.value)}
                  required
                >
                  <option key="" value="" disabled>Select</option>
                  {terraformActions.map((terraformAction, index) => (
                    <option key={index} value={terraformAction}>{terraformAction}</option>
                  ))}
                </select>
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="Deploy Kubernetes Cluster">{"Deploy Kubernetes Cluster"}</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="eks-dev-cluster"
                  readOnly
                  required
                />
              </div>
              <div className="form-group">
                <div className="form-check">
                  <label>
                    <input
                      type="checkbox"
                      name=""
                      readOnly
                      checked="true"
                      disabled="disabled"
                    /> NGINX Ingress
                  </label>
                </div>

                <div className="form-check">
                  <label>
                    <input
                      type="checkbox"
                      name="corepackages"
                      readOnly
                      checked="true"
                      disabled="disabled"
                    /> ExternalDNS
                  </label>
                </div>

                <div className="form-check">
                  <label>
                    <input
                      type="checkbox"
                      name="corepackages"
                      readOnly
                      checked="true"
                      disabled="disabled"
                    /> CertManager
                  </label>
                </div>

                <div className="form-check">
                  <label>
                    <input
                      type="checkbox"
                      name="corepackages"
                      readOnly
                      checked="true"
                      disabled="disabled"
                    /> Vault
                  </label>
                </div>

                <div className="form-check">
                  <label>
                    <input
                      type="checkbox"
                      name="Gitlab"
                      checked={props.gitlab}
                      onChange={handleChangeGitlab}
                    /> Gitlab
                  </label>
                </div>

                <div className="form-check">
                  <label>
                    <input
                      type="checkbox"
                      name="AWX"
                      checked={props.awx}
                      onChange={handleChangeAwx}
                    /> AWX
                  </label>
                </div>

                <div className="form-check">
                  <label>
                    <input
                      type="checkbox"
                      name="Harbor"
                      checked={props.harbor}
                      onChange={handleChangeHarbor}
                    /> Harbor
                  </label>
                </div>

                <div className="form-check">
                  <label>
                    <input
                      type="checkbox"
                      name="Sonarqube"
                      checked={props.sonarqube}
                      onChange={handleChangeSonarqube}
                    /> Sonarqube
                  </label>
                </div>
              </div>

            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default ConfigureTerraform;