import React, { Fragment } from "react";

const ConfigureSso = (props) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12">
          <form className="needs-validation" noValidate>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="SSO Email">{"SSO Email"}</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder={props.accountName + "-account@lz.datahubnow.com"}                  
                  value={props.accountName + "-account@lz.datahubnow.com"} 
                  onChange={(e) => props.setSsoEmail(e.target.value + "-account@lz.datahubnow.com")}
                  required
                />
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="SSO First Name">{"SSO First Name"}</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Simon"
                  value={props.ssoFirstName}
                  onChange={(e) => props.setSsoFirstName(e.target.value)}
                  required
                />
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="SSO Last Name">{"SSO Last Name"}</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Rees"
                  value={props.ssoLastName}
                  onChange={(e) => props.setSsoLastName(e.target.value)}
                  required
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default ConfigureSso;