import React, { Fragment } from "react";

const Target = (props) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12">
          <form className="needs-validation" noValidate>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label>{"Host"}</label>
                <input
                  id="host"
                  className="form-control"
                  type="text"
                  placeholder="Host"
                  value={props.targetConnectionParameters.host}
                  onChange={(e) => props.setTargetConnectionParameters({ ...props.targetConnectionParameters, host: e.target.value })}
                  required
                />
              </div>

              <div className="col-md-12 mb-3">
                <label>{"Port"}</label>
                <input
                  id="port"
                  className="form-control"
                  type="number"
                  required
                  value={props.targetConnectionParameters.port}
                  onChange={(e) => props.setTargetConnectionParameters({ ...props.targetConnectionParameters, port: e.target.value })}
                />
              </div>

              <div className="col-md-12 mb-3">
                <label>{"Database Name"}</label>
                <input
                  id="databaseName"
                  className="form-control"
                  type="text"
                  placeholder="Database Name"
                  value={props.targetConnectionParameters.databaseName}
                  onChange={(e) => props.setTargetConnectionParameters({ ...props.targetConnectionParameters, databaseName: e.target.value })}
                  required
                />
              </div>

              <div className="col-md-12 mb-3">
                <label>{"DB Version"}</label>
                <select
                  id="dbVersion"
                  className="form-control"
                  value={props.targetConnectionParameters.dbVersion}
                  onChange={(e) => props.setTargetConnectionParameters({ ...props.targetConnectionParameters, dbVersion: e.target.value })}
                  required
                >
                  <option value="" disabled>Select</option>
                  <option value="9.6">9.6</option>
                  <option value="10">10</option>
                  <option value="11">1</option>
                </select>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default Target;