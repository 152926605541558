import React, { Fragment } from "react";
import { Submit } from "../../../constant";
import axios from "axios";

const Review = (props) => {
  const submitForm = (e) => {
    e.preventDefault();
    axios.post(props.id ? `${process.env.REACT_APP_BACKEND_URL}/api/orders/update-AZRVM-order/${props.id}` : `${process.env.REACT_APP_BACKEND_URL}/api/orders/order-AZRVM`, {
      name: props.orderName,
      order: {
        instanceName: props.instanceName,
        instanceType: props.instanceType,
        numberOfInstances: props.numberOfInstances,
        region: props.region,
        status: props.status,
        statusId: props.statusId
      },
      action: props.action,
      type: "azrvm"
    })
      .then(() => {
        window.location = "/infrastructure/azr/AZRVM-build";
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12">
          <form className="needs-validation" noValidate>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label>{"Virtual Machine Name:"} {props.instanceName}</label>
              </div>

              <div className="col-md-12 mb-3">
                <label>{"Instance Machine Type:"} {props.instanceType}</label>
              </div>

              <div className="col-md-12 mb-3">
                <label>{"Number Of Instances:"} {props.numberOfInstances}</label>
              </div>

              <div className="col-md-12 mb-3">
                <label>{"Region:"} {props.region}</label>
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="Order Name">{"Order Name"}</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Order Name"
                  value={props.orderName}
                  onChange={(e) => props.setOrderName(e.target.value)}
                  required
                />
              </div>

              <div className="col-md-12 mb-3">
                <label>{"Action"}</label>
                <select
                  className="form-control"
                  value={props.action}
                  onChange={(e) => props.setAction(e.target.value)}
                  required
                >
                  <option value="" disabled>Select</option>
                  <option value="PLAN">Plan</option>
                  <option value="PROVISION">Provision</option>
                  <option value="DESTROY">Destroy</option>
                </select>
              </div>

              <button className="btn btn-primary r-7 btnsubmit" onClick={(e) => submitForm(e)}>{Submit}</button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default Review;