import React from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import Links from "../../components/common/Links";

const ServiceNow = () => {
  return (
    <div>
      <Breadcrumb title="ServiceNow" parent="Service" />
      <Links pathname={window.location.pathname} />
    </div>
  );
};
export default ServiceNow;
