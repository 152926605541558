import React, { Fragment } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import DataTable from "react-data-table-component";
import axios from "axios";
import FilterComponent from "../../../components/common/FilterComponent";
import "react-dropzone-uploader/dist/styles.css";
import { toast } from "react-toastify";
import { setToast } from "../../../components/common/Toast";
import { tableStyles } from "../../../components/common/CustomStyles";
import { Link } from "react-router-dom";
import { Edit, Trash } from "react-feather";

const ApplicationComponentsList = () => {
  const [applicationComponents, setApplicationComponents] = React.useState([]);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

  const getApplicationComponents = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/application-components`)
      .then(async (response) => {
        for (const appComp of response.data) {
          const databases = [];
          for await (let database of appComp.databases) {
            await new Promise((resolve) => {
              axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/databases/get-database/latest/${database}`)
                .then((response) => {
                  databases.push({
                    _id: response.data.database._id,
                    name: response.data.database.name
                  });
                  resolve();
                }).catch(() => {
                  setToast(toast.error, "Unable to retrieve database");
                  resolve();
                });
            });
          }

          appComp.databases = databases;

          const physicalservers = [];
          for await (let server of appComp.physicalservers) {
            await new Promise((resolve) => {
              axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/physical-servers/get-server/latest/${server}`)
                .then((response) => {
                  physicalservers.push({
                    _id: response.data.server._id,
                    name: response.data.server.name
                  });
                  resolve();
                }).catch(() => {
                  setToast(toast.error, "Unable to retrieve physical server");
                  resolve();
                });
            });
          }

          appComp.physicalservers = physicalservers;

          const virtualservers = [];
          for await (let server of appComp.virtualservers) {
            await new Promise((resolve) => {
              axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/virtual-servers/get-server/latest/${server}`)
                .then((response) => {
                  virtualservers.push({
                    _id: response.data.server._id,
                    name: response.data.server.name
                  });
                  resolve();
                }).catch(() => {
                  setToast(toast.error, "Unable to retrieve virtual server");
                  resolve();
                });
            });
          }

          appComp.virtualservers = virtualservers;

          const kubernetes = [];
          for await (let kb of appComp.kubernetes) {
            await new Promise((resolve) => {
              axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/kubernetes/get-pod/latest/${kb}`)
                .then((response) => {
                  kubernetes.push({
                    _id: response.data.pod._id,
                    name: response.data.pod.name
                  });
                  resolve();
                }).catch(() => {
                  setToast(toast.error, "Unable to retrieve kubernetes");
                  resolve();
                });
            });
          }

          appComp.kubernetes = kubernetes;
        }

        setApplicationComponents(response.data);
      }).catch(() => {
        setToast(toast.error, "Unable to retrieve application components");
      });
  };

  React.useEffect(() => {
    getApplicationComponents();
  }, []);

  const filteredItems = applicationComponents.filter(
    (item) => item.component && item.component.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText !== "") {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <div>
          <Link id="create-application-component" className="btn btn-primary" to="/discovery/application-components/add" type="button">Create Application Component</Link>
        </div>
        <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholder={"Component"} />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const deleteApplicationComponent = (data) => {
    if (window.confirm("Are you sure you want to delete this application component?")) {
      axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/application-components/${data._id}`)
        .then(() => {
          setToast(toast.success, "Application component deleted");
          getApplicationComponents();
        }).catch(() => {
          setToast(toast.error, "Unable to delete application component");
        });
    }
  };

  /* istanbul ignore next */
  const columns = [
    {
      name: "Application Component",
      selector: (component) => component.component,
      center: true,
      sortable: true,
      wrap: true,
      width: "17rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Actions",
      center: true,
      sortable: true,
      wrap: true,
      width: "7rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) =>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <button id={`edit-${data._id}`} className="btn btn-pill" style={{ margin: "5px 0px 5px 0px", backgroundColor: "#22af47" }} onClick={() => window.location.assign(`/discovery/application-components/edit/${data._id}`)}>
            <Edit style={{ height: "15px", width: "auto", color: "white" }} />
          </button>
          <button id={`delete-${data._id}`} className="btn btn-pill" style={{ margin: "5px 0px 5px 0px", backgroundColor: "#e83845" }} onClick={() => deleteApplicationComponent(data)}>
            <Trash style={{ height: "15px", width: "auto", color: "white" }} />
          </button>
        </div>
    },
    {
      name: "Description",
      selector: (component) => component.description,
      center: true,
      sortable: true,
      wrap: true,
      width: "16rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Databases",
      selector: (component) => component.databases,
      center: true,
      sortable: true,
      wrap: true,
      width: "20rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) => {
        return (
          <ul style={{ textAlign: "center" }}>
            {data.databases.map((database) => {
              return (
                <li style={{ margin: "15px" }} key={database._id}>
                  <Link to={`/discovery/databases/edit/latest/${database._id}`} style={{ color: "rgba(0,0,0,0.87)" }}>
                    {database.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        );
      }
    },
    {
      name: "Physical Servers",
      selector: (component) => component.physicalservers,
      center: true,
      sortable: true,
      wrap: true,
      width: "20rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) => {
        return (
          <ul style={{ textAlign: "center" }}>
            {data.physicalservers.map((physicalServer) => {
              return (
                <li style={{ margin: "15px" }} key={physicalServer._id}>
                  <Link to={`/discovery/physical-servers/edit/latest/${physicalServer._id}`} style={{ color: "rgba(0,0,0,0.87)" }}>
                    {physicalServer.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        );
      }
    },
    {
      name: "Virtual Servers",
      selector: (component) => component.virtualservers,
      center: true,
      sortable: true,
      wrap: true,
      width: "20rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) => {
        return (
          <ul style={{ textAlign: "center" }}>
            {data.virtualservers.map((virtualServer) => {
              return (
                <li style={{ margin: "15px" }} key={virtualServer._id}>
                  <Link to={`/discovery/virtual-servers/edit/latest/${virtualServer._id}`} style={{ color: "rgba(0,0,0,0.87)" }}>
                    {virtualServer.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        );
      }
    },
    {
      name: "Kubernetes",
      selector: (component) => component.kubernetes,
      center: true,
      sortable: true,
      wrap: true,
      width: "20rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) => {
        return (
          <ul style={{ textAlign: "center" }}>
            {data.kubernetes.map((pod) => {
              return (
                <li style={{ margin: "15px" }} key={pod._id}>
                  <Link to={`/discovery/kubernetes/edit/latest/${pod._id}`} style={{ color: "rgba(0,0,0,0.87)" }}>
                    {pod.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        );
      }
    }
  ];

  return (
    <Fragment>
      <Breadcrumb title="Application Components" parent="Discovery" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{"Table of Application Components"}</h5>
              </div>
              <div className="card-body datatable-react">
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  customStyles={tableStyles}
                  striped={true}
                  center={true}
                  subHeaderComponent={subHeaderComponentMemo}
                  subHeader
                  persistTableHead
                  pagination
                  responsive
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default ApplicationComponentsList;




