import React, { Fragment } from "react";
import { Submit } from "../../../constant";
import axios from "axios";
import { useAuthUser } from "../../../authUserContext";

const Review = (props) => {
  const { authUser } = useAuthUser();

  const submitForm = (e) => {
    e.preventDefault();
    axios.post(props.id ? `${process.env.REACT_APP_BACKEND_URL}/api/orders/update-vend-account-order/${props.id}` : `${process.env.REACT_APP_BACKEND_URL}/api/orders/vend-account`, {
      name: props.accountName,
      order: {
        accountName: props.accountName,
        accountEmail: props.accountEmail,
        accountUser: props.accountUser,
        ou: props.ou,
        ouId: props.ouId,
        ssoEmail: props.ssoEmail,
        ssoFirstName: props.ssoFirstName,
        ssoLastName: props.ssoLastName,
        // guardrails: props.guardrails,
        changeRequestedBy: authUser.name,
        changeReason: props.changeReason,
        gitlab: props.gitlab.toString(),
        awx: props.awx.toString(),
        harbor: props.harbor.toString(),
        sonarqube: props.sonarqube.toString()
      },
      terraformAction: props.terraformAction.toLowerCase(),
      type: "aws"
    })
      .then(() => {
        window.location = "/infrastructure/aws/vend-account";
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12">
          <form className="needs-validation" noValidate>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label>{"Account Name:"} {props.accountName}</label>
              </div>

              <div className="col-md-12 mb-3">
                <label>{"Account Email:"} {props.accountEmail}</label>
              </div>

              <div className="col-md-12 mb-3">
                <label>{"Account User:"} {props.accountUser}</label>
              </div>

              <div className="col-md-12 mb-3">
                <label>{"Organizational Unit:"} {props.ou}</label>
              </div>

              <div className="col-md-12 mb-3">
                <label>{"Organizational Unit ID:"} {props.ouId}</label>
              </div>

              <div className="col-md-12 mb-3">
                <label>{"SSO Email:"} {props.ssoEmail}</label>
              </div>

              <div className="col-md-12 mb-3">
                <label>{"SSO First Name:"} {props.ssoFirstName}</label>
              </div>

              <div className="col-md-12 mb-3">
                <label>{"SSO Last Name:"} {props.ssoLastName}</label>
              </div>

              {/* <div className="col-md-12 mb-3">
                <label>{"Guardrails:"} {props.guardrails}</label>
              </div> */}

              {/* <div className="col-md-12 mb-3">
                <label htmlFor="Order Name">{"Order Name"}</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Order Name"
                  value={props.orderName}
                  onChange={(e) => props.setOrderName(e.target.value)}
                  required
                />
              </div> */}

              {/* <div className="col-md-12 mb-3">
                <label htmlFor="Change Reason">{"Change Reason"}</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Change Reason"
                  value={props.changeReason}
                  onChange={(e) => props.setChangeReason(e.target.value)}
                  required
                />
              </div> */}

              <div className="col-md-12 mb-3">
                <label>{"Terraform Action:"} {props.terraformAction}</label>
              </div>

              <div className="col-md-12 mb-3">
                <label>{"Install Gitlab:"} { String(props.gitlab) }</label>
              </div>

              <div className="col-md-12 mb-3">
                <label>{"Install AWX:"} { String(props.awx) }</label>
              </div>

              <div className="col-md-12 mb-3">
                <label>{"Install Harbor:"} { String(props.harbor) }</label>
              </div>

              <div className="col-md-12 mb-3">
                <label>{"Install Sonarqube:"} { String(props.sonarqube) }</label>
              </div>

              <div className="col-md-12 mb-3">
                <label>{"Optional Packages:"} { String(props.optionalPackages) }</label>
              </div>

              <button className="btn btn-primary r-7 btnsubmit" onClick={(e) => submitForm(e)}>{Submit}</button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default Review;