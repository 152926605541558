import React, { Fragment } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import axios from "axios";
import "react-dropzone-uploader/dist/styles.css";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { setToast } from "../../../components/common/Toast";
import Select from "react-select";

const MigrationGroupEdit = () => {
  const [migrationGroup, setMigrationGroup] = React.useState({
    name: "",
    description: "",
    jiraproject: "",
    businessapplications: []
  });
  const [businessApplications, setBusinessApplications] = React.useState(undefined);
  const { id } = useParams();

  React.useEffect(() => {
    axios.get(id ? `${process.env.REACT_APP_BACKEND_URL}/api/migration-groups/business-applications/${id}` : `${process.env.REACT_APP_BACKEND_URL}/api/migration-groups/business-applications`)
      .then((response) => {
        setBusinessApplications(response.data.map((businessApplication) => {
          return {
            value: businessApplication._id,
            label: businessApplication.application
          };
        }));
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve business applications");
        setBusinessApplications(null);
      });
  }, []);

  React.useEffect(() => {
    if (id && businessApplications) {
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/migration-groups/${id}`)
        .then((response) => {
          /* istanbul ignore next */
          setMigrationGroup({
            ...response.data,
            businessapplications: response.data.businessapplications.map((busApp) => {
              const businessApplication = businessApplications.find((item) => item.value === busApp);

              if (businessApplication) {
                return businessApplication;
              }
            })
          });
        })
        .catch(() => {
          setToast(toast.error, "Unable to retrieve migration group");
        });
    }
  }, [id, businessApplications]);

  const handleOnChange = (value, name) => {
    setMigrationGroup({ ...migrationGroup, [name]: value });
  };

  const handleOnSubmit = () => {
    axios.post(id ? `${process.env.REACT_APP_BACKEND_URL}/api/migration-groups/${id}` : `${process.env.REACT_APP_BACKEND_URL}/api/migration-groups`, id ? {
      name: migrationGroup.name,
      description: migrationGroup.description,
      businessapplications: migrationGroup.businessapplications.map((item) => item.value)
    } : {
      name: migrationGroup.name,
      description: migrationGroup.description,
      jiraproject: migrationGroup.jiraproject,
      businessapplications: migrationGroup.businessapplications.map((item) => item.value)
    })
      .then(() => {
        window.location.assign("/dashboard/migration-groups");
      })
      .catch(() => {
        setToast(toast.error, `Unable to ${id ? "update" : "create"} migration group`);
      });
  };

  return (
    <Fragment>
      <Breadcrumb title={id ? "Edit" : "Create"} parent="Migration Groups" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{id ? "Edit" : "Create"} Migration Group Information</h5>
              </div>
              <div className="card-body datatable-react">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title mb-0">{`Migration Group: ${migrationGroup.name}`}</h4>
                    <div className="card-options">
                      <a className="card-options-collapse" href="javascript" data-toggle="card-collapse">
                        <i className="fe fe-chevron-up"></i>
                      </a>
                      <a className="card-options-remove" href="javascript" data-toggle="card-remove">
                        <i className="fe fe-x"></i>
                      </a>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Name</label>
                          <input id="name" className="form-control" type="text" name="name" placeholder="Name" value={migrationGroup.name} onChange={(e) => handleOnChange(e.target.value, "name")} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Description</label>
                          <input id="description" className="form-control" type="text" name="description" placeholder="Description" value={migrationGroup.description} onChange={(e) => handleOnChange(e.target.value, "description")} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Jira Project</label>
                          <input id="jiraproject" className="form-control" type="text" name="jiraproject" readOnly={id ? true : false} placeholder="Jira Project" maxLength={10} value={migrationGroup.jiraproject} onChange={(e) => handleOnChange(e.target.value.toUpperCase(), "jiraproject")} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label" style={{ marginBottom: "12px" }}>Business Applications</label>
                          <Select id="businessapplications" name="businessapplications" isMulti options={businessApplications} isLoading={businessApplications === undefined} onChange={(e) => {
                            /* istanbul ignore next */
                            handleOnChange(e, "businessapplications");
                          }} value={migrationGroup.businessapplications} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <Link id="back" to={"/dashboard/migration-groups"}><button className="btn btn-primary text-left" type="submit">Back</button></Link>
                    <button id="submit" className="btn btn-primary float-right" onClick={handleOnSubmit} type="submit">{id ? "Update" : "Create"}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default MigrationGroupEdit;
