import React from "react";

const Footer = () => {
  const year = new Date();
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 footer-copyright">
            <p className="mb-0">Copyright {year.getFullYear()} &copy; Fujitsu. All rights reserved. {process.env.REACT_APP_ENV === "DEV" && "Development Build."}</p>
          </div>
        </div>
      </div>
    </footer>
  );};

export default Footer;