import React, { createContext, useState, useEffect, useMemo, useContext } from "react";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";

export const AuthUserContext = createContext(null);

export const AuthUserProvider = ({ children }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [authUser, setAuthUser] = useState(undefined);

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      oktaAuth.token.getUserInfo()
        .then(async (user) => {
          user.accessToken = (await oktaAuth.tokenManager.get("accessToken")).accessToken;

          axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/admin/roles/sections`, {
            groups: user.groups
          }, {
            headers: {
              Authorization: `Bearer ${user.accessToken}`
            }
          })
            .then((response) => {
              setAuthUser({ ...user, sections: response.data });
            })
            .catch((err) => {
              console.log(err);

              if (!err.response) {
                if (window.location.pathname !== "/500") {
                  window.location.pathname = "/500";
                }
              }

              if (err.response.status === 401) {
                if (window.location.pathname !== "/401") {
                  window.location.pathname = "/401";
                }
              } else {
                setAuthUser({ ...user, sections: [] });

                if (window.location.pathname !== "/500") {
                  window.location.pathname = "/500";
                }
              }
            });
        })
        .catch((err) => {
          console.error(err);

          if (window.location.pathname !== "/500") {
            window.location.pathname = "/500";
          }
        });
    }
  }, [authState]);

  const values = useMemo(() => ({ authUser }), [authUser]);

  return (
    <AuthUserContext.Provider value={values}>
      {children}
    </AuthUserContext.Provider>
  );
};

export const useAuthUser = () => {
  const context = useContext(AuthUserContext);

  if (context === undefined) {
    throw new Error("`useAuthUser` hook must be used within a `AuthUserProvider` component");
  }

  return context;
};