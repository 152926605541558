export const instances = [
  "Please select",
  "f1-micro",
  "e2-micro",
  "e2-small",
  "g1-small",
  "e2-medium",
  "t2d-standard-1",
  "n1-standard-1",
  "e2-highcpu-2",
  "n2d-highcpu-2",
  "e2-standard-2",
  "n1-highcpu-2",
  "n2-highcpu-2",
  "c2d-highcpu-2",
  "n2d-standard-2",
  "t2d-standard-2",
  "e2-highmem-2",
  "c2d-standard-2",
  "n1-standard-2",
  "n2-standard-2",
  "e2-highcpu-4",
  "n2d-highmem-2",
  "n1-highmem-2",
  "c2d-highmem-2",
  "n2d-highcpu-4",
  "n2-highmem-2",
  "e2-standard-4",
  "n1-highcpu-4",
  "n2-highcpu-4",
  "c2d-highcpu-4",
  "n2d-standard-4",
  "t2d-standard-4",
  "e2-highmem-4",
  "c2d-standard-4",
  "n1-standard-4",
  "n2-standard-4",
  "e2-highcpu-8",
  "c2-standard-4",
  "n2d-highmem-4",
  "n1-highmem-4",
  "c2d-highmem-4",
  "n2d-highcpu-8",
  "n2-highmem-4",
  "e2-standard-8",
  "n1-highcpu-8",
  "n2-highcpu-8",
  "c2d-highcpu-8",
  "n2d-standard-8",
  "t2d-standard-8",
  "e2-highmem-8",
  "c2d-standard-8",
  "n1-standard-8",
  "n2-standard-8",
  "e2-highcpu-16",
  "c2-standard-8",
  "n2d-highmem-8",
  "n1-highmem-8",
  "c2d-highmem-8",
  "n2d-highcpu-16",
  "n2-highmem-8",
  "e2-standard-16",
  "n1-highcpu-16",
  "n2-highcpu-16",
  "c2d-highcpu-16",
  "n2d-standard-16",
  "t2d-standard-16",
  "e2-highmem-16",
  "c2d-standard-16",
  "a2-highgpu-1g",
  "n1-standard-16",
  "n2-standard-16",
  "e2-highcpu-32",
  "c2-standard-16",
  "n2d-highmem-16",
  "n1-highmem-16",
  "c2d-highmem-16",
  "n2d-highcpu-32",
  "n2-highmem-16",
  "e2-standard-32",
  "n1-highcpu-32",
  "n2-highcpu-32",
  "c2d-highcpu-32",
  "n2d-standard-32",
  "t2d-standard-32",
  "c2d-standard-32",
  "a2-highgpu-2g",
  "n2d-highcpu-48",
  "n1-standard-32",
  "n2-standard-32",
  "c2-standard-30",
  "n2-highcpu-48",
  "n2d-highmem-32",
  "n1-highmem-32",
  "c2d-highmem-32",
  "n2d-highcpu-64",
  "n2d-standard-48",
  "t2d-standard-48",
  "n2-highmem-32",
  "c2d-highcpu-56",
  "n1-highcpu-64",
  "n2-highcpu-64",
  "n2-standard-48",
  "n2d-highcpu-80",
  "t2d-standard-60",
  "c2d-standard-56",
  "n2d-standard-64",
  "n2d-highmem-48",
  "n2-highcpu-80",
  "a2-highgpu-4g",
  "n2d-highcpu-96",
  "n1-standard-64",
  "n2-standard-64",
  "c2-standard-60",
  "n2-highmem-48",
  "n2d-standard-80",
  "n1-highcpu-96",
  "c2d-highmem-56",
  "n2-highcpu-96",
  "n2d-highmem-64",
  "n1-highmem-64",
  "n2-standard-80",
  "n2d-highcpu-128",
  "n2d-standard-96",
  "n2-highmem-64",
  "c2d-highcpu-112",
  "n2d-highmem-80",
  "n1-standard-96",
  "n2-standard-96",
  "c2d-standard-112",
  "n2-highmem-80",
  "n2d-standard-128",
  "n2d-highmem-96",
  "n1-highmem-96",
  "a2-highgpu-8g",
  "n2-standard-128",
  "n2-highmem-96",
  "m1-ultramem-40",
  "c2d-highmem-112",
  "n2d-highcpu-224",
  "n2-highmem-128",
  "a2-megagpu-16g",
  "n2d-standard-224",
  "m1-megamem-96",
  "m1-ultramem-80",
  "m1-ultramem-160",
  "m2-ultramem-208",
  "m2-ultramem-416"
];