import React, { Fragment } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import products from "./product.json";

const Product = () => {
  const handleClick = (product) => {
    window.location.assign(product.orderLink);
  };

  return (
    <Fragment>
      <Breadcrumb title="Products" parent="Catalogue" />
      <div className="container-fluid product-wrapper">
        <div className="product-grid">
          <div className="product-wrapper-grid">
            <div className="row gridRow">
              {products && products.map((item, i) =>
                <div id="item" className="col-xl-3 col-sm-6 xl-4 col-grid-box" key={i}>
                  <div className="card">
                    <div className="product-box">
                      <div className="product-img">
                        <img className="img-fluid" src={require("../../assets/images/" + item.img)} alt="" />
                        <div className="product-hover">
                          <ul>
                            <li>
                              <button id={`add-icon-${item.id}`} className="btn" type="button" onClick={() => handleClick(item)}>
                                <i className="icon-plus"></i>
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="product-details">
                        <h5>
                          <a id={`text-link-${item.id}`} onClick={() => handleClick(item)} className="font-primary" href="#javascript">
                            {item.name}
                          </a></h5>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Product;
