import React, { Fragment } from "react";
import { instances } from "../azr-list/Instances";

const ConfigureResource = (props) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12">
          <form className="needs-validation" noValidate>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="Virtual Machine Name">{"Virtual Machine Name"}</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Virtual Machine Name"
                  value={props.instanceName}
                  onChange={(e) => props.setInstanceName(e.target.value)}
                  required
                />
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="Virtual Machine Size">{"Virtual Machine Size"}</label>
                <select
                  className="form-control"
                  value={props.instanceType}
                  onChange={(e) => props.setInstanceType(e.target.value)}
                  required
                >
                  <option key="" value="" disabled>Select</option>
                  {instances.map((instance, index) => (
                    <option key={index} value={instance}>{instance}</option>
                  ))}
                </select>
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="Number Of Instances">{"Number Of Instances"}</label>
                <input
                  className="form-control"
                  type="number"
                  maxLength={1}
                  max={5}
                  min={1}
                  required
                  value={props.numberOfInstances}
                  onChange={(e) => props.setNumberOfInstances(e.target.value)}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default ConfigureResource;