import React, { Fragment } from "react";
import "survey-react/survey.css";
import * as Survey from "survey-react";
import Breadcrumb from "../../components/common/breadcrumb";
import axios from "axios";
import { setToast } from "../../components/common/Toast";
import { toast } from "react-toastify";

const surveyJSON = {
  "title": "8R Identification Model",
  "logoPosition": "right",
  "completedHtml": "<h3>Thank you for your feedback.</h3><h5>Your thoughts and ideas will help us to create a great product!</h5>",
  "completedHtmlOnCondition": [
    {
      "html": "<h3>Thank you for your feedback.</h3><h5>We glad that you love our product. Your ideas and suggestions will help us to make our product even better!</h5>"
    },
    {
      "html": "<h3>Thank you for your feedback.</h3><h5> We are glad that you share with us your ideas.We highly value all suggestions from our customers. We do our best to improve the product and reach your expectation.</h5><br />"
    }
  ],
  "pages": [
    {
      "name": "page1",
      "elements": [
        {
          "type": "matrixdropdown",
          "name": "question1",
          "title": "What is the estimated total number of active concurrent users for this application? (Translated as a % of total user base)",
          "isRequired": true,
          "columns": [
            {
              "name": "Answer",
              "cellType": "text",
              "isRequired": true
            },
            {
              "name": "Tech Focus",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                "TI: Cost",
                "TI: Complexity",
                "TI: Risk",
                "BV: Fit",
                "BV: Value"
              ]
            }
          ],
          "cellType": "text",
          "rows": [
            {
              "value": "Q1",
              "text": "Q1"
            }
          ]
        },
        {
          "type": "matrixdropdown",
          "name": "question2",
          "title": "What is the quantity of application objects/modules with unsupported technologies?",
          "isRequired": true,
          "columns": [
            {
              "name": "Answer",
              "cellType": "text",
              "isRequired": true
            },
            {
              "name": "Tech Focus",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                "TI: Cost",
                "TI: Complexity",
                "TI: Risk",
                "BV: Fit",
                "BV: Value"
              ]
            }
          ],
          "cellType": "text",
          "rows": [
            {
              "value": "Q2",
              "text": "Q2"
            }
          ]
        },
        {
          "type": "matrixdropdown",
          "name": "question3",
          "title": "Roughly what percentage of the Technology Stack is close to End of Life or at End of Life?",
          "isRequired": true,
          "columns": [
            {
              "name": "Answer",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                {
                  "value": "25",
                  "text": "25%"
                },
                {
                  "value": "50",
                  "text": "50%"
                },
                {
                  "value": "75",
                  "text": "75%"
                },
                {
                  "value": "75",
                  "text": "75%+"
                }
              ]
            },
            {
              "name": "Tech Focus",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                "TI: Cost",
                "TI: Complexity",
                "TI: Risk",
                "BV: Fit",
                "BV: Value"
              ]
            }
          ],
          "choices": [
            "TI: Cost",
            "TI: Complexity",
            "TI: Risk",
            "BV: Fit",
            "BV: Value"
          ],
          "cellType": "text",
          "rows": [
            {
              "value": "Q3",
              "text": "Q3"
            }
          ]
        },
        {
          "type": "matrixdropdown",
          "name": "question4",
          "title": "How critical is the application to the business? (i.e. The impact if the application is not available)",
          "isRequired": true,
          "columns": [
            {
              "name": "Answer",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                {
                  "value": "Very Low",
                  "text": "Very Low"
                },
                {
                  "value": "Low",
                  "text": "Low"
                },
                {
                  "value": "Medium",
                  "text": "Medium"
                },
                {
                  "value": "High",
                  "text": "High"
                },
                {
                  "value": "Very High",
                  "text": "Very High"
                }
              ]
            },
            {
              "name": "Tech Focus",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                "TI: Cost",
                "TI: Complexity",
                "TI: Risk",
                "BV: Fit",
                "BV: Value"
              ]
            }
          ],
          "choices": [
            "TI: Cost",
            "TI: Complexity",
            "TI: Risk",
            "BV: Fit",
            "BV: Value"
          ],
          "cellType": "text",
          "rows": [
            {
              "value": "Q4",
              "text": "Q4"
            }
          ]
        },
        {
          "type": "matrixdropdown",
          "name": "question5",
          "title": "How was the the availability of the application during the previous 12 months?",
          "isRequired": true,
          "columns": [
            {
              "name": "Answer",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                {
                  "value": "Low",
                  "text": "Low"
                },
                {
                  "value": "Medium",
                  "text": "Medium"
                },
                {
                  "value": "High",
                  "text": "High"
                }
              ]
            },
            {
              "name": "Tech Focus",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                "TI: Cost",
                "TI: Complexity",
                "TI: Risk",
                "BV: Fit",
                "BV: Value"
              ]
            }
          ],
          "choices": [
            "TI: Cost",
            "TI: Complexity",
            "TI: Risk",
            "BV: Fit",
            "BV: Value"
          ],
          "cellType": "text",
          "rows": [
            {
              "value": "Q5",
              "text": "Q5"
            }
          ]
        },
        {
          "type": "matrixdropdown",
          "name": "question6",
          "title": "Does a remediation Roadmap/Plan to patch and retain exist?",
          "isRequired": true,
          "columns": [
            {
              "name": "Answer",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                {
                  "value": "Yes",
                  "text": "Yes"
                },
                {
                  "value": "No",
                  "text": "No"
                }
              ]
            },
            {
              "name": "Tech Focus",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                "TI: Cost",
                "TI: Complexity",
                "TI: Risk",
                "BV: Fit",
                "BV: Value"
              ]
            }
          ],
          "choices": [
            "TI: Cost",
            "TI: Complexity",
            "TI: Risk",
            "BV: Fit",
            "BV: Value"
          ],
          "cellType": "text",
          "rows": [
            {
              "value": "Q6",
              "text": "Q6"
            }
          ]
        },
        {
          "type": "matrixdropdown",
          "name": "question7",
          "title": "Is the application of strategic importance? Does the application support the core business?",
          "isRequired": true,
          "columns": [
            {
              "name": "Answer",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                {
                  "value": "Yes",
                  "text": "Yes"
                },
                {
                  "value": "No",
                  "text": "No"
                }
              ]
            },
            {
              "name": "Tech Focus",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                "TI: Cost",
                "TI: Complexity",
                "TI: Risk",
                "BV: Fit",
                "BV: Value"
              ]
            }
          ],
          "choices": [
            "TI: Cost",
            "TI: Complexity",
            "TI: Risk",
            "BV: Fit",
            "BV: Value"
          ],
          "cellType": "text",
          "rows": [
            {
              "value": "Q7",
              "text": "Q7"
            }
          ]
        },
        {
          "type": "matrixdropdown",
          "name": "question8",
          "title": "Do other applications have affinity with, or are dependent upon data/traffic produced by, this application?",
          "isRequired": true,
          "columns": [
            {
              "name": "Answer",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                {
                  "value": "Yes",
                  "text": "Yes"
                },
                {
                  "value": "No",
                  "text": "No"
                }
              ]
            },
            {
              "name": "Tech Focus",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                "TI: Cost",
                "TI: Complexity",
                "TI: Risk",
                "BV: Fit",
                "BV: Value"
              ]
            }
          ],
          "choices": [
            "TI: Cost",
            "TI: Complexity",
            "TI: Risk",
            "BV: Fit",
            "BV: Value"
          ],
          "cellType": "text",
          "rows": [
            {
              "value": "Q8",
              "text": "Q8"
            }
          ]
        },
        {
          "type": "matrixdropdown",
          "name": "question9",
          "title": "What is the estimated effort for remediating this application to replace unsupported technology?",
          "isRequired": true,
          "columns": [
            {
              "name": "Answer",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                {
                  "value": "Very Low",
                  "text": "Very Low"
                },
                {
                  "value": "Low",
                  "text": "Low"
                },
                {
                  "value": "Medium",
                  "text": "Medium"
                },
                {
                  "value": "High",
                  "text": "High"
                },
                {
                  "value": "Very High",
                  "text": "Very High"
                }
              ]
            },
            {
              "name": "Tech Focus",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                "TI: Cost",
                "TI: Complexity",
                "TI: Risk",
                "BV: Fit",
                "BV: Value"
              ]
            }
          ],
          "choices": [
            "TI: Cost",
            "TI: Complexity",
            "TI: Risk",
            "BV: Fit",
            "BV: Value"
          ],
          "cellType": "text",
          "rows": [
            {
              "value": "Q9",
              "text": "Q9"
            }
          ]
        },
        {
          "type": "matrixdropdown",
          "name": "question10",
          "title": "Is Funding Available to Modernize/Transform (Re-Architect, Refactor or Re-Platform)? Or Repurchase?",
          "isRequired": true,
          "columns": [
            {
              "name": "Answer",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                {
                  "value": "Yes",
                  "text": "Yes"
                },
                {
                  "value": "No",
                  "text": "No"
                },
                {
                  "value": "Not Sure",
                  "text": "Not Sure"
                },
                {
                  "value": "Not Applicable",
                  "text": "Not Applicable"
                }
              ]
            },
            {
              "name": "Tech Focus",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                "TI: Cost",
                "TI: Complexity",
                "TI: Risk",
                "BV: Fit",
                "BV: Value"
              ]
            }
          ],
          "choices": [
            "TI: Cost",
            "TI: Complexity",
            "TI: Risk",
            "BV: Fit",
            "BV: Value"
          ],
          "cellType": "text",
          "rows": [
            {
              "value": "Q10",
              "text": "Q10"
            }
          ]
        },
        {
          "type": "matrixdropdown",
          "name": "question11",
          "title": "Is Funding Available to Remediate & Retain (and removing all unsupported technology)?",
          "isRequired": true,
          "columns": [
            {
              "name": "Answer",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                {
                  "value": "Yes",
                  "text": "Yes"
                },
                {
                  "value": "No",
                  "text": "No"
                },
                {
                  "value": "Not Sure",
                  "text": "Not Sure"
                },
                {
                  "value": "Not Applicable",
                  "text": "Not Applicable"
                }
              ]
            },
            {
              "name": "Tech Focus",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                "TI: Cost",
                "TI: Complexity",
                "TI: Risk",
                "BV: Fit",
                "BV: Value"
              ]
            }
          ],
          "choices": [
            "TI: Cost",
            "TI: Complexity",
            "TI: Risk",
            "BV: Fit",
            "BV: Value"
          ],
          "cellType": "text",
          "rows": [
            {
              "value": "Q11",
              "text": "Q11"
            }
          ]
        },
        {
          "type": "matrixdropdown",
          "name": "question12",
          "title": "Is there a Plan to Retire the Application?",
          "isRequired": true,
          "columns": [
            {
              "name": "Answer",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                {
                  "value": "Yes",
                  "text": "Yes"
                },
                {
                  "value": "No",
                  "text": "No"
                }
              ]
            },
            {
              "name": "Tech Focus",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                "TI: Cost",
                "TI: Complexity",
                "TI: Risk",
                "BV: Fit",
                "BV: Value"
              ]
            }
          ],
          "choices": [
            "TI: Cost",
            "TI: Complexity",
            "TI: Risk",
            "BV: Fit",
            "BV: Value"
          ],
          "cellType": "text",
          "rows": [
            {
              "value": "Q12",
              "text": "Q12"
            }
          ]
        },
        {
          "type": "matrixdropdown",
          "name": "question13",
          "title": "Are skills and knowledge to support, develop and maintain the application available?",
          "isRequired": true,
          "columns": [
            {
              "name": "Answer",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                {
                  "value": "Yes",
                  "text": "Yes"
                },
                {
                  "value": "No",
                  "text": "No"
                }
              ]
            },
            {
              "name": "Tech Focus",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                "TI: Cost",
                "TI: Complexity",
                "TI: Risk",
                "BV: Fit",
                "BV: Value"
              ]
            }
          ],
          "choices": [
            "TI: Cost",
            "TI: Complexity",
            "TI: Risk",
            "BV: Fit",
            "BV: Value"
          ],
          "cellType": "text",
          "rows": [
            {
              "value": "Q13",
              "text": "Q13"
            }
          ]
        },
        {
          "type": "matrixdropdown",
          "name": "question14",
          "title": "What is the SLA/RTO for this application?",
          "isRequired": true,
          "columns": [
            {
              "name": "Answer",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                {
                  "value": "Very Low",
                  "text": "Very Low"
                },
                {
                  "value": "Low",
                  "text": "Low"
                },
                {
                  "value": "Medium",
                  "text": "Medium"
                },
                {
                  "value": "High",
                  "text": "High"
                },
                {
                  "value": "Very High",
                  "text": "Very High"
                }
              ]
            },
            {
              "name": "Tech Focus",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                "TI: Cost",
                "TI: Complexity",
                "TI: Risk",
                "BV: Fit",
                "BV: Value"
              ]
            }
          ],
          "choices": [
            "TI: Cost",
            "TI: Complexity",
            "TI: Risk",
            "BV: Fit",
            "BV: Value"
          ],
          "cellType": "text",
          "rows": [
            {
              "value": "Q14",
              "text": "Q14"
            }
          ]
        },
        {
          "type": "matrixdropdown",
          "name": "question15",
          "title": "What is the total cost of ownership (TCO) of the application? (Add Support, Maintenance and Licensing together and translate as a % of total IT budget for the dept/unit/business)",
          "isRequired": true,
          "columns": [
            {
              "name": "Answer",
              "cellType": "text",
              "isRequired": true,
              "inputType": "number",
              "min": "0"
            },
            {
              "name": "Tech Focus",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                "TI: Cost",
                "TI: Complexity",
                "TI: Risk",
                "BV: Fit",
                "BV: Value"
              ]
            }
          ],
          "choices": [
            "TI: Cost",
            "TI: Complexity",
            "TI: Risk",
            "BV: Fit",
            "BV: Value"
          ],
          "cellType": "text",
          "rows": [
            {
              "value": "Q15",
              "text": "Q15"
            }
          ]
        },
        {
          "type": "matrixdropdown",
          "name": "question16",
          "title": "Is the application a COTS or internally developed (bespoke) application?",
          "isRequired": true,
          "columns": [
            {
              "name": "Answer",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                {
                  "value": "COTS",
                  "text": "COTS"
                },
                {
                  "value": "Bespoke",
                  "text": "Bespoke"
                }
              ]
            },
            {
              "name": "Tech Focus",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                "TI: Cost",
                "TI: Complexity",
                "TI: Risk",
                "BV: Fit",
                "BV: Value"
              ]
            }
          ],
          "choices": [
            "TI: Cost",
            "TI: Complexity",
            "TI: Risk",
            "BV: Fit",
            "BV: Value"
          ],
          "cellType": "text",
          "rows": [
            {
              "value": "Q16",
              "text": "Q16"
            }
          ]
        },
        {
          "type": "matrixdropdown",
          "name": "question17",
          "title": "If the application is provided by a third party (COTS,SaaS, etc.), does your company control the source code and is the app older than 10 years?",
          "isRequired": true,
          "columns": [
            {
              "name": "Answer",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                {
                  "value": "Yes",
                  "text": "Yes"
                },
                {
                  "value": "No",
                  "text": "No"
                }
              ]
            },
            {
              "name": "Tech Focus",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                "TI: Cost",
                "TI: Complexity",
                "TI: Risk",
                "BV: Fit",
                "BV: Value"
              ]
            }
          ],
          "choices": [
            "TI: Cost",
            "TI: Complexity",
            "TI: Risk",
            "BV: Fit",
            "BV: Value"
          ],
          "cellType": "text",
          "rows": [
            {
              "value": "Q17",
              "text": "Q17"
            }
          ]
        },
        {
          "type": "matrixdropdown",
          "name": "question18",
          "title": "Is there an agreed strategy for the evolution of each application? Has the client made a decision to move forward with a modernization approach already?",
          "isRequired": true,
          "columns": [
            {
              "name": "Answer",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                {
                  "value": "No",
                  "text": "No"
                },
                {
                  "value": "Replatform",
                  "text": "Replatform"
                },
                {
                  "value": "Refactor",
                  "text": "Refactor"
                },
                {
                  "value": "Rearchitect",
                  "text": "Rearchitect"
                },
                {
                  "value": "Rebuild",
                  "text": "Rebuild"
                }
              ]
            },
            {
              "name": "Tech Focus",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                "TI: Cost",
                "TI: Complexity",
                "TI: Risk",
                "BV: Fit",
                "BV: Value"
              ]
            }
          ],
          "choices": [
            "TI: Cost",
            "TI: Complexity",
            "TI: Risk",
            "BV: Fit",
            "BV: Value"
          ],
          "cellType": "text",
          "rows": [
            {
              "value": "Q18",
              "text": "Q18"
            }
          ]
        },
        {
          "type": "matrixdropdown",
          "name": "question19",
          "title": "Does the application support hosting in the cloud?",
          "isRequired": true,
          "columns": [
            {
              "name": "Answer",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                {
                  "value": "Yes",
                  "text": "Yes"
                },
                {
                  "value": "No",
                  "text": "No"
                }
              ]
            },
            {
              "name": "Tech Focus",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                "TI: Cost",
                "TI: Complexity",
                "TI: Risk",
                "BV: Fit",
                "BV: Value"
              ]
            }
          ],
          "choices": [
            "TI: Cost",
            "TI: Complexity",
            "TI: Risk",
            "BV: Fit",
            "BV: Value"
          ],
          "cellType": "text",
          "rows": [
            {
              "value": "Q19",
              "text": "Q19"
            }
          ]
        },
        {
          "type": "matrixdropdown",
          "name": "question20",
          "title": "What is the user population of the department/unit/business/organisation where the application is used?",
          "isRequired": true,
          "columns": [
            {
              "name": "Answer",
              "cellType": "text",
              "isRequired": true,
              "inputType": "number",
              "min": "0"
            },
            {
              "name": "Tech Focus",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                "TI: Cost",
                "TI: Complexity",
                "TI: Risk",
                "BV: Fit",
                "BV: Value"
              ]
            }
          ],
          "choices": [
            "TI: Cost",
            "TI: Complexity",
            "TI: Risk",
            "BV: Fit",
            "BV: Value"
          ],
          "cellType": "text",
          "rows": [
            {
              "value": "Q20",
              "text": "Q20"
            }
          ]
        },
        {
          "type": "matrixdropdown",
          "name": "question21",
          "title": "What is the IT Budget of the depertment/unit/business/organisation where the application is used?",
          "isRequired": true,
          "columns": [
            {
              "name": "Answer",
              "cellType": "text",
              "isRequired": true,
              "inputType": "number",
              "min": "0"
            },
            {
              "name": "Tech Focus",
              "cellType": "dropdown",
              "isRequired": true,
              "choices": [
                "TI: Cost",
                "TI: Complexity",
                "TI: Risk",
                "BV: Fit",
                "BV: Value"
              ]
            }
          ],
          "choices": [
            "TI: Cost",
            "TI: Complexity",
            "TI: Risk",
            "BV: Fit",
            "BV: Value"
          ],
          "cellType": "text",
          "rows": [
            {
              "value": "Q21",
              "text": "Q21"
            }
          ]
        }
      ]
    }
  ],
  "showPageNumbers": true,
  "showQuestionNumbers": "off"
};

export const sendDataToServer = async (e, serverId) => {
  axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/assessments/add-completed-server-assessment`, {
    id: serverId,
    completedAssessment: e.data
  })
    .then(() => {
      setToast(toast.success, "Assessment submitted");
    })
    .catch(() => {
      setToast(toast.error, "Unable to submit assessment");
    });
};

const ServerAssessment = () => {
  const [showAssessment, setShowAssessment] = React.useState(false);
  const [servers, setServers] = React.useState([]);
  const [serverId, setServerId] = React.useState("");
  const [discoveries, setDiscoveries] = React.useState([]);
  const [discovered, setDiscovered] = React.useState(undefined);

  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/virtual-servers/get-discoveries`)
      .then((response) => {
        setDiscoveries(response.data);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve server discoveries");
      });
  }, []);

  React.useEffect(() => {
    if (discoveries.length > 0) {
      setDiscovered(discoveries[discoveries.length - 1]);
    }
  }, [discoveries]);

  React.useEffect(() => {
    if (!discovered) {
      return;
    }

    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/virtual-servers/get/${discovered}`)
      .then((response) => {
        setServers(response.data.servers);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve servers");
      });
  }, [discovered]);

  return (
    <Fragment>
      <Breadcrumb title="Server Assessment" parent="Assessments" />
      {showAssessment ?
        <Fragment>
          <Survey.Survey json={surveyJSON} onComplete={(e) => {
            /* istanbul ignore next */
            sendDataToServer(e, serverId);
          }}
          />
        </Fragment>
        :
        <div className="card">
          <div className="card-header">
            <h5 id="title">{"SELECT A SERVER"}</h5>
          </div>
          <div className="card-body datatable-react">
            <form>
              <select id="servers" className="form-control btn-square" onChange={(e) => setServerId(e.target.value)}>
                {servers.map((device, index) => (
                  <option key={index} value={device.id}>{device.name}</option>
                ))}
              </select>
              <div className="card-footer text-right">
                <button id="next" className="btn btn-primary" type="submit" onClick={() => setShowAssessment(!showAssessment)}>Next</button>
              </div>
            </form>
          </div>
        </div>}
    </Fragment>
  );
};

export default ServerAssessment;