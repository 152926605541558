import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import StepZilla from "react-stepzilla";
import ConfigureProject from "./ConfigureProject";
import ConfigureRegion from "./ConfigureRegion";
import CreateUser from "./CreateUser";
import Review from "./Review";
import { FormWizardWithIcon } from "../../../constant";
import { useParams } from "react-router";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const OrderPackagedDeploymnetBuild = () => {  
  const { id } = useParams();
  const [ projectId, setProjectId ] = useState("");
  const [ clusterName, setClusterName ] = useState("");
  const [ adminEmail, setAdminEmail ] = useState("");
  const [ domainName, setDomainName ] = useState("fj-packaged-deployment.net.");
  const [ domainZone, setDomainZone ] = useState("fj-packaged-deployment-zone");
  const [ region, setRegion ] = useState("");
  const [ clusterZones, setClusterZones ] = useState("");
  const [ vaultUser, setVaultUser ] = useState("admin");
  const [ vaultPassword, setVaultPassword ] = useState("");
  const [ orderName, setOrderName ] = useState("");
  const [ action, setAction ] = useState("");
  const [statusId, setStatusId] = useState(uuidv4());
  const [status, setStatus] = useState("Waiting");

  useEffect(() => {
    if (id) {
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/orders/get-packaged-deployment-order/${id}`)
        .then((res) => {
          setProjectId(res.data.order.projectId);
          setClusterName(res.data.order.clusterName);
          setAdminEmail(res.data.order.adminEmail);
          setDomainName(res.data.order.domainName);
          setDomainZone(res.data.order.domainZone);
          setRegion(res.data.order.region);
          setClusterZones(res.data.order.clusterZones);
          setVaultUser(res.data.order.vaultUser);
          setVaultPassword(res.data.order.password);
          setOrderName(res.data.name);
          setAction(res.data.action);
          setStatusId(res.data.order.statusId);
        })
        .catch((err) => {
          console.log(err);
          window.location = "/infrastructure/google/packaged-deployment";
        });
    }
  }, [id]);

  const steps =
    [
      {
        name: "Configure Project",
        component: <ConfigureProject
          projectId={projectId}
          setProjectId={setProjectId}
          clusterName={clusterName}
          setClusterName={setClusterName}
          adminEmail={adminEmail}
          setAdminEmail={setAdminEmail}
          domainName={domainName}
          setDomainName={setDomainName}
          domainZone={domainZone}
          setDomainZone={setDomainZone}
        />
      }, 
      {
        name: "Configure Region",
        component: <ConfigureRegion
          region={region}
          setRegion={setRegion}
          clusterZones={clusterZones}
          setClusterZones={setClusterZones}
        />
      },
      {
        name: "Create User",
        component: <CreateUser
          vaultUser={vaultUser}
          setVaultUser={setVaultUser}
          vaultPassword={vaultPassword}
          setVaultPassword={setVaultPassword}
        />
      },
      {
        name: "Review",
        component: <Review
          projectId={projectId}
          setProjectId={setProjectId}
          clusterName={clusterName}
          setClusterName={setClusterName}
          adminEmail={adminEmail}
          setAdminEmail={setAdminEmail}
          domainName={domainName}
          setDomainName={setDomainName}
          domainZone={domainZone}
          setDomainZone={setDomainZone}
          region={region}
          setRegion={setRegion}
          clusterZones={clusterZones}
          setClusterZones={setClusterZones}
          vaultUser={vaultUser}
          setVaultUser={setVaultUser}
          vaultPassword={vaultPassword}
          setVaultPassword={setVaultPassword}
          orderName={orderName}
          setOrderName={setOrderName}
          action={action}
          setAction={setAction}
          statusId={statusId}
          setStatusId={setStatusId}
          status={status}
          setStatus={setStatus}
          id={id}
        />
      }
    ];

  return (
    <Fragment>
      <Breadcrumb title="Order" parent="Packaged Deployment Build" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{FormWizardWithIcon}</h5>
              </div>
              <div className="card-body">
                <StepZilla
                  steps={steps}
                  showSteps={true}
                  showNavigation={true}
                  stepsNavigation={true}
                  prevBtnOnLastStep={true}
                  dontValidate={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OrderPackagedDeploymnetBuild;