import React from "react";
import { Col } from "reactstrap";
import ReChartLineGraph from "../../components/sustainability/ReChartCarbonGraph";
import axios from "axios";
import { setToast } from "../../components/common/Toast";
import { toast } from "react-toastify";

const CarbonGraph = () => {
  const [carbonData, setCarbonData] = React.useState(null);

  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/sustainability/get-carbon-month-data`)
      .then((res) => {
        setCarbonData(res.data);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve carbon data");
      });
  }, []);

  return (
    <Col style={{ height: "450px", marginBottom: "40px" }} sm="12">
      <h5>Cloud Usage</h5>
      {carbonData ?
        <ReChartLineGraph data={carbonData} />
        :
        <h2 id="loading-message">Loading</h2>
      }
    </Col>
  );
};

export default CarbonGraph;