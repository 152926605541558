import React, { Fragment, useEffect, useReducer, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import axios from "axios";
import "react-dropzone-uploader/dist/styles.css";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { MENUITEMS } from "../../components/common/sidebar-component/menu";

const RolesEdit = () => {
  const [role, setRole] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      roleName: "",
      roleId: "",
      users: [],
      sections: [],
      applications: []
    }
  );
  const [reload, setReload] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [allApplications, setAllApplications] = useState([]);

  const { id } = useParams();

  const getAllUsers = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/users`)
      .then((response) => {
        setAllUsers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllApplications = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/applications`)
      .then((response) => {
        setAllApplications(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRole = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/roles/${id}`)
      .then((response) => {
        setRole(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllUsers();
    getAllApplications();

    if (id) {
      getRole();
    }
  }, []);

  useEffect(() => {
    if (reload) {
      setTimeout(() => window.location = "/admin/roles", 600);
    }
  }, [reload]);

  const handleOnSectionsChange = (e) => {
    const { name, checked } = e.target;
    setRole({
      ...role,
      ...{
        sections: checked ?
          [
            ...role.sections,
            name
          ]
          :
          role.sections.filter((section) => section !== name)
      }
    });
  };

  const handleOnUsersChange = (e, user) => {
    const { checked } = e.target;
    setRole({
      ...role,
      ...{
        users: checked ?
          [
            ...role.users,
            user
          ]
          :
          role.users.filter((u) => u.id !== user.id)
      }
    });
  };

  const handleOnApplicationsChange = (e, application) => {
    const { checked } = e.target;
    setRole({
      ...role,
      ...{
        applications: checked ?
          [
            ...role.applications,
            application
          ]
          :
          role.applications.filter((a) => a.id !== application.id)
      }
    });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (id) {
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/admin/roles/${id}`, {
        roleName: role.roleName,
        roleId: role.roleId,
        sections: role.sections,
        users: role.users,
        applications: role.applications
      });
    } else {
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/admin/roles`, {
        roleName: role.roleName,
        sections: role.sections,
        users: role.users,
        applications: role.applications
      });
    }
    setReload(true);
  };

  return (
    <Fragment>
      <Breadcrumb title={id ? "Edit" : "Create"} parent="Roles" />
      <ToastContainer />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{id ? "Edit" : "Create"} Role</h5>
              </div>
              <div className="card-body datatable-react">
                <form className="card">
                  <div className="card-header">
                    <h4 className="card-title mb-0">{`Role Information: ${role.roleName}`}</h4>
                    <div className="card-options">
                      <a className="card-options-collapse" href="javascript" data-toggle="card-collapse">
                        <i className="fe fe-chevron-up"></i>
                      </a>
                      <a className="card-options-remove" href="javascript" data-toggle="card-remove">
                        <i className="fe fe-x"></i>
                      </a>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label">Name</label>
                          {id ?
                            <input className="form-control" type="text" placeholder="Name" value={role.roleName} disabled />
                            :
                            <input className="form-control" type="text" placeholder="Name" value={role.roleName} onChange={(e) => setRole({ ...role, ...{ roleName: e.target.value } })} />
                          }
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 col-md-4">
                        <div className="form-group">
                          <label className="form-label">Sections</label>
                          {Object.keys(MENUITEMS).map((section, index) => {
                            return (
                              <div key={index} className="checkbox p-0">
                                <input
                                  id={`sectionCheckbox${index}`}
                                  type="checkbox"
                                  checked={role.sections.includes(MENUITEMS[section].section)}
                                  onChange={(e) => handleOnSectionsChange(e)}
                                  name={MENUITEMS[section].section}
                                  disabled={role.roleName === "Administrators" && MENUITEMS[section].section === "Admin"}
                                />
                                <label htmlFor={`sectionCheckbox${index}`}>{MENUITEMS[section].section}</label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4">
                        <div className="form-group">
                          <label className="form-label">Users</label>
                          {allUsers.map((user, index) => {
                            return (
                              <div key={index} className="checkbox p-0">
                                <input
                                  id={`userCheckbox${index}`}
                                  type="checkbox"
                                  checked={role.users.some((item) => item.id === user.id)}
                                  onChange={(e) => handleOnUsersChange(e, user)}
                                  name={user}
                                  disabled={role.roleName === "Everyone"}
                                />
                                <label htmlFor={`userCheckbox${index}`}>{user.name}</label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4">
                        <div className="form-group">
                          <label className="form-label">Applications</label>
                          {allApplications.map((application, index) => {
                            return (
                              <div key={index} className="checkbox p-0">
                                <input
                                  id={`applicationCheckbox${index}`}
                                  type="checkbox"
                                  checked={role.applications.some((item) => item.id === application.id)}
                                  onChange={(e) => handleOnApplicationsChange(e, application)}
                                  name={application}
                                  disabled={role.roleName === "Administrators"}
                                />
                                <label htmlFor={`applicationCheckbox${index}`}>{application.label}</label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <Link to={"/admin/roles"}><button className="btn btn-primary text-left" type="submit">Back</button></Link>
                    <button className="btn btn-primary float-right" onClick={handleOnSubmit} type="submit">{id ? "Update" : "Create"}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default RolesEdit;




