import React, { Fragment } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import "react-dropzone-uploader/dist/styles.css";
import { toast } from "react-toastify";
import axios from "axios";
import DataTable from "react-data-table-component";
import FilterComponent from "../../components/common/FilterComponent";
import { Media } from "reactstrap";
import { setToast } from "../../components/common/Toast";
import LinkModal from "./LinkModal";
import { tableStyles } from "../../components/common/CustomStyles";

const Links = () => {
  const [links, setLinks] = React.useState([]);
  const [link, setLink] = React.useState({
    name: "",
    image: "",
    link: "",
    page: ""
  });
  const [openUpload, setOpenUpload] = React.useState(false);
  const [existing, setExisting] = React.useState(false);
  const [filteredItems, setFilteredItems] = React.useState([]);
  const [pending, setPending] = React.useState(true);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

  const getLinks = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/links`)
      .then((res) => {
        setLinks(res.data);
        setPending(false);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve links");
      });
  };

  React.useEffect(() => {
    getLinks();
  }, []);

  const handleUploadOpen = (data) => {
    setLink(data);
    setExisting(true);
    setOpenUpload(true);
  };

  const handleDelete = (data) => {
    axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/admin/links/${data._id}`)
      .then(() => {
        setToast(toast.success, "Link deleted");
        getLinks();
      })
      .catch(() => {
        setToast(toast.error, "Unable to delete link");
      });
  };

  /* istanbul ignore next */
  const columns = [
    {
      name: "Name",
      selector: (link) => link.name,
      center: true,
      sortable: true,
      wrap: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Link",
      selector: (link) => link.link,
      center: true,
      sortable: true,
      width: "13rem",
      wrap: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) => <div>
        <button className="btn btn-primary" style={{ margin: "5px 0px 5px 0px", width: "175px" }} onClick={() => window.open(data.link)}>Open Link</button>
      </div>
    },
    {
      name: "Page",
      selector: (link) => link.page,
      center: true,
      sortable: true,
      wrap: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Image",
      selector: (link) => link.image,
      center: true,
      sortable: true,
      wrap: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) => <div>
        <Media
          src={data.image}
          alt="Gallery"
          className="img-thumbnail"
          style={{ margin: "10px" }}
        />
      </div>
    },
    {
      name: "Actions",
      center: true,
      width: "13rem",
      sortable: true,
      wrap: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) => <div>
        <button id={`edit-${data._id}`} className="btn btn-primary" style={{ margin: "5px 0px 5px 0px", width: "175px" }} onClick={() => handleUploadOpen(data)}>Edit</button>
        <button id={`delete-${data._id}`} className="btn btn-primary" style={{ margin: "5px 0px 5px 0px", width: "175px" }} onClick={() => handleDelete(data)}>Delete</button>
      </div>
    }
  ];

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
        <div>
          <button id="add-link" key="add-link" style={{ marginLeft: "5px", marginRight: "5px" }} className="btn btn-primary" onClick={() => setOpenUpload(true)} type="button">Add Link</button>
        </div>
        <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholder={"Name"} />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  React.useEffect(() => {
    setFilteredItems(links.filter(
      (item) => item.name && (item.name.toLowerCase().includes(filterText.toLowerCase()))));
  }, [filterText, links]);

  return (
    <Fragment>
      <Breadcrumb title="Links" parent="Admin" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{"Table of Links"}</h5>
              </div>
              <div className="card-body datatable-react">
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  customStyles={tableStyles}
                  progressPending={pending}
                  striped={true}
                  center={true}
                  subHeaderComponent={subHeaderComponentMemo}
                  subHeader
                  persistTableHead
                  pagination
                  responsive
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <LinkModal
        link={link}
        setLink={setLink}
        openUpload={openUpload}
        existing={existing}
        setExisting={setExisting}
        getLinks={getLinks}
        setOpenUpload={setOpenUpload}
      />
    </Fragment >
  );
};
export default Links;




