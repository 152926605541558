import React, { Fragment } from "react";
import man from "../../../assets/images/dashboard/user.png";
import { User, Settings, LogOut } from "react-feather";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { EditProfile } from "../../../constant";
import { useOktaAuth } from "@okta/okta-react";

const UserMenu = () => {
  const { oktaAuth } = useOktaAuth();

  const logout = async () => {
    await oktaAuth.signOut();
  };

  return (
    <Fragment>
      <li className="onhover-dropdown">
        <div className="media align-items-center">
          <img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" src={man} alt="header-user" />
          <div className="dotted-animation">
            <span className="animate-circle"></span>
            <span className="main-circle"></span>
          </div>
        </div>
        <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
          <li><Link to={`${process.env.PUBLIC_URL}/users/userEdit`}><User />{EditProfile}</Link></li>
          <li><a href="#javascript"><Settings />{"Settings"}</a></li>
          <li onClick={logout}><a href="#javascript"><LogOut /> {"Log out"}</a></li>
        </ul>
      </li>
    </Fragment>
  );
};


export default withRouter(UserMenu);