import React, { Fragment } from "react";
import "survey-react/survey.css";
import Breadcrumb from "../../components/common/breadcrumb";
import axios from "axios";
import { toast } from "react-toastify";
import DatabaseAssessmentResults from "./DatabaseAssessmentResults";
import { setToast } from "../../components/common/Toast";
import FdmButton from "../database-factory/fdm/FdmButton";

const DatabaseAssessment = () => {
  const [databases, setDatabases] = React.useState([]);
  const [databaseIndex, setDatabaseIndex] = React.useState(undefined);
  const [chosenSchemas, setChosenSchemas] = React.useState([]);
  const [validSource, setValidSource] = React.useState(false);
  const [showTestSourceConnection, setShowTestSourceConnection] = React.useState(false);
  const [schemas, setSchemas] = React.useState(false);
  const [validSourceConnection, setValidSourceConnection] = React.useState(false);
  const [migrationProjects, setMigrationProjects] = React.useState([]);
  const [migrationProjectIndex, setMigrationProjectIndex] = React.useState(undefined);

  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/fdm/migration-projects`)
      .then((res) => {
        setMigrationProjects(res.data);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve migration projects");
      });

    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/databases/get-non-postgres-ips`)
      .then((res) => {
        setDatabases(res.data);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve databases");
      });
  }, []);

  const handleChangeDatabase = (e) => {
    setDatabaseIndex(e.target.value);
    setValidSourceConnection(false);

    if (databaseIndex !== undefined) {
      setShowTestSourceConnection(true);
      setValidSource(true);
    } else {
      setShowTestSourceConnection(false);
      setValidSource(false);
    }
  };

  const testSourceConnectionAPI = () => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/fdm/test-source-connection`, {
      source: {
        ...databases[databaseIndex],
        id: databases[databaseIndex].name,
        host: databases[databaseIndex].ip,
        type: databases[databaseIndex].vendor.toUpperCase()
      }
    })
      .then(() => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/fdm/get-schemas`, {
          source: {
            ...databases[databaseIndex],
            id: databases[databaseIndex].name,
            host: databases[databaseIndex].ip,
            type: databases[databaseIndex].vendor.toUpperCase()
          }
        })
          .then((response) => {
            setSchemas(response.data);
            setShowTestSourceConnection(false);
            setValidSourceConnection(true);
          })
          .catch(() => {
            setToast(toast.error, "Unable to retrieve schemas");
            setShowTestSourceConnection(true);
            setValidSourceConnection(false);
          });
      })
      .catch(() => {
        setToast(toast.error, "Unable to connect to database");
        setShowTestSourceConnection(true);
        setValidSourceConnection(false);
      });
  };

  const runAssessment = () => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/assessments/database`, {
      database: databases[databaseIndex],
      schemas: chosenSchemas,
      projectId: migrationProjects[migrationProjectIndex].migrationProjectId.toString()
    })
      .then((res) => {
        setToast(toast.success, "Assessment triggered. Result will appear here when it is ready");
        window.open(res.data, "_blank");
      })
      .catch(() => {
        setToast(toast.error, "Unable to trigger assessment");
      });
  };

  const schemaChange = (e) => {
    if (chosenSchemas.includes(e.target.value)) {
      setChosenSchemas(chosenSchemas.filter((schema) => schema !== e.target.value));
    } else {
      setChosenSchemas([...new Set([...chosenSchemas, ...[e.target.value]])]);
    }
  };

  return (
    <Fragment>
      <Breadcrumb title="Database Assessment" parent="Assessment" />
      <div className="card">
        <div className="card-header">
          <h5>{"RUN A DATABASE ASSESSMENT"}</h5>
        </div>
        <div className="card-body datatable-react">
          <div>
            <p className="form-text">Migration Project</p>
            <select id="migration-projects" className="form-control btn-square" onChange={(e) => setMigrationProjectIndex(e.target.value)}>
              <option value={undefined} disabled={migrationProjectIndex !== undefined}>Select</option>
              {migrationProjects.map((migrationProject, index) => (
                <option key={index} value={index}>{migrationProject.migrationProjectName}</option>
              ))}
            </select>
          </div>

          {migrationProjectIndex &&
            <div>
              <p className="form-text">Database</p>
              <select id="databases" className="form-control btn-square" onChange={handleChangeDatabase}>
                <option value={undefined} disabled={databaseIndex !== undefined}>Select</option>
                {databases.map((database, index) => (
                  <option key={index} value={index}>{database.name}: {database.ip}</option>
                ))}
              </select>
            </div>
          }

          {showTestSourceConnection ?
            <button id="test-source-connection" className="btn btn-pill btn-primary btn-air-primary btn-modal" onClick={testSourceConnectionAPI} type="button">Test Connection</button>
            : validSource &&
            <div>
              <p className="connection-successful">Connection Successful</p>
            </div>
          }

          {schemas &&
            <div>
              <p className="form-text">Schemas</p>
              <select id="schemas" className="form-control btn-square" style={{ height: "10rem" }} multiple value={chosenSchemas} onChange={schemaChange}>
                {schemas.map((schema) => {
                  return (
                    <option key={schema.schemaName} value={schema.schemaName}>{schema.schemaName}</option>
                  );
                })}
              </select>
            </div>
          }

          <div className="card-footer text-right">
            <button id="run-assessment" className="btn btn-primary" disabled={!(migrationProjectIndex && validSourceConnection && chosenSchemas.length > 0)} onClick={runAssessment}>Run Assessment</button>
          </div>

        </div>
        {migrationProjectIndex &&
          <div>
            <div className="card-header">
              <h5>{"PREVIOUS ASSESSMENTS"}</h5>
            </div>
            <div className="card-body datatable-react">
              <DatabaseAssessmentResults
                migrationProjects={migrationProjects}
                migrationProjectIndex={migrationProjectIndex}
              />
            </div>
          </div>
        }
      </div>
      <FdmButton />
    </Fragment>
  );
};

export default DatabaseAssessment;