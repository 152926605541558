import React, { Fragment } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import axios from "axios";
import "react-dropzone-uploader/dist/styles.css";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { setToast } from "../../../components/common/Toast";
import Select from "react-select";

const ApplicationComponentEdit = () => {
  const [applicationComponent, setApplicationComponent] = React.useState({
    component: "",
    description: "",
    databases: [],
    physicalservers: [],
    virtualservers: [],
    kubernetes: []
  });
  const [databases, setDatabases] = React.useState(undefined);
  const [physicalServers, setPhysicalServers] = React.useState(undefined);
  const [virtualServers, setVirtualServers] = React.useState(undefined);
  const [kubernetes, setKubernetes] = React.useState(undefined);
  const { id } = useParams();

  React.useEffect(() => {
    axios.get(id ? `${process.env.REACT_APP_BACKEND_URL}/api/application-components/databases/${id}` : `${process.env.REACT_APP_BACKEND_URL}/api/application-components/databases`)
      .then((response) => {
        setDatabases(response.data.map((item) => {
          return {
            value: item._id,
            label: item.name
          };
        }));
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve databases");
        setDatabases(null);
      });

    axios.get(id ? `${process.env.REACT_APP_BACKEND_URL}/api/application-components/physical-servers/${id}` : `${process.env.REACT_APP_BACKEND_URL}/api/application-components/physical-servers`)
      .then((response) => {
        setPhysicalServers(response.data.map((item) => {
          return {
            value: item._id,
            label: item.name
          };
        }));
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve physical servers");
      });

    axios.get(id ? `${process.env.REACT_APP_BACKEND_URL}/api/application-components/virtual-servers/${id}` : `${process.env.REACT_APP_BACKEND_URL}/api/application-components/virtual-servers`)
      .then((response) => {
        setVirtualServers(response.data.map((item) => {
          return {
            value: item._id,
            label: item.name
          };
        }));
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve virtual servers");
      });

    axios.get(id ? `${process.env.REACT_APP_BACKEND_URL}/api/application-components/kubernetes/${id}` : `${process.env.REACT_APP_BACKEND_URL}/api/application-components/kubernetes`)
      .then((response) => {
        setKubernetes(response.data.map((item) => {
          return {
            value: item._id,
            label: item.name
          };
        }));
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve kubernetes");
      });
  }, []);

  React.useEffect(() => {
    if (id && databases && physicalServers && virtualServers && kubernetes) {
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/application-components/${id}`)
        .then((response) => {
          setApplicationComponent({
            ...response.data,
            databases: response.data.databases.map(((item) => {
              const database = databases.find((db) => db.value === item);

              if (database) {
                return database;
              }
            })),
            physicalservers: response.data.physicalservers.map(((item) => {
              const physicalServer = physicalServers.find((ps) => ps.value === item);

              if (physicalServer) {
                return physicalServer;
              }
            })),
            virtualservers: response.data.virtualservers.map(((item) => {
              const virtualServer = virtualServers.find((vs) => vs.value === item);

              if (virtualServer) {
                return virtualServer;
              }
            })),
            kubernetes: response.data.kubernetes.map(((item) => {
              const kb = kubernetes.find((vs) => vs.value === item);

              if (kb) {
                return kb;
              }
            }))
          });
        })
        .catch((e) => {
          console.log(e);
          setToast(toast.error, "Unable to retrieve application component");
        });
    }
  }, [id, databases, physicalServers, virtualServers, kubernetes]);

  const handleOnChange = (value, name) => {
    setApplicationComponent({ ...applicationComponent, [name]: value });
  };

  const handleOnSubmit = () => {
    console.log(applicationComponent);
    axios.post(id ? `${process.env.REACT_APP_BACKEND_URL}/api/application-components/${id}` : `${process.env.REACT_APP_BACKEND_URL}/api/application-components`, {
      component: applicationComponent.component,
      description: applicationComponent.description,
      databases: applicationComponent.databases.filter((item) => item !== undefined).map((item) => item.value),
      physicalservers: applicationComponent.physicalservers.filter((item) => item !== undefined).map((item) => item.value),
      virtualservers: applicationComponent.virtualservers.filter((item) => item !== undefined).map((item) => item.value),
      kubernetes: applicationComponent.kubernetes.filter((item) => item !== undefined).map((item) => item.value)
    })
      .then(() => {
        window.location.assign("/discovery/application-components");
      })
      .catch(() => {
        setToast(toast.error, `Unable to ${id ? "update" : "create"} application component`);
      });
  };

  return (
    <Fragment>
      <Breadcrumb title={id ? "Edit" : "Create"} parent="Application Components" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{id ? "Edit" : "Create"} Application Component Information</h5>
              </div>
              <div className="card-body datatable-react">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title mb-0">{`Migration Group: ${applicationComponent.component}`}</h4>
                    <div className="card-options">
                      <a className="card-options-collapse" href="javascript" data-toggle="card-collapse">
                        <i className="fe fe-chevron-up"></i>
                      </a>
                      <a className="card-options-remove" href="javascript" data-toggle="card-remove">
                        <i className="fe fe-x"></i>
                      </a>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Application Component</label>
                          <input id="component" className="form-control" type="text" name="component" placeholder="Application Component" value={applicationComponent.component} onChange={(e) => handleOnChange(e.target.value, "component")} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Description</label>
                          <input id="description" className="form-control" type="text" name="description" placeholder="Description" value={applicationComponent.description} onChange={(e) => handleOnChange(e.target.value, "description")} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label" style={{ marginBottom: "12px" }}>Databases</label>
                          <Select id="databases" name="databases" isMulti options={databases} isLoading={databases === undefined} onChange={(e) => handleOnChange(e, "databases")} value={applicationComponent.databases} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label" style={{ marginBottom: "12px" }}>Physical Servers</label>
                          <Select id="physicalservers" name="physicalservers" isMulti options={physicalServers} isLoading={physicalServers === undefined} onChange={(e) => handleOnChange(e, "physicalservers")} value={applicationComponent.physicalservers} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label" style={{ marginBottom: "12px" }}>Virtual Servers</label>
                          <Select id="virtualservers" name="virtualservers" isMulti options={virtualServers} isLoading={virtualServers === undefined} onChange={(e) => handleOnChange(e, "virtualservers")} value={applicationComponent.virtualservers} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label" style={{ marginBottom: "12px" }}>Kubernetes</label>
                          <Select id="kubernetes" name="kubernetes" isMulti options={kubernetes} isLoading={kubernetes === undefined} onChange={(e) => handleOnChange(e, "kubernetes")} value={applicationComponent.kubernetes} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <Link id="back" to={"/discovery/application-components"}><button className="btn btn-primary text-left" type="submit">Back</button></Link>
                    <button id="submit" className="btn btn-primary float-right" onClick={handleOnSubmit} type="submit">{id ? "Update" : "Create"}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment >
  );
};
export default ApplicationComponentEdit;
