import React, { Fragment } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import DataTable from "react-data-table-component";
import axios from "axios";
import FilterComponent from "../../components/common/FilterComponent";
import { tableStyles } from "../../components/common/CustomStyles";
import { setToast } from "../../components/common/Toast";
import { toast } from "react-toastify";

const ServerSpecs = () => {
  const [servers, setServers] = React.useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");
  const [pending, setPending] = React.useState(true);

  const getServerSpecs = React.useCallback(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/sustainability/server-specs`)
      .then((response) => {
        setServers(response.data);
        setPending(false);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve server specs");
        setPending(false);
      });
  }, []);

  React.useEffect(() => {
    setPending(true);
    getServerSpecs();
  }, [getServerSpecs]);

  /* istanbul ignore next */
  const columns = [
    {
      name: "Material Name",
      selector: (server) => server.MaterialName,
      center: true,
      wrap: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Model",
      selector: (server) => server.Model,
      center: true,
      wrap: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Manufacturer",
      selector: (server) => server.Manufacturer,
      center: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Material Type",
      selector: (server) => server.MaterialType,
      center: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Watts",
      selector: (server) => server.Watts,
      center: true,
      wrap: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Mount Type",
      selector: (server) => server.MountType,
      center: true,
      wrap: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    }
  ];

  const filteredItems = servers.filter(
    (item) => item.MaterialName && item.MaterialName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholder={"Material Name"} />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <Fragment>
      <Breadcrumb title="Server Specs" parent="Sustainability" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{"Server Specs"}</h5>
              </div>
              <div className="card-body datatable-react">
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  striped={true}
                  center={true}
                  customStyles={tableStyles}
                  progressPending={pending}
                  subHeaderComponent={subHeaderComponentMemo}
                  subHeader
                  persistTableHead
                  pagination
                  responsive
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ServerSpecs;