import React, { Fragment, useState } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import StepZilla from "react-stepzilla";
import SelectType from "./SelectType";
import ConfigureSecretType from "./ConfigureSecretType";
import Review from "./Review";

const AddSecret = () => {
  const [credentialChoice, setCredentialChoice] = useState("");
  const [database, setDatabase] = useState("");
  const [server, setServer] = useState("");
  const [secretType, setSecretType] = useState("");
  const [pemSecret, setPemSecret] = useState("");
  const [pemName, setPemName] = useState("");
  const [singleTypeSecret, setSingleTypeSecret] = useState("");
  const [username, setUsername] = useState("");
  const [databaseUsername, setDatabaseUsername] = useState("");
  const [databasePassword, setDatabasePassword] = useState("");
  const [databaseType, setDatabaseType] = useState("");
  const [databasePort, setDatabasePort] = useState("");
  const [databaseName, setDatabaseName] = useState("");

  const steps =
    [
      {
        name: "Select Database/Server",
        component: <SelectType
          credentialChoice={credentialChoice}
          setCredentialChoice={setCredentialChoice}
          database={database}
          setDatabase={setDatabase}
          server={server}
          setServer={setServer}
        />
      },
      {
        name: "Configure Secret",
        component: <ConfigureSecretType
          secretType={secretType}
          setSecretType={setSecretType}
          pemSecret={pemSecret}
          setPemSecret={setPemSecret}
          pemName={pemName}
          setPemName={setPemName}
          singleTypeSecret={singleTypeSecret}
          setSingleTypeSecret={setSingleTypeSecret}
          username={username}
          setUsername={setUsername}
          databaseUsername={databaseUsername}
          setDatabaseUsername={setDatabaseUsername}
          databasePassword={databasePassword}
          setDatabasePassword={setDatabasePassword}
          databaseType={databaseType}
          setDatabaseType={setDatabaseType}
          databasePort={databasePort}
          setDatabasePort={setDatabasePort}
          databaseName={databaseName}
          setDatabaseName={setDatabaseName}
        />
      },
      {
        name: "Review",
        component: <Review
          credentialChoice={credentialChoice}
          database={database}
          server={server}
          secretType={secretType}
          pemName={pemName}
          pemSecret={pemSecret}
          singleTypeSecret={singleTypeSecret}
          username={username}
          databaseUsername={databaseUsername}
          databasePassword={databasePassword}
          databaseType={databaseType}
          databasePort={databasePort}
          databaseName={databaseName}
        />
      }
    ];

  return (
    <Fragment>
      <Breadcrumb title="Add Secret" parent="Secrets" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Add Secret to Vault</h5>
              </div>
              <div className="card-body">
                <StepZilla
                  steps={steps}
                  showSteps={true}
                  showNavigation={true}
                  stepsNavigation={true}
                  prevBtnOnLastStep={true}
                  dontValidate={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddSecret;