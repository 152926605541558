import React from "react";
import { CartesianGrid, AreaChart, XAxis, YAxis, Tooltip, Area, ResponsiveContainer, Legend } from "recharts";
import moment from "moment";
import { sumServiceTotals, carbonDataToCsv } from "../../helpers";
import { useCurrentPng } from "recharts-to-png";
import FileSaver from "file-saver";
import { CSVLink } from "react-csv";
import { Button, Col } from "reactstrap";
import { setToast } from "../common/Toast";
import { toast } from "react-toastify";

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      /* istanbul ignore next */
      setWindowDimensions(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

const ReChartLineGraph = (props) => {
  const [co2Selected, setCo2Selected] = React.useState(true);
  const [costSelected, setCostSelected] = React.useState(false);
  const [kiloSelected, setKiloSelected] = React.useState(false);

  const { width } = useWindowDimensions();
  const [getChartPng, { ref: chartRef }] = useCurrentPng();
  const AllCarbon = sumServiceTotals(props.data);

  const toggleLineData = (index) => {
    if (index === "y") {
      setCo2Selected(true);
      setCostSelected(false);
      setKiloSelected(false);
    } else if (index === "cost") {
      setCo2Selected(false);
      setCostSelected(true);
      setKiloSelected(false);
    } else {
      setCo2Selected(false);
      setCostSelected(false);
      setKiloSelected(true);
    }
  };

  /* istanbul ignore next */
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart ref={chartRef} data={AllCarbon.co2Series}
        margin={{ top: 10, right: 30, left: 0, bottom: 80 }}>
        <defs>
          <linearGradient id="colorAWS" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#008AD7" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#008AD7" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorGCP" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#32CD32" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#32CD32" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorAZURE" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#F4DB07" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#F4DB07" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis interval={width < 1450 ? 1 : 0} angle={-45} tick={{ textAnchor: "end" }} tickFormatter={(e) => moment(e).format("LL")} dataKey="x" />
        {co2Selected ? <YAxis label={{ value: "CO2e (metric tons)", angle: -90, position: "insideLeft" }} dataKey="y"></YAxis> : null}
        {costSelected ? <YAxis label={{ value: "Cost ($)", angle: -90, position: "insideLeft" }} dataKey="cost" /> : null}
        {kiloSelected ? <YAxis label={{ value: "KiloWatts Hours (kWh)", angle: -90, position: "insideLeft" }} dataKey="kilowattHours" /> : null}
        <CartesianGrid x={AllCarbon.co2Series.length} strokeDasharray={"4 1 2"} />
        <Tooltip wrapperStyle={{ backgroundColor: "white", borderStyle: "ridge", paddingLeft: "10px", paddingRight: "10px" }} content={<CustomTooltip />} />
        <Legend verticalAlign="top" content={<CustomLegend chartPng={getChartPng} data={AllCarbon} toggleLineData={toggleLineData} costSelected={costSelected} co2Selected={co2Selected} kiloSelected={kiloSelected} />} />
        <Area type="monotone" dataKey="y" label={"co2"} stroke={co2Selected ? "#008AD7" : "none"} fillOpacity={co2Selected ? 1 : 0} fill="url(#colorAWS)" />
        <Area type="monotone" dataKey="cost" stroke={costSelected ? "#32CD32" : "none"} fillOpacity={costSelected ? 1 : 0} fill="url(#colorGCP)" />
        <Area type="monotone" dataKey="kilowattHours" stroke={kiloSelected ? "#F4DB07" : "none"} fillOpacity={kiloSelected ? 1 : 0} fill="url(#colorAZURE)" />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export const CustomTooltip = (props) => {
  if (props.active && props.payload && props.payload.length) {
    return (
      <div>
        <p>{`${moment(props.label).format("LL")}`}</p>
        <p>{`CO2: ${props.payload[0].value} metric tons CO2e*`}</p>
        <p>{`Cost: $${props.payload[1].value}`}</p>
        <p>{`Kilowatt Hours: ${props.payload[2].value}`}</p>
      </div>
    );
  }

  return <div id="error"><p style={{ color: "black" }}>Failed</p></div>;
};

const CustomLegend = (props) => {
  const { payload } = props;

  const handleAreaDownload = React.useCallback(() => {
    props.chartPng()
      .then((png) => {
        /* istanbul ignore next */
        if (png) {
          FileSaver.saveAs(png, "co2-chart.png");
        }
      })
      .catch(() => {
        setToast(toast.error, "Unable to download PNG");
      });
  }, [props.chartPng]);

  return (
    <Col style={{ marginBottom: "20px" }} sm="12">
      <Button
        id="co2"
        style={{ marginRight: "10px" }}
        color="primary"
        active={!props.co2Selected}
        outline={props.co2Selected}
        key={`item-${payload[0].dataKey}`}
        onClick={() => props.toggleLineData(payload[0].dataKey)}
      >
        CO2
      </Button>
      <Button
        id="cost"
        style={{ marginRight: "10px" }}
        color="success"
        active={!props.costSelected}
        outline={props.costSelected}
        key={`item-${payload[1].dataKey}`}
        onClick={() => props.toggleLineData("cost")}
      >
        Cost
      </Button>
      <Button
        id="kilowatt-hours"
        style={{ marginRight: "10px" }}
        color="warning"
        active={!props.kiloSelected}
        outline={props.kiloSelected}
        key={`item-${payload[2].dataKey}`}
        onClick={() => props.toggleLineData("kilowattHours")}
      >
        Kilowatt Hours
      </Button>
      <Button
        style={{ marginRight: "10px" }}
        id="download-png"
        type="button"
        color="secondary"
        onClick={handleAreaDownload}
      >
        Download PNG
      </Button>
      <Button
        id="download-csv"
        type="button"
        color="secondary"
      >
        <CSVLink
          style={{ textDecoration: "none", color: "white" }}
          data={carbonDataToCsv(props.data)}
        >
          Download CSV
        </CSVLink>
      </Button>
    </Col>
  );
};


export default ReChartLineGraph;