import React, { Fragment } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import DataTable from "react-data-table-component";
import axios from "axios";
import FilterComponent from "../../../components/common/FilterComponent";
import { tableStyles } from "../../../components/common/CustomStyles";
import { setToast } from "../../../components/common/Toast";
import { toast } from "react-toastify";
import MigrationProjectModal from "./MigrationProjectModal";
import FdmButton from "./FdmButton";
import { Edit, Trash } from "react-feather";

const MigrationProjects = () => {
  const [projects, setProjects] = React.useState([]);
  const [projectModal, setProjectModal] = React.useState(false);
  const [currentProject, setCurrentProject] = React.useState({});
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");

  const getMigrationProjects = React.useCallback(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/fdm/migration-projects`)
      .then((response) => {
        setProjects(response.data);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve migration projects");
      });
  }, []);

  React.useEffect(() => {
    getMigrationProjects();
  }, [getMigrationProjects]);

  const handleEditProject = (data) => {
    setCurrentProject(data);
    setProjectModal(true);
  };

  const handleDeleteProject = (data) => {
    axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/fdm/migration-projects/${data.migrationProjectId}`)
      .then(() => {
        setToast(toast.success, "Migration project deleted");
        getMigrationProjects();
      })
      .catch(() => {
        setToast(toast.error, "Unable to delete migration project");
      });
  };

  /* istanbul ignore next" */
  const columns = [
    {
      name: "Name",
      selector: (migration) => migration.migrationProjectName,
      center: true,
      wrap: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Description",
      selector: (migration) => migration.migrationProjectDescription,
      center: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Create Date",
      selector: (migration) => migration.createDate,
      center: true,
      wrap: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Update Date",
      selector: (migration) => migration.updateDate,
      center: true,
      wrap: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Migrations",
      center: true,
      width: "9rem",
      cell: (data) => <button className="btn btn-primary" onClick={() => window.location = `/database-factory/oracle-to-postgresql/migrations/${data.migrationProjectId}`}>View</button>,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Actions",
      center: true,
      width: "7rem",
      cell: (data) =>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <button id={`edit-${data.migrationProjectId}`} className="btn btn-pill" style={{ margin: "5px 0px 5px 0px", backgroundColor: "#22af47" }} onClick={() => handleEditProject(data)}>
            <Edit style={{ height: "15px", width: "auto", color: "white" }} />
          </button>
          <button id={`delete-${data.migrationProjectId}`} className="btn btn-pill" style={{ margin: "5px 0px 5px 0px", backgroundColor: "#e83845" }} onClick={() => handleDeleteProject(data)}>
            <Trash style={{ height: "15px", width: "auto", color: "white" }} />
          </button>
        </div>
    }
  ];

  const filteredItems = projects.filter(
    (item) => item.migrationProjectName && item.migrationProjectName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <div>
          <button id="create-migration-project" className="btn btn-primary" onClick={() => setProjectModal(true)} type="button">Create Migration Project</button>
        </div>
        <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholder={"Project"} />
      </div>

    );
  }, [filterText, resetPaginationToggle]);

  return (
    <Fragment>
      <Breadcrumb title="Oracle-To-Postgres Migrations" parent="Database Factory" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{"Migration Projects"}</h5>
              </div>
              <div className="card-body datatable-react">
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  striped={true}
                  center={true}
                  customStyles={tableStyles}
                  subHeaderComponent={subHeaderComponentMemo}
                  subHeader
                  persistTableHead
                  pagination
                  responsive
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FdmButton />
      <MigrationProjectModal
        projects={projects}
        currentProject={currentProject}
        setCurrentProject={setCurrentProject}
        projectModal={projectModal}
        setProjectModal={setProjectModal}
        getMigrationProjects={getMigrationProjects}
      />
    </Fragment>
  );
};

export default MigrationProjects;