import { setToast } from "../components/common/Toast";
import { toast } from "react-toastify";

export const handleChangeStatus = ({ meta, remove }, status) => {
  if (status === "headers_received") {
    setToast(toast.success, `Uploaded ${meta.name}`);
    remove();
  } else if (status === "aborted") {
    setToast(toast.error, `Unable to upload ${meta.name}`);
  }
};

export const getUploadParamsApplications = ({ file }) => {
  let body = new FormData();
  body.append("file", file);

  return { url: "/api/business-applications/upload", body };
};

export const getUploadParamsApplicationsServiceNow = ({ file }) => {
  let body = new FormData();
  body.append("file", file);

  return { url: "/api/business-applications/upload/servicenow", body };
};

export const getUploadParamsDatabase = ({ file }) => {
  let body = new FormData();
  body.append("file", file);

  return { url: "/api/databases/upload", body };
};

export const getUploadParamsFactors = ({ file }) => {
  let body = new FormData();
  body.append("file", file);

  return { url: "/api/factor-repository/upload", body };
};