import React from "react";
import Modal from "react-responsive-modal";
import axios from "axios";
import { setToast } from "../../../components/common/Toast";
import { toast } from "react-toastify";
import { defaultValues } from "./MigrationList";
import Select from "react-select";

const MigrationProcessModal = (props) => {
  const [validSource, setValidSource] = React.useState(false);
  const [showTestSourceConnection, setShowTestSourceConnection] = React.useState(false);
  const [schemas, setSchemas] = React.useState(false);
  const [validSourceConnection, setValidSourceConnection] = React.useState(false);
  const [validTarget, setValidTarget] = React.useState(false);
  const [showTestTargetConnection, setShowTestTargetConnection] = React.useState(false);
  const [validTargetConnection, setValidTargetConnection] = React.useState(false);
  const [nonPostgresDatabases, setNonPostgresDatabases] = React.useState(undefined);
  const [postgresDatabases, setPostgresDatabases] = React.useState(undefined);

  React.useEffect(() => {
    if (props.migrationModal) {
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/databases/all-ips`)
        .then((response) => {
          if (props.migrationProcessId) {
            try {
              props.setMigrationDetails({
                ...props.migrationDetails,
                source: {
                  ...props.migrationDetails.source,
                  name: response.data.nonPostgresDatabases.find((item) => {
                    /* istanbul ignore next */
                    return item.ip === props.migrationDetails.source.host && item.sid.toLowerCase() === props.migrationDetails.source.sid;
                  }).name
                },
                target: {
                  ...props.migrationDetails.target,
                  name: response.data.postgresDatabases.find((item) => {
                    /* istanbul ignore next */
                    return item.ip === props.migrationDetails.target.host && item.sid.toLowerCase() === props.migrationDetails.target.sid;
                  }).name
                }
              });
            } catch (err) {
              setToast(toast.error, "Unable to locate database");
            }
          }

          setNonPostgresDatabases(response.data.nonPostgresDatabases.map((item) => {
            return {
              ...item,
              value: item.name,
              label: `${item.name} (${item.sid})`
            };
          }));

          setPostgresDatabases(response.data.postgresDatabases.map((item) => {
            return {
              ...item,
              value: item.name,
              label: `${item.name} (${item.sid})`
            };
          }));
        })
        .catch(() => {
          setToast(toast.error, "Unable to retrieve databases");
        });
    }
  }, [props.migrationModal]);

  const handleMigrationModalClose = () => {
    props.setMigrationModal(false);
    props.setMigrationDetails(defaultValues);
    setValidSource(false);
    setShowTestSourceConnection(false);
    setSchemas(false);
    setValidSourceConnection(false);
    setValidTarget(false);
    setValidTargetConnection(false);
    setShowTestTargetConnection(false);
    props.getMigrations();
    props.setMigrationProcessId();
  };

  const handleEditMigrationSubmit = () => {
    axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/fdm/migration-process`, {
      migrationDetails: props.migrationDetails,
      projectId: props.id
    })
      .then(() => {
        handleMigrationModalClose();
      })
      .catch(() => {
        setToast(toast.error, "Unable to edit migration");
      });
  };

  const handleCreateMigrationSubmit = () => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/fdm/migration-process`, {
      migrationDetails: props.migrationDetails,
      projectId: props.id
    })
      .then(() => {
        handleMigrationModalClose();
      })
      .catch(() => {
        setToast(toast.error, "Unable to create migration");
      });
  };

  React.useEffect(() => {
    setValidSourceConnection(false);

    if (props.migrationDetails.source.host !== undefined) {
      setShowTestSourceConnection(true);
      setValidSource(true);
    } else {
      setShowTestSourceConnection(false);
      setValidSource(false);
    }
  }, [props.migrationDetails.source.host]);

  React.useEffect(() => {
    setValidTargetConnection(false);

    if (props.migrationDetails.target.host !== undefined) {
      setShowTestTargetConnection(true);
      setValidTarget(true);
    } else {
      setShowTestTargetConnection(false);
      setValidTarget(false);
    }
  }, [props.migrationDetails.target.host]);

  const testSourceConnectionAPI = () => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/fdm/test-source-connection`, {
      source: props.migrationDetails.source
    })
      .then((res) => {
        setSchemas(res.data.map((item) => {
          return {
            ...item,
            value: item.schemaName,
            label: item.schemaName
          };
        }));
        setShowTestSourceConnection(false);
        setValidSourceConnection(true);
      })
      .catch(() => {
        setToast(toast.error, "Unable to connect to source database");
        setShowTestSourceConnection(true);
        setValidSourceConnection(false);
      });
  };

  const testTargetConnectionAPI = () => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/fdm/test-target-connection`, {
      target: props.migrationDetails.target
    })
      .then(() => {
        setShowTestTargetConnection(false);
        setValidTargetConnection(true);
      })
      .catch(() => {
        setToast(toast.error, "Unable to connect to target database");
        setShowTestTargetConnection(true);
        setValidTargetConnection(false);
      });
  };

  return (
    <Modal open={props.migrationModal} onClose={handleMigrationModalClose}>
      <div className="modalCustom">
        <h6 className="f-w-600 text-align-centre">Create Migration Process</h6>

        <p className="form-text">Process Name</p>
        <input
          id="process-name"
          type="text"
          className="form-control"
          maxLength={20}
          value={props.migrationDetails.processName}
          onChange={(e) => props.setMigrationDetails({ ...props.migrationDetails, processName: e.target.value })}
        />

        <div className="form-side-by-side">
          <div className="form-column" style={{ width: "20rem" }}>
            <h6 className="f-w-600 text-align-centre">Source Database</h6>

            <p className="form-text" style={{ marginBottom: "12px" }}>Source Database Host</p>
            <Select id="host" name="host" options={nonPostgresDatabases} isLoading={nonPostgresDatabases === undefined} onChange={(e) => {
              /* istanbul ignore next */
              nonPostgresDatabases && props.setMigrationDetails({ ...props.migrationDetails, source: { ...props.migrationDetails.source, host: e.ip, type: e.vendor.toUpperCase(), name: e.name, sid: e.sid } });
            }} value={nonPostgresDatabases && nonPostgresDatabases.find((item) => {
              /* istanbul ignore next */
              return item.sid.toLowerCase() === props.migrationDetails.source.sid && item.ip === props.migrationDetails.source.host;
            })} />

            <div style={{ marginTop: "12px", marginBottom: "12px" }}>
              {
                showTestSourceConnection ?
                  <button id="test-connection" className="btn btn-pill btn-primary btn-air-primary btn-modal" onClick={testSourceConnectionAPI} type="button">Test Connection</button>
                  : validSource &&
                  <p className="connection-successful">Connection Successful</p>
              }
            </div>

            {
              schemas &&
              <div style={{ marginBottom: "12px" }}>
                <p className="form-text" style={{ marginBottom: "12px" }}>Schemas</p>
                <Select id="schemas" name="schemas" isMulti options={schemas} onChange={(e) => {
                  /* istanbul ignore next */
                  props.setMigrationDetails({ ...props.migrationDetails, ...{ schemas: e } });
                }} value={props.migrationDetails.schemas} components={{ DropdownIndicator: null }} placeholder="Schemas" />
              </div>
            }
          </div>

          <div className="form-column" style={{ width: "20rem" }}>
            <h6 className="f-w-600 text-align-centre">Target Database</h6>

            <p className="form-text" style={{ marginBottom: "12px" }}>Target Database Host</p>
            <Select id="host" name="host" options={postgresDatabases} isLoading={postgresDatabases === undefined} onChange={(e) => {
              /* istanbul ignore next */
              postgresDatabases && props.setMigrationDetails({ ...props.migrationDetails, target: { ...props.migrationDetails.target, host: e.ip, type: e.vendor.toUpperCase(), name: e.name, sid: e.name } });
            }} value={postgresDatabases && postgresDatabases.find((item) => {
              /* istanbul ignore next */
              return item.sid.toLowerCase() === props.migrationDetails.target.sid && item.ip === props.migrationDetails.target.host;
            })} />

            <div style={{ marginTop: "12px" }}>
              {showTestTargetConnection ?
                <button id="test-connection" className="btn btn-pill btn-primary btn-air-primary btn-modal" onClick={testTargetConnectionAPI} type="button">Test Connection</button>
                : validTarget &&
                <p className="connection-successful">Connection Successful</p>
              }
            </div>
          </div>
        </div>

        <button id="submit" className="btn btn-pill btn-primary btn-air-primary btn-modal" onClick={props.migrationProcessId ? handleEditMigrationSubmit : handleCreateMigrationSubmit} disabled={!(validSourceConnection && validTargetConnection && props.migrationDetails.schemas.length > 0)} type="button">Submit</button>
      </div>
    </Modal >
  );
};

export default MigrationProcessModal;