import React, { Fragment } from "react";
import one from "../../../assets/images/user/1.jpg";
import four from "../../../assets/images/user/4.jpg";
import seven from "../../../assets/images/user/7.jpg";
import { CurrentProgress, WebApplication, DesignAndDevelopment, LoginModule, Development, ModuleTesting, Testing } from "../../../constant";

const BudgetComponent = () => {
  return (
    <Fragment>
      <div className="tab-content" id="tab-2">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{CurrentProgress}</h5>
              </div>
              <div className="card-body">
                <div className="table-responsive current-progress">
                  <table className="table table-bordernone">
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-inline-block align-middle">
                            <img className="img-radius img-50 align-top m-r-15 rounded-circle" src={one} alt="" />
                            <div className="d-inline-block">
                              <h6>{WebApplication}</h6>
                              <p>{DesignAndDevelopment}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-inline-block align-middle">
                            <span>{"Latest Updated Today at 1:30 PM"}</span>
                            <span className="ml-current">
                              <i className="fa fa-clock-o"></i>{"10:32"}</span>
                            <span className="ml-current">
                              <i className="fa fa-comment"></i>{"540"}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="progress sm-progress-bar">
                            <div className="progress-bar bg-primary" role="progressbar" style={{ width: "60%" }} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-inline-block align-middle">
                            <img className="img-radius img-50 align-top m-r-15 rounded-circle" src={four} alt="" />
                            <div className="d-inline-block">
                              <h6>{LoginModule}</h6>
                              <p>{Development}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-inline-block align-middle"><span>{"Latest Updated Today at 4:00 PM"}</span><span className="ml-current"><i className="fa fa-clock-o"></i>{"1:32"}</span><span className="ml-current"><i className="fa fa-comment"></i>{"700"}</span></div>
                        </td>
                        <td>
                          <div className="progress sm-progress-bar">
                            <div className="progress-bar bg-primary" role="progressbar" style={{ width: "50%" }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-inline-block align-middle">
                            <img className="img-radius img-50 align-top m-r-15 rounded-circle" src={seven} alt="" />
                            <div className="d-inline-block">
                              <h6>{ModuleTesting}</h6>
                              <p>{Testing}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-inline-block align-middle"><span>{"Latest Updated Today at 5:45 PM"}</span><span className="ml-current"><i className="fa fa-clock-o"></i>{"11:40"}</span><span className="ml-current"><i className="fa fa-comment"></i>{"425"}</span></div>
                        </td>
                        <td>
                          <div className="progress sm-progress-bar">
                            <div className="progress-bar bg-primary" role="progressbar" style={{ width: "90%" }} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BudgetComponent;