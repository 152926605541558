import React, { Fragment } from "react";
import six from "../../../assets/images/user/6.jpg";
import { TeamMembers } from "../../../constant";
import axios from "axios";
import { setToast } from "../../../components/common/Toast";
import { toast } from "react-toastify";

const UserComponent = () => {
  const [users, setUsers] = React.useState([]);

  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users`)
      .then((res) => {
        setUsers(res.data);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve users");
      });
  }, []);

  return (
    <Fragment>
      <div className="tab-content" id="tab-3">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{TeamMembers}</h5>
              </div>
              <div className="card-body">
                <div className="table-responsive sellers team-members">
                  <table className="table table-bordernone">
                    <thead>
                      <tr>
                        <th scope="col">{"Name"}</th>
                        <th scope="col">{"Email"}</th>
                        <th scope="col">{"Status"}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user) => {
                        return (
                          <tr key={user.id}>
                            <td>
                              <div className="d-inline-block align-middle">
                                <img className="img-radius img-50 align-top m-r-15 rounded-circle" src={six} alt="" />
                                <div className="d-inline-block">
                                  <p>{user.name}</p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <p>{user.email}</p>
                            </td>
                            <td>
                              <p style={{ color: user.status === "ACTIVE" ? "green": "red", fontWeight: "bold" }}>{user.status}</p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Fragment>
  );
};

export default UserComponent;