import React from "react";
import Modal from "react-responsive-modal";

const AssessmentResultModal = (props) => {
  return (
    <Modal open={props.openResult} onClose={() => props.setOpenResult(false)}>
      <div className="container-fluid">
        <div className="row">
          <h6 className="f-w-600 text-align-centre">Survey Data</h6>
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Equipment</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Co2 Per Year</th>
                      <th scope="col">Co2e Per Year</th>
                      <th scope="col">Date Created</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.selectedAssessmentResult !== undefined ?
                      Object.values(props.selectedAssessmentResult).map((value, index) => {
                        return (
                          <tr key={index}>
                            <td>{value.equipment}</td>
                            <td>{value.quantity}</td>
                            <td>{value.co2}</td>
                            <td>{value.co2e}</td>
                            <td>{value.date.toLocaleString("en-GB")}</td>
                          </tr>
                        );
                      })
                      :
                      <tr id="error">
                        <td colSpan={5}>No Data</td>
                      </tr>
                    }
                  </tbody>
                </table>
                <div style={{ display: "flex", padding: "1rem", placeContent: "center", justifyContent: "space-evenly" }} className="text-center">
                  {props.selectedAssessment && props.selectedAssessment[0] &&
                    <h6 className="f-w-600">Total Co2: {props.selectedAssessment[0].totalCO2}</h6>
                  }

                  {props.selectedAssessment && props.selectedAssessment[0] &&
                    <h6 className="f-w-600"> Total Co2e: {props.selectedAssessment[0].totalCO2e}</h6>
                  }
                </div>
                <div className="text-center"><button className="btn btn-primary">Download Report</button></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AssessmentResultModal;