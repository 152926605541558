import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import seven from "../../assets/images/user/7.jpg";
import { MyProfile, Password, EmailAddress } from "../../constant";
import { useOktaAuth } from "@okta/okta-react";
import { useAuthUser } from "../../authUserContext";

const UserEdit = () => {
  const { authState } = useOktaAuth();
  const [url, setUrl] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { authUser } = useAuthUser();

  useEffect(() => {
    if (authUser) {
      // setUrl(userInfo.profile.picture);
      setEmail(authUser.email);
    }
  }, [authUser]);

  const readUrl = (event) => {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) === null) {
      return;
    }
    // Image upload
    var reader = new FileReader();

    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      setUrl(reader.result);
    };
  };

  if (!authState || authUser === undefined) {
    return (
      <p>Loading...</p>
    );
  } else {
    return (
      <Fragment>
        <Breadcrumb parent="User" title="Edit Profile" />
        <div className="container-fluid">
          <div className="edit-profile">
            <div className="row">
              <div className="col-lg-4">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title mb-0">{MyProfile}</h4>
                    <div className="card-options">
                      <a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                  </div>
                  <div className="card-body">
                    <form>
                      <div className="row mb-3">
                        <div className="user-image">
                          <div className="avatar">
                            <img className="pro" alt="" src={url ? url : seven} data-intro="This is Profile image" />
                          </div>
                          <div className="icon-wrapper">
                            <i className="icofont icofont-pencil-alt-5" data-intro="Change Profile image here" >
                              <input className="pencil" type="file" onChange={(e) => readUrl(e)} />
                            </i>
                          </div>
                        </div>
                        <div className="col">
                          <h3 className="mb-1">{authUser.name}</h3>
                          {authUser.groups.includes("Administrators") ?
                            <p className="mb-4">Administrator</p>
                            :
                            <p className="mb-4">User</p>
                          }
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form-label">{EmailAddress}</label>
                        <input className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="email@example.com" />
                      </div>
                      <div className="form-group">
                        <label className="form-label">{Password}</label>
                        <input className="form-control" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                      </div>
                      <div className="form-footer">
                        <button className="btn btn-primary btn-block">Update</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
};

export default UserEdit;