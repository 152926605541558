import React, { Fragment } from "react";
import { regions } from "../azr-list/Regions";

const SelectNetwork = (props) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12">

          <form className="needs-validation" noValidate>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label>{"Region"}</label>
                <select
                  className="form-control"
                  value={props.location}
                  onChange={(e) => props.setLocation(e.target.value)}
                  required
                >
                  <option key="" value="" disabled>Select</option>
                  {regions.map((location, index) => (
                    <option key={index} value={location}>{location}</option>
                  ))}
                </select>
              </div>

            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default SelectNetwork;