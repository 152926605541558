import React, { Fragment } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import StepZilla from "react-stepzilla";
import Source from "./Source";
import Target from "./Target";
import MigrationConfiguration from "./MigrationConfiguration";
import { useParams } from "react-router";
import axios from "axios";
import { setToast } from "../../../components/common/Toast";
import { toast } from "react-toastify";

export const defaultConnectionParameters = {
  host: "",
  port: 1521,
  container: true,
  containerName: "",
  pluggableName: "",
  sid: "",
  dbVersion: ""
};

export const defaultMigrationParameters = {
  expdpDir: "DATA_PUMP_DIR",
  exdpDumpFile: "expdat.dmp",
  exdpLogFile: "export.log",
  networkLink: "",
  cloudProvider: "",
  bucketName: ""
};

const CreateOra2OraMigration = () => {
  const [sourceConnectionParameters, setSourceConnectionParameters] = React.useState(defaultConnectionParameters);
  const [targetConnectionParameters, setTargetConnectionParameters] = React.useState(defaultConnectionParameters);
  const [migrationConfiguration, setMigrationConfiguration] = React.useState(defaultMigrationParameters);
  const { id } = useParams();

  React.useEffect(() => {
    if (id) {
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/oracle-2-oracle/migrations/${id}`)
        .then((res) => {
          setSourceConnectionParameters(res.data.sourceConnectionParameters);
          setTargetConnectionParameters(res.data.targetConnectionParameters);
          setMigrationConfiguration(res.data.migrationConfiguration);
        })
        .catch(() => {
          setToast(toast.error, "Unable to retrieve migration details");
          window.location.assign("/database-factory/oracle-to-oracle");
        });
    }
  }, [id]);

  const steps =
    [
      {
        name: "Source",
        component: <Source
          sourceConnectionParameters={sourceConnectionParameters}
          setSourceConnectionParameters={setSourceConnectionParameters}
        />
      },
      {
        name: "Target",
        component: <Target
          targetConnectionParameters={targetConnectionParameters}
          setTargetConnectionParameters={setTargetConnectionParameters}
        />
      },
      {
        name: "Migration Configuration",
        component: <MigrationConfiguration
          sourceConnectionParameters={sourceConnectionParameters}
          targetConnectionParameters={targetConnectionParameters}
          migrationConfiguration={migrationConfiguration}
          setMigrationConfiguration={setMigrationConfiguration}
          id={id}
        />
      }
    ];

  return (
    <Fragment>
      <Breadcrumb title="Create Migration" parent="Oracle To Oracle" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Create Oracle To Oracle Migration</h5>
              </div>
              <div className="card-body">
                <StepZilla
                  steps={steps}
                  showSteps={true}
                  showNavigation={true}
                  stepsNavigation={true}
                  prevBtnOnLastStep={true}
                  dontValidate={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CreateOra2OraMigration;