import React from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import Links from "../../components/common/Links";

const Mongo = () => {
  return (
    <div>
      <Breadcrumb title="MongoDB" parent="Database Factory" />
      <Links pathname={window.location.pathname} />
    </div>
  );
};
export default Mongo;
