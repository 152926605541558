import React from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import Links from "../../components/common/Links";

const Vault = () => {
  return (
    <div>
      <Breadcrumb title="Vault" parent="Secrets" />
      <Links pathname={window.location.pathname} />
    </div>
  );
};
export default Vault;
