import React, { Fragment } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import axios from "axios";
import "react-dropzone-uploader/dist/styles.css";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { setToast } from "../../../components/common/Toast";
import { ExternalLink, Trash } from "react-feather";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

const VirtualServerEdit = () => {
  const [virtualServer, setVirtualServer] = React.useState({
    name: "", status: true, cpu: "", memory: "", os: "", ip: [], cloud: "", tags: [], cloudlink: "",
    datadoglink: "", state: "PROVISIONED", transformationphase: "",
    transformationstrategy: "", transformationgroup: "", jirakey: "INVALID", jiradomain: "", discovered: "Manual", mergestrategy: "Global"
  });
  const [jiradomain, setJiraDomain] = React.useState(undefined);
  const [transformationgroups, setTransformationgroups] = React.useState([]);
  const { discovery, id } = useParams();

  const getServer = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/virtual-servers/get-server/${discovery}/${id}`)
      .then((response) => {
        /* istanbul ignore next */
        setVirtualServer({
          ...response.data.server,
          ip: response.data.server.ip ? response.data.server.ip.map((item) => {
            return {
              _id: item._id,
              value: item.ip,
              label: item.ip
            };
          }) : [],
          tags: response.data.server.tags ? response.data.server.tags.map((item) => {
            return {
              value: item,
              label: item
            };
          }) : []
        });
        /* istanbul ignore next */
        setJiraDomain(response.data.jiradomain);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve virtual server");
      });
  };

  const getTransformationGroups = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/migration-groups`)
      .then((response) => {
        const migGroups = response.data.migrationGroups.map((item) => {
          return {
            value: item._id,
            label: item.name
          };
        });

        setTransformationgroups([
          {
            value: "",
            label: "None"
          },
          ...migGroups
        ]);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve transformation groups");
      });
  };

  React.useEffect(() => {
    if (typeof virtualServer.transformationgroup === "string" && transformationgroups.length > 0) {
      setVirtualServer({
        ...virtualServer,
        transformationgroup: transformationgroups.find((item) => item.value === virtualServer.transformationgroup)
      });
    }
  }, [virtualServer, transformationgroups]);

  React.useEffect(() => {
    if (id && discovery) {
      getServer();
    } else {
      setVirtualServer({
        ...virtualServer,
        discovered: "Manual",
        mergestrategy: "Manual"
      });
    }

    getTransformationGroups();
  }, []);

  const handleOnChange = (value, name) => {
    setVirtualServer({ ...virtualServer, [name]: value });
  };

  const handleOnSubmit = () => {
    axios.post(id ? `${process.env.REACT_APP_BACKEND_URL}/api/virtual-servers/${discovery}/${id}` : `${process.env.REACT_APP_BACKEND_URL}/api/virtual-servers/${discovery}`, {
      virtualServer: {
        ...virtualServer,
        ip: virtualServer.ip.map((item) => ({ _id: item._id, ip: item.value })),
        transformationgroup: virtualServer.transformationgroup.value,
        tags: virtualServer.tags.map((item) => item.value).join(",")
      }
    })
      .then(() => {
        window.location.assign("/discovery/virtual-servers");
      })
      .catch(() => {
        setToast(toast.error, `Unable to ${id ? "update" : "create"} virtual server`);
      });
  };

  const createJiraTicket = () => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/jira`, {
      items: [virtualServer],
      type: "virtualserver",
      discoveryId: discovery
    })
      .then(() => {
        setToast(toast.success, "Jira ticket created");
        setTimeout(() => getServer(), 1000);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setToast(toast.error, "No longer part of a migration group");
        } else {
          setToast(toast.error, "Unable to create Jira ticket");
        }
      });
  };

  const deleteJiraTicket = () => {
    axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/jira`, {
      data: {
        jirakey: virtualServer.jirakey,
        type: "virtualserver",
        discoveryId: discovery,
        serverId: id
      }
    })
      .then(() => {
        setToast(toast.success, "Jira ticket deleted");
        setTimeout(() => getServer(), 1000);
      })
      .catch(() => {
        setToast(toast.error, "Unable to delete Jira ticket");
      });
  };

  return (
    <Fragment>
      <Breadcrumb title={"Edit"} parent="Virtual Servers" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Edit Virtual Server Information</h5>
              </div>
              <div className="card-body datatable-react">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title mb-0">{`Server Information: ${virtualServer.name}`}</h4>
                    <div className="card-options">
                      <a className="card-options-collapse" href="javascript" data-toggle="card-collapse">
                        <i className="fe fe-chevron-up"></i>
                      </a>
                      <a className="card-options-remove" href="javascript" data-toggle="card-remove">
                        <i className="fe fe-x"></i>
                      </a>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className={`col-sm-6 ${virtualServer.name.length > 35 ? "col-md-6" : "col-md-3"}`}>
                        <div className="form-group">
                          <label className="form-label">Name</label>
                          {id ?
                            <input id="name" className="form-control" type="text" name="name" placeholder="Name" value={virtualServer.name} readOnly />
                            :
                            <input id="name" className="form-control" type="text" name="name" placeholder="Name" value={virtualServer.name} onChange={(e) => handleOnChange(e.target.value, "name")} />
                          }
                        </div>
                      </div>
                      <div className={`col-sm-6 ${virtualServer.status.length > 35 ? "col-md-6" : "col-md-3"}`}>
                        <div className="form-group">
                          <label className="form-label">Status</label>
                          <select id="status" className="form-control" name="status" value={virtualServer.status} disabled={id} onChange={(e) => handleOnChange(e.target.value, "status")}
                          >
                            <option value={true}>On</option>
                            <option value={false}>Off</option>
                          </select>
                        </div>
                      </div>
                      <div className={`col-sm-6 ${virtualServer.cpu.length > 35 ? "col-md-6" : "col-md-3"}`}>
                        <div className="form-group">
                          <label className="form-label">CPU</label>
                          <input id="cpu" className="form-control" type="number" name="cpu" placeholder="CPU" value={parseInt(virtualServer.cpu || "-1")} onChange={(e) => handleOnChange(e.target.value.toString(), "cpu")} />
                        </div>
                      </div>
                      <div className={`col-sm-6 ${virtualServer.memory.length > 35 ? "col-md-6" : "col-md-3"}`}>
                        <div className="form-group">
                          <label className="form-label">Memory</label>
                          <input id="memory" className="form-control" type="number" name="memory" placeholder="Memory" value={parseInt(virtualServer.memory || "-1")} onChange={(e) => handleOnChange(e.target.value.toString(), "memory")} />
                        </div>
                      </div>
                      <div className={`col-sm-6 ${virtualServer.os.length > 35 ? "col-md-6" : "col-md-3"}`}>
                        <div className="form-group">
                          <label className="form-label">Operating System</label>
                          <input id="os" className="form-control" type="text" name="os" placeholder="Operating System" value={virtualServer.os} onChange={(e) => handleOnChange(e.target.value, "os")} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label" style={{ marginBottom: "12px" }}>IP</label>
                          <CreatableSelect id="ip" name="ip" isMulti onChange={(e) => {
                            /* istanbul ignore next */
                            handleOnChange(e, "ip");
                          }} value={virtualServer.ip} components={{ DropdownIndicator: null }} placeholder="IP" />
                        </div>
                      </div>
                      <div className={`col-sm-6 ${virtualServer.cloud.length > 35 ? "col-md-6" : "col-md-3"}`}>
                        <div className="form-group">
                          <label className="form-label">Cloud</label>
                          <select id="cloud" className="form-control" name="cloud" value={virtualServer.cloud} onChange={(e) => handleOnChange(e.target.value, "cloud")}>
                            <option value="" disabled={virtualServer.cloud}>Select Cloud</option>
                            <option value="Amazon EC2 Instance">Amazon EC2 Instance</option>
                            <option value="GCE">GCE</option>
                          </select>
                        </div>
                      </div>
                      <div className={`col-sm-6 ${virtualServer.cloudlink.length > 35 ? "col-md-6" : "col-md-3"}`}>
                        <div className="form-group">
                          <label className="form-label">Cloud Link</label>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <input id="cloudlink" className="form-control" type="text" name="cloudlink" placeholder="Cloud Link" value={virtualServer.cloudlink} onChange={(e) => handleOnChange(e.target.value, "cloudlink")} />
                            <button id={`cloud-link-${virtualServer._id}`} className="btn btn-pill" style={{ margin: "5px 10px 10px 10px", backgroundColor: "#007bff" }} disabled={!new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&\/\/=]*)/).test(virtualServer.cloudlink)} onClick={() => window.open(virtualServer.cloudlink, "_blank")}>
                              <ExternalLink style={{ height: "15px", width: "auto", color: "white" }} />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className={`col-sm-6 ${virtualServer.datadoglink.length > 35 ? "col-md-6" : "col-md-3"}`}>
                        <div className="form-group">
                          <label className="form-label">Datadog Link</label>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <input id="datadoglink" className="form-control" type="text" name="datadoglink" placeholder="Datadog Link" value={virtualServer.datadoglink} onChange={(e) => handleOnChange(e.target.value, "datadoglink")} />
                            <button id={`datadog-link-${virtualServer._id}`} className="btn btn-pill" style={{ margin: "5px 10px 10px 10px", backgroundColor: "#007bff" }} disabled={!new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&\/\/=]*)/).test(virtualServer.datadoglink)} onClick={() => window.open(virtualServer.datadoglink, "_blank")}>
                              <ExternalLink style={{ height: "15px", width: "auto", color: "white" }} />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className={`col-sm-6 ${virtualServer.transformationphase.length > 35 ? "col-md-6" : "col-md-3"}`}>
                        <div className="form-group">
                          <label className="form-label">Transformation Phase</label>
                          <input id="transformationphase" className="form-control" type="text" name="transformationphase" placeholder="Transformation Phase" value={virtualServer.transformationphase} onChange={(e) => handleOnChange(e.target.value, "transformationphase")} />
                        </div>
                      </div>
                      <div className={`col-sm-6 ${virtualServer.transformationstrategy.length > 35 ? "col-md-6" : "col-md-3"}`}>
                        <div className="form-group">
                          <label className="form-label">Transformation Strategy</label>
                          <input id="transformationstrategy" className="form-control" type="text" name="transformationstrategy" placeholder="Transformation Strategy" value={virtualServer.transformationstrategy} onChange={(e) => handleOnChange(e.target.value, "transformationstrategy")} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label" style={{ marginBottom: "12px" }}>Transformation Group</label>
                          <Select id="transformationgroup" name="transformationgroup" onChange={(e) => {
                            /* istanbul ignore next */
                            handleOnChange(e, "transformationgroup");
                          }} value={virtualServer.transformationgroup} options={transformationgroups} placeholder={"Transformation Group"} isDisabled={
                            virtualServer.transformationgroup.value === "" && virtualServer.jirakey !== "INVALID"} />
                          {virtualServer.transformationgroup.value === "" && virtualServer.jirakey !== "INVALID" && <p style={{ textAlign: "center", marginTop: "10px" }}>Already a part of an application component.</p>}
                        </div>
                      </div>
                      <div className={`col-sm-6 ${virtualServer.state.length > 35 ? "col-md-6" : "col-md-3"}`}>
                        <div className="form-group">
                          <label className="form-label">State</label>
                          <input id="state" className="form-control" type="text" name="state" placeholder="State" value={virtualServer.state} readOnly />
                        </div>
                      </div>
                      {id && <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label">Jira Ticket</label>
                          {virtualServer.jirakey && virtualServer.jirakey !== "" && virtualServer.jirakey !== "INVALID" ?
                            <div style={{
                              display: "flex",
                              justifyContent: "space-between"
                            }}>
                              <button id={`jira-view-${virtualServer._id}`} className="btn btn-pill" style={{ margin: "5px 0px 5px 0px", backgroundColor: "#007bff", width: "47%" }} onClick={() => window.open(`${jiradomain}/browse/${virtualServer.jirakey}`, "_blank")}>
                                <ExternalLink style={{ height: "15px", width: "auto", color: "white" }} />
                              </button>
                              <button id={`jira-delete-${virtualServer._id}`} className="btn btn-pill" style={{ margin: "5px 0px 5px 0px", backgroundColor: "#e83845", width: "47%" }} onClick={() => deleteJiraTicket()}>
                                <Trash style={{ height: "15px", width: "auto", color: "white" }} />
                              </button>
                            </div>
                            :
                            <div>
                              <button id={`jira-create-${virtualServer._id}`} className="btn form-control btn-primary" disabled={virtualServer.jirakey === "INVALID"} onClick={() => createJiraTicket()}>Create</button>
                              {virtualServer.jirakey === "INVALID" && <p style={{ textAlign: "center" }}>Must be assigned to a migration group.</p>}
                            </div>
                          }
                        </div>
                      </div>
                      }
                      <div className={`col-sm-6 ${virtualServer.discovered.length > 35 ? "col-md-6" : "col-md-3"}`}>
                        <div className="form-group">
                          <label className="form-label">Discovered</label>
                          <input id="discovered" className="form-control" type="text" name="discovered" placeholder="Discovered" value={virtualServer.discovered} readOnly />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label" style={{ marginBottom: "12px" }}>Tags</label>
                          <CreatableSelect id="tags" name="tags" isMulti onChange={(e) => {
                            /* istanbul ignore next */
                            handleOnChange(e, "tags");
                          }} value={virtualServer.tags} components={{ DropdownIndicator: null }} placeholder="Tags" />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Notes</label>
                          <textarea id="notes" className="form-control" rows={5} type="text" name="notes" placeholder="Notes" value={virtualServer.notes} onChange={(e) => handleOnChange(e.target.value, "notes")} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Merge Strategy</label>
                          {["Global", "Discovery", "Manual"].map((mergeStrategy) => {
                            return (
                              <div key={mergeStrategy} className="radio col-sm-6 col-md-12">
                                <input
                                  className="form-control"
                                  id={`mergestrategy-${mergeStrategy.toLowerCase()}`}
                                  type="radio"
                                  value={mergeStrategy}
                                  checked={virtualServer.mergestrategy === mergeStrategy}
                                  onChange={() => handleOnChange(mergeStrategy, "mergestrategy")}
                                  name={mergeStrategy.toLowerCase()}
                                  disabled={["Global", "Discovery"].includes(mergeStrategy) && virtualServer.discovered === "Manual"}
                                />
                                <label htmlFor={`mergestrategy-${mergeStrategy.toLowerCase()}`}>{mergeStrategy}</label>
                                {mergeStrategy === "Global" &&
                                  <p style={{ width: "100%", color: "grey" }}>Merge according to the global setting set by administrators.</p>
                                }

                                {mergeStrategy === "Discovery" &&
                                  <p style={{ width: "100%", color: "grey" }}>Allow new discovery data to override these values.</p>
                                }

                                {mergeStrategy === "Manual" &&
                                  <p style={{ width: "100%", color: "grey" }}>Override discovery data with these values.</p>
                                }
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <Link id="back" to={"/discovery/virtual-servers"}><button className="btn btn-primary text-left" type="submit">Back</button></Link>
                    <button id="submit" className="btn btn-primary float-right" onClick={() => handleOnSubmit()} type="submit">{id ? "Update" : "Create"}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default VirtualServerEdit;




