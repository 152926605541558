import React, { Fragment } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import DataTable from "react-data-table-component";
import axios from "axios";
import FilterComponent from "../../components/common/FilterComponent";
import "react-dropzone-uploader/dist/styles.css";
import { tableStyles } from "../../components/common/CustomStyles";
import { setToast } from "../../components/common/Toast";
import { toast } from "react-toastify";

const Roles = () => {
  const [roles, setRoles] = React.useState([]);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [pending, setPending] = React.useState(true);

  const getRoles = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/roles`)
      .then((response) => {
        setRoles(response.data);
        setPending(false);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve roles");
      });
  };

  React.useEffect(() => {
    getRoles();
  }, []);

  const filteredItems = roles.filter(
    (item) => item.roleName && item.roleName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <div>
          <button id="create-role" className="btn btn-primary" onClick={() => window.location.assign("/admin/roles/edit")} type="button">Create Role</button>
        </div>
        <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholder={"Roles"} />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const deleteRole = (role) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/admin/roles/${role._id}/delete`, {
      roleId: role.roleId
    })
      .then(() => {
        setToast(toast.success, "Role deleted");
        setPending(true);
        getRoles();
      })
      .catch(() => {
        setToast(toast.error, "Unable to delete role");
      });
  };

  /* istanbul ignore next */
  const columns = [
    {
      name: "Role Name",
      selector: (role) => role.roleName,
      center: true,
      sortable: true,
      wrap: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Sections",
      selector: (role) => role.sections,
      center: true,
      sortable: true,
      wrap: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) => {
        return (
          <ul style={{ textAlign: "center" }}>
            {data.sections.map((section) => {
              return (
                <li key={section}>{section}</li>
              );
            })}
          </ul>
        );
      }
    },
    {
      name: "Users",
      selector: (role) => role.users,
      center: true,
      sortable: true,
      wrap: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) => {
        return (
          <ul style={{ textAlign: "center" }}>
            {data.users.map((user) => {
              return (
                <li key={user.id}>{user.name}</li>
              );
            })}
          </ul>
        );
      }
    },
    {
      name: "Applications",
      selector: (role) => role.applications,
      center: true,
      sortable: true,
      wrap: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) => {
        return (
          <ul style={{ textAlign: "center" }}>
            {data.applications.map((app) => {
              return (
                <li key={app.id}>{app.label}</li>
              );
            })}
          </ul>
        );
      }
    },
    {
      name: "Actions",
      center: true,
      width: "13rem",
      cell: (data) => <div>
        <button id={`edit-${data._id}`} className="btn btn-primary" style={{ margin: "5px 0px 5px 0px", width: "175px" }} onClick={() => window.location.assign(`/admin/roles/edit/${data._id}`)}>Edit</button>
        <button id={`delete-${data._id}`} className="btn btn-primary" style={{ margin: "5px 0px 5px 0px", width: "175px" }} onClick={() => deleteRole(data)} disabled={data.roleName === "Administrators" || data.roleName === "Everyone"}>Delete</button>
      </div>
    }
  ];

  return (
    <Fragment>
      <Breadcrumb title="Roles" parent="Admin" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{"Table of Roles"}</h5>
              </div>
              <div className="card-body datatable-react">
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  customStyles={tableStyles}
                  striped={true}
                  center={true}
                  subHeaderComponent={subHeaderComponentMemo}
                  subHeader
                  persistTableHead
                  pagination
                  responsive
                  progressPending={pending}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Roles;




