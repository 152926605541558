import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import StepZilla from "react-stepzilla";
import ConfigureAccount from "./ConfigureAccount";
import ConfigureSso from "./ConfigureSso";
import ConfigureTerraform from "./ConfigureTerraform";
import Review from "./Review";
import { useParams } from "react-router";
import axios from "axios";

// Default values
const defaultAccountEmail = "example-account@lz.datahubnow.com";
const defaultOu = "Sandbox";
const defaultOuId = "ou-vzz4-ul2c46fk";
const defaultSsoFirstName = "Simon";
const defaultSsoLastName = "Rees";
const defaultTerraformAction = "apply";

const OrderVendAccount = () => {  
  const { id } = useParams();
  const [accountName, setAccountName] = useState("");
  const [accountEmail, setAccountEmail] = useState(defaultAccountEmail);
  const [accountUser, setAccountUser] = useState("");
  const [ou, setOu] = useState(defaultOu);
  const [ouId, setOuId] = useState(defaultOuId);
  const [ssoEmail, setSsoEmail] = useState("");
  const [ssoFirstName, setSsoFirstName] = useState(defaultSsoFirstName);
  const [ssoLastName, setSsoLastName] = useState(defaultSsoLastName);
  const [orderName, setOrderName] = useState("");
  const [changeReason, setChangeReason] = useState("");
  const [action, setAction] = useState("");
  const [terraformAction, setTerraformAction] = useState(defaultTerraformAction);
  const [gitlab, setGitlab] = useState(false);
  const [awx, setAwx] = useState(false);
  const [harbor, setHarbor] = useState(false);
  const [sonarqube, setSonarqube] = useState(false);
  const [optionalPackages, setOptionalPackages] = useState([]);

  useEffect(() => {
    if (id) {
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/orders/get-vend-account-order/${id}`)
        .then((res) => {
          setAccountName(res.data.order.accountName);
          setAccountEmail(res.data.order.accountEmail);
          setAccountUser(res.data.order.accountUser);
          setOu(res.data.order.ou);
          setOuId(res.data.order.ouId);
          setSsoEmail(res.data.order.ssoEmail);
          setSsoFirstName(res.data.order.ssoFirstName);
          setSsoLastName(res.data.order.ssoLastName);
          setOrderName(res.data.name);
          setAction(res.data.action);
        })
        .catch((err) => {
          console.log(err);
          window.location = "/infrastructure/aws/vend-account";
        });
    }
  }, [id]);

  const steps =
    [
      {
        name: "Configure Account",
        component: <ConfigureAccount
          accountName={accountName}
          setAccountName={setAccountName}
          accountEmail={accountEmail}
          setAccountEmail={setAccountEmail}
          accountUser={accountUser}
          setAccountUser={setAccountUser}
          ssoEmail={ssoEmail}
          setSsoEmail={setSsoEmail}
          // guardrails={guardrails}
          // setGuardrails={setGuardrails}
          ou={ou}
          setOu={setOu}
          ouId={ouId}
          setOuId={setOuId}
        />
      },
      {
        name: "Configure SSO",
        component: <ConfigureSso
          accountName={accountName}
          ssoEmail={ssoEmail}
          setSsoEmail={setSsoEmail}
          ssoFirstName={ssoFirstName}
          setSsoFirstName={setSsoFirstName}
          ssoLastName={ssoLastName}
          setSsoLastName={setSsoLastName}
        />
      },
      {
        name: "Configure Terraform",
        component: <ConfigureTerraform
          terraformAction={terraformAction}
          setTerraformAction={setTerraformAction}
          gitlab={gitlab}
          setGitlab={setGitlab}
          awx={awx}
          setAwx={setAwx}
          harbor={harbor}
          setHarbor={setHarbor}
          sonarqube={sonarqube}
          setSonarqube={setSonarqube}
          optionalPackages={optionalPackages}
          setOptionalPackages={setOptionalPackages}
        />
      },
      {
        name: "Review",
        component: <Review
          accountName={accountName}
          accountEmail={accountEmail}
          accountUser={accountUser}
          ou={ou}
          ouId={ouId}
          ssoEmail={ssoEmail}
          ssoFirstName={ssoFirstName}
          ssoLastName={ssoLastName}
          // guardrails={guardrails}
          orderName={orderName}
          setOrderName={setOrderName}
          changeReason={changeReason}
          setChangeReason={setChangeReason}
          action={action}
          setAction={setAction}
          id={id}
          terraformAction={terraformAction}
          gitlab={gitlab}
          awx={awx}
          harbor={harbor}
          sonarqube={sonarqube}
          optionalPackages={optionalPackages}
          setOptionalPackages={setOptionalPackages}
        />
      }
    ];

  return (
    <Fragment>
      <Breadcrumb title="Order" parent="Vend AWS Account" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Provision AWS Account</h5>
              </div>
              <div className="card-body">
                <StepZilla
                  steps={steps}
                  showSteps={true}
                  showNavigation={true}
                  stepsNavigation={true}
                  prevBtnOnLastStep={true}
                  dontValidate={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OrderVendAccount;