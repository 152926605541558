import React from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import Links from "../../components/common/Links";

const DataBus = () => {
  return (
    <div>
      <Breadcrumb title="Data Bus" parent="Data" />
      <Links pathname={window.location.pathname} />
    </div>
  );
};
export default DataBus;
