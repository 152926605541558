import React, { Fragment } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import DataTable from "react-data-table-component";
import axios from "axios";
import "react-dropzone-uploader/dist/styles.css";
import FilterComponent from "../../components/common/FilterComponent";
import { FileText } from "react-feather";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { setToast } from "../../components/common/Toast";
import { tableStyles } from "../../components/common/CustomStyles";
import AssessmentResultModal from "./AssessmentResultModal";

const AssementResult = () => {
  const [assessmentResults, setAssessmentResults] = React.useState([]);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [openResult, setOpenResult] = React.useState(false);
  const [selectedAssessment, setSelectedAssessment] = React.useState({});
  const [selectedAssessmentResult, setSelectedAssessmentResult] = React.useState({});

  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/sustainability/get-all-completed-assessments`)
      .then((response) => {
        setAssessmentResults(response.data);
      }).catch(() => {
        setToast(toast.error, "Unable to retrieve completed assessments");
      });
  }, []);

  const onOpenModalResult = (data) => {
    setOpenResult(true);
    const filteredItems = assessmentResults.filter(
      (item) => item._id && item._id.toLowerCase().includes(data._id));
    setSelectedAssessmentResult(filteredItems[0].assessmentResults);
    setSelectedAssessment(filteredItems);
  };

  const filteredItems = assessmentResults.filter(
    (item) => item.assessmentName && item.assessmentName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholder={"Name"} />
    );
  }, [filterText, resetPaginationToggle]);

  /* istanbul ignore next */
  const columns = [
    {
      name: "Assessment Name",
      selector: (sustainability) => sustainability.assessmentName,
      center: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Assessed By",
      selector: (sustainability) => sustainability.user,
      center: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "CO2 Total",
      selector: (sustainability) => sustainability.totalCO2,
      center: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    }, {
      name: "CO2e Total",
      selector: (sustainability) => sustainability.totalCO2e,
      center: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Report",
      center: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) => <button id={`report-${data._id}`} className="btn btn-primary" onClick={() => onOpenModalResult(data)} ><FileText /></button>
    },
    {
      name: "Edit",
      selector: (database) => database.edit,
      center: true,
      sortable: true,
      wrap: true,
      width: "10rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) => <Link to={`/sustainability/sustainability-assessment/edit/${data._id}`}><button className="btn btn-primary">Edit</button></Link>
    }
  ];

  return (
    <Fragment>
      <Breadcrumb title="Assessment Results" parent="Table" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{"Sustainability Assessments"}</h5>
              </div>
              <div className="card-body datatable-react">
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  customStyles={tableStyles}
                  striped={true}
                  center={true}
                  subHeaderComponent={subHeaderComponentMemo}
                  subHeader
                  persistTableHead
                  pagination
                  responsive
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AssessmentResultModal
        openResult={openResult}
        setOpenResult={setOpenResult}
        selectedAssessmentResult={selectedAssessmentResult}
        selectedAssessment={selectedAssessment}
      />
    </Fragment>
  );
};
export default AssementResult;




