import {
  Home, Box, DollarSign, Airplay, FolderPlus, FileText, Server,
  Image, Database, Headphones, ShoppingBag, Package, Lock, Settings
} from "react-feather";

export const MENUITEMS = [
  {
    title: "Dashboard",
    section: "Dashboard",
    path: "/",
    icon: Home,
    type: "sub",
    badgeType: "primary",
    active: false,
    children: [
      {
        title: "Project",
        type: "link",
        section: "Dashboard",
        path: "/dashboard/project"
      },
      {
        title: "Migration Groups",
        path: "/dashboard/migration-groups",
        section: "Dashboard",
        type: "link"
      }
    ]
  },
  {
    title: "Discovery",
    icon: Server,
    section: "Discovery",
    type: "sub",
    children: [
      {
        title: "Tools",
        type: "link",
        section: "Discovery",
        path: "/discovery/tools"
      },
      {
        title: "Business Applications",
        path: "/discovery/business-applications",
        section: "Discovery",
        type: "link"
      },
      {
        title: "Application Components",
        path: "/discovery/application-components",
        section: "Discovery",
        type: "link"
      },
      {
        title: "Virtual Servers",
        path: "/discovery/virtual-servers",
        section: "Discovery",
        type: "link"
      },
      {
        title: "Physical Servers",
        path: "/discovery/physical-servers",
        section: "Discovery",
        type: "link"
      },
      {
        title: "Databases",
        path: "/discovery/databases",
        section: "Discovery",
        type: "link"
      },
      {
        title: "Kubernetes",
        path: "/discovery/kubernetes",
        section: "Discovery",
        type: "link"
      },
      {
        title: "Topology",
        path: "/discovery/topology",
        section: "Discovery",
        type: "link"
      }
    ]
  },
  {
    title: "Assessments",
    icon: Home,
    type: "sub",
    badgeType: "primary",
    section: "Assessments",
    active: false,
    children: [
      {
        path: "/application-assessment",
        title: "Application Assessment",
        section: "Assessments",
        type: "link"
      },
      {
        path: "/server-assessment",
        title: "Server Assessment",
        section: "Assessments",
        type: "link"
      },
      {
        path: "/database-assessment",
        title: "Database Assessment",
        section: "Assessments",
        type: "link"
      }
    ]
  },
  {
    title: "Enterprise Architecture",
    section: "Enterprise Architecture",
    icon: DollarSign,
    path: "/enterprise-architecture",
    type: "link",
    active: false
  },
  {
    title: "Database Factory",
    section: "Database Factory",
    icon: Box,
    type: "sub",
    active: false,
    children: [
      {
        path: "/database-factory/oracle-to-oracle",
        section: "Database Factory",
        title: "Oracle to Oracle",
        type: "link"
      },
      {
        path: "/database-factory/postgres-to-postgres",
        section: "Database Factory",
        title: "Postgres to Postgres",
        type: "link"
      },
      {
        path: "/database-factory/oracle-to-postgresql",
        section: "Database Factory",
        title: "Oracle to PostgreSQL",
        type: "link"
      },
      {
        path: "/database-factory/mongodb",
        section: "Database Factory",
        title: "MongoDB",
        type: "link"
      },
      {
        path: "/database-factory/workflow-tool",
        section: "Database Factory",
        title: "Workflow Tool",
        type: "link"
      }
    ]
  },
  {
    title: "Application Migration",
    section: "Application Migration",
    icon: Box,
    type: "sub",
    active: false,
    children: [
      {
        path: "/application-migration/source-servers",
        section: "Application Migration",
        title: "Source Servers",
        type: "link"
      }
    ]
  },
  {
    title: "Service",
    section: "Service",
    icon: Box,
    type: "sub",
    active: false,
    children: [
      {
        path: "/service/service-now",
        section: "Service",
        title: "Service Now",
        type: "link"
      }
    ]
  },
  {
    title: "Kanban Board",
    section: "Kanban Board",
    icon: Package,
    type: "link",
    path: "/kanban-board",
    active: false
  },
  {
    title: "APIs",
    section: "APIs",
    icon: FolderPlus,
    type: "sub",
    active: false,
    children: [
      {
        path: "/apis/kong",
        section: "APIs",
        title: "Kong",
        type: "link"
      },
      {
        path: "/apis/oracle-web-app",
        section: "APIs",
        title: "Oracle Web Application",
        type: "link"
      }
    ]
  },
  {
    title: "Repository",
    section: "Repository",
    icon: Image,
    type: "link",
    path: "/repository",
    active: false
  },
  {
    title: "Catalogue",
    section: "Catalogue",
    icon: ShoppingBag,
    type: "sub",
    active: false,
    children: [
      {
        path: "/catalogue/db-migrations",
        section: "Catalogue",
        title: "Products",
        type: "link"
      },
      {
        path: "/catalogue/gitlab-trigger",
        section: "Catalogue",
        title: "GitLab Trigger",
        type: "link"
      },
      {
        path: "/catalogue/product-list",
        title: "Product List",
        section: "Catalogue",
        type: "link"
      }
    ]
  },
  {
    title: "Infrastructure",
    section: "Infrastructure",
    icon: FileText,
    type: "sub",
    active: false,
    children: [
      {
        title: "Azure",
        section: "Infrastructure",
        type: "sub",
        children: [
          {
            path: "/infrastructure/azr/azrvm-build",
            title: "Azure VM Build",
            section: "Infrastructure",
            type: "link"
          },
          {
            path: "/infrastructure/azr/azuredevops-azr-vm",
            title: "AzureDevOps VM",
            section: "Infrastructure",
            type: "link"
          },
          {
            path: "/infrastructure/azr/azuredevops-azr-postgres",
            title: "AzureDevOps Postgres",
            section: "Infrastructure",
            type: "link"
          }
        ]
      },
      {
        title: "AWS ",
        section: "Infrastructure",
        type: "sub",
        children: [
          {
            path: "/infrastructure/aws/aws-console",
            section: "Infrastructure",
            title: "AWS Console",
            type: "link"
          },
          {
            path: "/infrastructure/aws/ec2-build",
            section: "Infrastructure",
            title: "EC2 Build",
            type: "link"
          },
          {
            path: "/infrastructure/aws/vend-account",
            section: "Infrastructure",
            title: "Vend Account",
            type: "link"
          }
        ]
      },
      {
        title: "Google",
        section: "Infrastructure",
        type: "sub",
        children: [
          {
            path: "/infrastructure/google/gcp-console",
            section: "Infrastructure",
            title: "GCP Console",
            type: "link"
          },
          {
            path: "/infrastructure/google/iac-build",
            section: "Infrastructure",
            title: "VM Build",
            type: "link"
          },
          {
            path: "/infrastructure/google/azuredevops-gcp-vm",
            section: "Infrastructure",
            title: "VM Build (Azure DevOps)",
            type: "link"
          },
          {
            path: "/infrastructure/google/cloudsql-build",
            title: "CloudSQL Build",
            section: "Infrastructure",
            type: "link"
          },
          {
            path: "/infrastructure/google/packaged-deployment",
            title: "Packaged Deployment",
            section: "Infrastructure",
            type: "link"
          }
        ]
      },
      {
        title: "Alibaba Cloud",
        type: "sub",
        section: "Infrastructure",
        children: [
          {
            path: "/infrastructure/alibaba",
            section: "Infrastructure",
            title: "Alibaba Console",
            type: "link"
          }
        ]
      },
      {
        title: "Oracle",
        type: "sub",
        section: "Infrastructure",
        children: [
          {
            path: "/infrastructure/oracle/oci-database",
            section: "Infrastructure",
            title: "Oracle Database",
            type: "link"
          }
        ]
      }
    ]
  },
  {
    title: "Secrets",
    section: "Secrets",
    icon: Lock,
    type: "sub",
    children: [
      {
        title: "Vault",
        path: "/secrets/vault",
        section: "Secrets",
        type: "link"
      },
      {
        title: "Add Secret",
        path: "/secrets/add-secret",
        section: "Secrets",
        type: "link"
      }
    ]
  },
  {
    title: "Sustainability",
    icon: Server,
    section: "Sustainability",
    type: "sub",
    children: [
      {
        title: "Recommendation",
        path: "/sustainability/recommendations",
        section: "Sustainability",
        type: "link"
      },
      {
        title: "Carbon Footprint",
        path: "/sustainability/carbon-footprint",
        section: "Sustainability",
        type: "link"
      },
      {
        title: "Server Power Ratings",
        path: "/sustainability/server-power-ratings",
        section: "Sustainability",
        type: "link"
      },
      {
        title: "Server Specs",
        path: "/sustainability/server-specs",
        section: "Sustainability",
        type: "link"
      },
      {
        title: "Factors Repository",
        path: "/sustainability/factors-repository",
        section: "Sustainability",
        type: "link"
      },
      {
        title: "Assessment",
        path: "/sustainability/sustainability-assessment",
        section: "Sustainability",
        type: "link"
      },
      {
        title: "Sustainability Result",
        path: "/sustainability/sustainability-result",
        section: "Sustainability",
        type: "link"
      }
    ]
  },
  {
    title: "Data",
    icon: Airplay,
    type: "sub",
    section: "Data",
    children: [
      {
        title: "Data Virtualisation",
        path: "/data/data-virtualisation",
        section: "Data",
        type: "link"
      },
      {
        title: "Data Bus",
        path: "/data/data-bus",
        section: "Data",
        type: "link"
      }
    ]
  },
  {
    title: "Knowledgebase",
    section: "Knowledgebase",
    icon: Database,
    type: "link",
    path: "/knowledgebase",
    active: false
  },
  {
    title: "File Manager",
    section: "File Manager",
    icon: FileText,
    type: "link",
    path: "/file-manager",
    active: false
  },
  {
    title: "Support Ticket",
    section: "Support Ticket",
    icon: Headphones,
    type: "link",
    path: "/support-ticket",
    active: false
  },
  {
    title: "Admin",
    section: "Admin",
    icon: Settings,
    type: "sub",
    children: [
      {
        title: "Roles",
        section: "Admin",
        path: "/admin/roles",
        type: "link"
      },
      {
        title: "Links",
        section: "Admin",
        path: "/admin/links",
        type: "link"
      },
      {
        path: "/admin/assessments/create",
        section: "Admin",
        title: "Create Assessment",
        type: "link"
      },
      {
        path: "/admin/globals",
        section: "Admin",
        title: "Globals",
        type: "link"
      }
    ]
  }
];