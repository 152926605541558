import React from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import ReactFlow, { MiniMap, Controls, Background, useNodesState, useEdgesState } from "react-flow-renderer";
import { Truck, Grid, Layers, Cloud, Server, Database, PlusSquare, RefreshCw, Box } from "react-feather";
import axios from "axios";
import { setToast } from "../../../components/common/Toast";
import { toast } from "react-toastify";
import dagre from "dagre";
import { Link } from "react-router-dom";
import { Container, Card, CardBody } from "reactstrap";

const nodeWidth = 200;
const nodeHeight = 200;

const Topology = () => {
  const [migrationGroups, setMigrationGroups] = React.useState([]);
  const [migrationGroupIndex, setMigrationGroupIndex] = React.useState(-1);
  const [nodes, setNodes, onNodesChange] = useNodesState();
  const [edges, setEdges, onEdgesChange] = useEdgesState();
  const [position, setPosition] = React.useState({ x: 0, y: 0 });
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const getTopology = () => {
    setLoading(true);

    if (migrationGroups.length > 0 && migrationGroupIndex >= 0) {
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/topology/${migrationGroups[migrationGroupIndex]._id}`)
        .then((res) => {
          let nodesResponse = res.data.nodes.map((nodeGroup, index) => {
            return nodeGroup.map((node) => {
              return {
                ...node,
                position: { x: 0, y: 0 },
                style: {
                  backgroundColor: "#f6f6f6"
                },
                data: {
                  label: <div style={{ flexDirection: "column", alignItems: "center" }}>
                    <Link to={node.url} style={{ cursor: "pointer" }}>
                      <p style={{ wordWrap: "break-word", color: "#2a2a2a" }}>{node.name}</p>
                      {index === 0 ?
                        <Truck style={{ color: "#2a2a2a", marginBottom: "10px" }} />
                        : index === 1 ?
                          <Grid style={{ color: "#2a2a2a", marginBottom: "10px" }} />
                          : index === 2 ?
                            <Layers style={{ color: "#2a2a2a", marginBottom: "10px" }} />
                            : index === 3 ?
                              node.category === "database" ?
                                <Database style={{ color: "#2a2a2a", marginBottom: "10px" }} />
                                : node.category === "virtualserver" ?
                                  <Cloud style={{ color: "#2a2a2a", marginBottom: "10px" }} />
                                  : node.category === "physicalserver" ?
                                    <Server style={{ color: "#2a2a2a", marginBottom: "10px" }} />
                                    : node.category === "kubernetes" ?
                                      <Box style={{ color: "#2a2a2a", marginBottom: "10px" }} />
                                      : null
                              : null
                      }
                    </Link>
                    {node.jirakey && <p onClick={() => window.open(`${res.data.nodes[0][0].jiradomain}/browse/${node.jirakey}`, "_blank")} style={{ wordWrap: "break-word", color: "#2a2a2a", textDecoration: "underline", cursor: "pointer" }}>View Ticket</p>}
                  </div>
                }
              };
            });
          });

          const edgesResponse = res.data.edges;

          nodesResponse = nodesResponse.flat();

          const dagreGraph = new dagre.graphlib.Graph();
          dagreGraph.setDefaultEdgeLabel(() => ({}));
          dagreGraph.setGraph({ rankdir: "TB" });

          nodesResponse.forEach((node) => {
            dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
          });

          edgesResponse.forEach((edge) => {
            dagreGraph.setEdge(edge.source, edge.target);
          });

          dagre.layout(dagreGraph);

          nodesResponse.forEach((node) => {
            const nodeWithPosition = dagreGraph.node(node.id);
            node.targetPosition = "top";
            node.sourcePosition = "bottom";


            node.position = {
              x: nodeWithPosition.x - nodeWidth / 2,
              y: node.type === "output" ? 750 : nodeWithPosition.y - nodeHeight / 2
            };

            return node;
          });

          setNodes(nodesResponse);
          setEdges(edgesResponse);
          setLoading(false);
        })
        .catch(() => {
          setToast(toast.error, "Unable to retrieve topology");
          setLoading(false);
        });
    }
  };

  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/migration-groups`)
      .then((res) => {
        setMigrationGroups(res.data.migrationGroups);
        setMigrationGroupIndex(0);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve migration groups");
      });
  }, []);

  React.useEffect(() => {
    getTopology();
  }, [migrationGroups, migrationGroupIndex]);

  const onContextMenu = (e) => {
    e.preventDefault();
    console.log(e.clientX);
    setPosition({ x: e.clientX, y: e.clientY });
    setOpen(true);
  };

  return (
    <div>
      <Breadcrumb title="Topology" parent="Discovery" />
      <div className="tab-content active default" id="tab-1">
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <select style={{ width: "300px", marginLeft: "10px", marginRight: "10px" }} id="migrationgroups" className="form-control" name="migrationgroups" value={migrationGroupIndex} onChange={(e) => setMigrationGroupIndex(e.target.value)}>
              {migrationGroups.map((migrationGroup, index) => {
                return (
                  <option key={index} value={index}>{migrationGroup.name}</option>
                );
              })}
            </select>
            <button className="btn btn-primary" onClick={getTopology} style={{ height: "36px", marginTop: "5px", marginBottom: "10px" }}>
              <RefreshCw className={`refresh-${loading ? "start" : "end"}`} disabled={loading}/>
            </button>
          </div>
        </div>
      </div>
      {nodes && edges ?
        <Container fluid={true}>
          <Card style={{ marginBottom: "0px", borderRadius: "15px 15px 0px 0px" }}>
            <CardBody style={{ padding: "15px" }}>
              <h4 style={{ textAlign: "center" }}>Key</h4>

              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <Truck />
                  <p>Migration Group</p>
                </div>

                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <Grid />
                  <p>Business Application</p>
                </div>

                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <Layers />
                  <p>Application Component</p>
                </div>

                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <Database />
                  <p>Database</p>
                </div>

                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <Server />
                  <p>Physical Server</p>
                </div>

                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <Cloud />
                  <p>Virtual Server</p>
                </div>

                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <Box />
                  <p>Kubernetes</p>
                </div>
              </div>
            </CardBody>
          </Card>
          <ReactFlow
            style={{ height: "70vh", width: "100%", borderRadius: "0px 0px 15px 15px" }}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            fitView
            nodesConnectable={false}
            onContextMenu={onContextMenu}
            onClick={() => setOpen(false)}
          >
            {open && <div className="context-menu" style={{ left: `calc(${position.x}px - 280px)`, top: `calc(${position.y}px - 215px)` }} onClick={() => setOpen(false)}>
              <button onClick={() => window.location.assign("/discovery/business-applications/add")}>
                <PlusSquare className="context-menu-icon" />
                Business Application
              </button>
              <button onClick={() => window.location.assign("/discovery/application-components/add")}>
                <PlusSquare className="context-menu-icon" />
                Application Component
              </button>
              <button onClick={() => window.location.assign("/infrastructure/google/iac-build")}>
                <PlusSquare className="context-menu-icon" />
                GCP VM
              </button>
              <button onClick={() => window.location.assign("/infrastructure/aws/ec2-build")}>
                <PlusSquare className="context-menu-icon" />
                AWS VM
              </button>
              <button onClick={() => window.location.assign("/infrastructure/azr/azrvm-build")}>
                <PlusSquare className="context-menu-icon" />
                Azure VM
              </button>
              <button onClick={() => window.location.assign("/infrastructure/google/azuredevops-gcp-vm")}>
                <PlusSquare className="context-menu-icon" />
                Azure DevOps GCP VM
              </button>
              <button onClick={() => window.location.assign("/infrastructure/google/cloudsql-build")}>
                <PlusSquare className="context-menu-icon" />
                GCP CloudSQL
              </button>
            </div>
            }
            <Background variant="lines" gap={12} size={4} />
            <MiniMap />
            <Controls />
          </ReactFlow>
        </Container>
        :
        <div id="error" className="col-xl-3 col-sm-6">
          <p>Loading...</p>
        </div>
      }
    </div>
  );
};
export default Topology;
