import React, { Fragment } from "react";
import Header from "./common/header-component/header";
import Sidebar from "./common/sidebar-component/sidebar";
import Footer from "./common/footer";
import { ToastContainer } from "react-toastify";
import Loader from "./common/Loader";
import axios from "axios";
import { useAuthUser } from "../authUserContext";

const AppLayout = ({ children }) => {
  const { authUser } = useAuthUser();

  if (authUser) {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${authUser.accessToken}`
    };
  }

  return (
    <Fragment>
      <Loader />
      <div className="page-wrapper">
        <div className="page-body-wrapper">
          <Header />
          <Sidebar />
          <div className="page-body">
            {children}
          </div>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default AppLayout;