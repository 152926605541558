import React, { Fragment } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import DataTable from "react-data-table-component";
import axios from "axios";
import FilterComponent from "../../../components/common/FilterComponent";
import "react-dropzone-uploader/dist/styles.css";
import { toast } from "react-toastify";
import { setToast } from "../../../components/common/Toast";
import { tableStyles } from "../../../components/common/CustomStyles";
import { Edit, ExternalLink, Trash } from "react-feather";
import { Link } from "react-router-dom";

const MigrationGroupsList = () => {
  const [migrationGroups, setMigrationGroups] = React.useState([]);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [jiradomain, setJiradomain] = React.useState(undefined);

  const getMigrationGroups = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/migration-groups`)
      .then(async (response) => {
        setJiradomain(response.data.jiradomain);

        for (const migrationGroup of response.data.migrationGroups) {
          const businessApps = [];
          for await (let businessApplication of migrationGroup.businessapplications) {
            await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/business-applications/${businessApplication}`)
              .then((response) => {
                businessApps.push({
                  _id: response.data._id,
                  application: response.data.application
                });
              }).catch(() => {
                setToast(toast.error, "Unable to retrieve business application");
              });
          }

          migrationGroup.businessapplications = businessApps;
        }

        setMigrationGroups(response.data.migrationGroups);
      }).catch(() => {
        setToast(toast.error, "Unable to retrieve migration groups");
      });
  };

  React.useEffect(() => {
    getMigrationGroups();
  }, []);

  const deleteMigrationGroup = (data) => {
    if (window.confirm("Are you sure you want to delete this migration group?")) {
      axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/migration-groups/${data._id}`)
        .then(() => {
          setToast(toast.success, "Migration group deleted");
          getMigrationGroups();
        }).catch(() => {
          setToast(toast.error, "Unable to delete migration group");
        });
    }
  };

  const filteredItems = migrationGroups.filter(
    (item) => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText !== "") {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <div>
          <button id="create-migration-group" className="btn btn-primary" onClick={() => window.location.assign("/dashboard/migration-groups/add")} type="button">Create Migration Group</button>
        </div>
        <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholder={"Migration Group"} />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  /* istanbul ignore next */
  const columns = [
    {
      name: "Name",
      selector: (database) => database.name,
      center: true,
      sortable: true,
      wrap: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Actions",
      center: true,
      sortable: true,
      wrap: true,
      width: "7rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) =>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <button id={`edit-${data._id}`} className="btn btn-pill" style={{ margin: "5px 0px 5px 0px", backgroundColor: "#22af47" }} onClick={() => window.location.assign(`/dashboard/migration-groups/edit/${data._id}`)}>
            <Edit style={{ height: "15px", width: "auto", color: "white" }} />
          </button>
          <button id={`delete-${data._id}`} className="btn btn-pill" style={{ margin: "5px 0px 5px 0px", backgroundColor: "#e83845" }} onClick={() => deleteMigrationGroup(data)}>
            <Trash style={{ height: "15px", width: "auto", color: "white" }} />
          </button>
        </div>
    },
    {
      name: "Description",
      selector: (database) => database.description,
      center: true,
      sortable: true,
      wrap: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Jira",
      center: true,
      sortable: true,
      wrap: true,
      width: "7rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) => <button id={`jira-${data._id}`} className="btn btn-pill" style={{ margin: "5px 0px 5px 0px", backgroundColor: "#007bff" }} onClick={() => window.open(`${jiradomain}/jira/core/projects/${data.jiraproject}/board`, "_blank")}>
        <ExternalLink style={{ height: "15px", width: "auto", color: "white" }} />
      </button>
    },
    {
      name: "Business Applications",
      selector: (database) => database.businessapplications,
      center: true,
      sortable: true,
      wrap: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) => {
        return (
          <ul style={{ textAlign: "center" }}>
            {data.businessapplications.map((businessApp) => {
              return (
                <li style={{ margin: "15px" }} key={businessApp._id}>
                  <Link to={`/discovery/business-applications/edit/${businessApp._id}`} style={{ color: "rgba(0,0,0,0.87)" }}>
                    {businessApp.application}
                  </Link>
                </li>
              );
            })}
          </ul>
        );
      }
    }
  ];

  return (
    <Fragment>
      <Breadcrumb title="Migration Groups" parent="Dashboard" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{"Table of Migration Groups"}</h5>
              </div>
              <div className="card-body datatable-react">
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  customStyles={tableStyles}
                  striped={true}
                  center={true}
                  subHeaderComponent={subHeaderComponentMemo}
                  subHeader
                  persistTableHead
                  pagination
                  responsive
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default MigrationGroupsList;




