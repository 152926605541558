import React, { Fragment, useEffect } from "react";
import Breadcrumb from "../../components/common/breadcrumb";


const KnowledgebaseComponent = () => {
  useEffect(() => {
    window.location.replace("https://knowledgebase.datahubnow.com");
  },[]);


  return (
    <Fragment>
      <Breadcrumb title="Knowledgebase" parent="Pages" />
    </Fragment>
  );
};

export default KnowledgebaseComponent;