import React, { Fragment } from "react";
import { Submit } from "../../../constant";
import axios from "axios";
import { toast } from "react-toastify";
import { setToast } from "../../../components/common/Toast";

const Review = (props) => {
  const submitForm = () => {
    let ip = "";
    let mount = "";
    let secret = {};

    props.database && props.database !== "" ?
      ip = props.database
      :
      ip = props.server;

    props.database && props.database !== "" ?
      mount = "database"
      :
      mount = "server";

    props.secretType === "PEM key" ?
      secret = {
        pemKey: props.pemSecret,
        username: props.username
      }
      :
      props.secretType === "Single input" ?
        secret = {
          singleSecret: props.singleSecret
        }
        :
        secret = {
          databaseUsername: props.databaseUsername,
          databasePassword: props.databasePassword,
          databaseType: props.databaseType,
          databasePort: props.databasePort,
          databaseName: props.databaseName
        };

    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/vault/add-secret`, {
      name: ip,
      type: props.secretType,
      secret: secret,
      mount: mount
    })
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        setToast(toast.error, "Unable to submit secret");
      });
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12">
          <table className="table table-bordered table-striped mb-3 mt-3">
            <thead>
              <tr>
                <th scope="col">Credential Choice</th>
                {props.database && props.database !== "" ?
                  <th scope="col">Database</th>
                  :
                  <th scope="col">Server</th>
                }

                <th scope="col">Secret Type</th>

                {props.pemName &&
                  <th scope="col">PEM Key</th>
                }

                {props.singleTypeSecret &&
                  <th scope="col">Secret</th>
                }

                {props.username &&
                  <th scope="col">Username</th>
                }

                {props.databaseUsername &&
                  <>
                    <th scope="col">Database Name</th>
                    <th scope="col">Database Type</th>
                    <th scope="col">Database Username</th>
                    <th scope="col">Database Password</th>
                    <th scope="col">Database Port</th>
                  </>
                }
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{props.credentialChoice}</td>
                {props.database && props.database !== "" ?
                  <td>{props.database}</td>
                  :
                  <td>{props.server}</td>
                }

                <td>{props.secretType}</td>

                {props.pemName &&
                  <>
                    <td>{props.pemName}</td>
                    <td>{props.username}</td>
                  </>
                }

                {props.singleSecret &&
                  <td>{props.singleSecret}</td>
                }
                
                {
                  props.databaseUsername &&
                  <>
                    <td>{props.databaseName}</td>
                    <td>{props.databaseType}</td>
                    <td>{props.databaseUsername}</td>
                    <td>{props.databasePassword}</td>
                    <td>{props.databasePort}</td>
                  </>
                }
              </tr>
            </tbody>
          </table>
          <button id="submitSecret" className="btn btn-primary r-7 btnsubmit" onClick={submitForm}>{Submit}</button>
        </div>
      </div>
    </Fragment>
  );
};

export default Review;