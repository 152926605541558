import React, { Fragment } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import axios from "axios";
import "react-dropzone-uploader/dist/styles.css";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { setToast } from "../../../components/common/Toast";
import { ExternalLink, Trash } from "react-feather";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

const DatabaseEdit = () => {
  const [database, setDatabase] = React.useState({
    vendor: "", name: "", sid: "",
    databaseversion: "", databaseedition: "", ip: "", port: "",
    clustertype: "", clusternodecount: "", backuptype: "",
    storage: "", multitenanttype: "", containerdatabase: "",
    bit: "", tablespaces: "", transformationphase: "",
    transformationstrategy: "", transformationgroup: "",
    priority: "", migrationpattern: "", tags: [], cloudlink: "",
    datadoglink: "", discovered: "", notes: "", jirakey: "INVALID",
    mergestrategy: "Global"
  });
  const [migrationPatterns, setMigrationPatterns] = React.useState([]);
  const [transformationgroups, setTransformationgroups] = React.useState([]);
  const [jiradomain, setJiraDomain] = React.useState(undefined);

  const { discovery, id } = useParams();

  const getDatabase = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/databases/get-database/${discovery}/${id}`)
      .then((response) => {
        /* istanbul ignore next */
        setDatabase({
          ...response.data.database,
          tags: response.data.database.tags ? response.data.database.tags.split(",").map((item) => {
            return {
              value: item,
              label: item
            };
          }) : []
        });
        /* istanbul ignore next */
        setJiraDomain(response.data.jiradomain);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve database");
      });
  };

  const getMigrationPatterns = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/databases/migration-patterns`)
      .then((response) => {
        setMigrationPatterns(response.data);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve migration patterns");
      });
  };

  const getTransformationGroups = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/migration-groups`)
      .then((response) => {
        const migGroups = response.data.migrationGroups.map((item) => {
          return {
            value: item._id,
            label: item.name
          };
        });

        setTransformationgroups([
          {
            value: "",
            label: "None"
          },
          ...migGroups
        ]);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve transformation groups");
      });
  };

  React.useEffect(() => {
    if (typeof database.transformationgroup === "string" && transformationgroups.length > 0) {
      setDatabase({
        ...database,
        transformationgroup: transformationgroups.find((item) => item.value === database.transformationgroup)
      });
    }
  }, [database, transformationgroups]);

  React.useEffect(() => {
    if (id && discovery) {
      getDatabase();
    } else {
      setDatabase({
        ...database,
        discovered: "Manual",
        mergestrategy: "Manual"
      });
    }

    getMigrationPatterns();
    getTransformationGroups();
  }, []);

  const handleOnChange = (value, name) => {
    setDatabase({ ...database, [name]: value });
  };

  const handleOnSubmit = () => {
    axios.post(id ? `${process.env.REACT_APP_BACKEND_URL}/api/databases/${discovery}/${id}` : `${process.env.REACT_APP_BACKEND_URL}/api/databases/${discovery}`, {
      database: {
        ...database,
        transformationgroup: database.transformationgroup.value,
        tags: database.tags.map((item) => item.value).join(",")
      }
    })
      .then(() => {
        window.location.assign("/discovery/databases");
      })
      .catch(() => {
        setToast(toast.error, `Unable to ${id ? "update" : "create"} database`);
      });
  };

  const createJiraTicket = () => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/jira`, {
      items: [database],
      type: "database",
      discoveryId: discovery
    })
      .then(() => {
        setToast(toast.success, "Jira ticket created");
        setTimeout(() => getDatabase(), 1000);
      })
      .catch(() => {
        setToast(toast.error, "Unable to create Jira ticket");
      });
  };

  const deleteJiraTicket = () => {
    axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/jira`, {
      data: {
        jirakey: database.jirakey,
        type: "database",
        discoveryId: discovery,
        databaseId: id
      }
    })
      .then(() => {
        setToast(toast.success, "Jira ticket deleted");
        setTimeout(() => getDatabase(), 1000);
      })
      .catch(() => {
        setToast(toast.error, "Unable to delete Jira ticket");
      });
  };

  return (
    <Fragment>
      <Breadcrumb title={id ? "Edit" : "Create"} parent="Database" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{id ? "Edit" : "Create"} Database Information</h5>
              </div>
              <div className="card-body datatable-react">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title mb-0">{`Database Information: ${database.name}`}</h4>
                    <div className="card-options">
                      <a className="card-options-collapse" href="javascript" data-toggle="card-collapse">
                        <i className="fe fe-chevron-up"></i>
                      </a>
                      <a className="card-options-remove" href="javascript" data-toggle="card-remove">
                        <i className="fe fe-x"></i>
                      </a>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className={`col-sm-6 ${database.name.length > 35 ? "col-md-6" : "col-md-3"}`}>
                        <div className="form-group">
                          <label className="form-label">Name</label>
                          {id ?
                            <input id="name" className="form-control" type="text" name="name" placeholder="Name" value={database.name} readOnly />
                            :
                            <input id="name" className="form-control" type="text" name="name" placeholder="Name" value={database.name} onChange={(e) => handleOnChange(e.target.value, "name")} />
                          }
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label">Vendor</label>
                          <select id="vendor" className="form-control" name="vendor" value={database.vendor} onChange={(e) => handleOnChange(e.target.value, "vendor")}>
                            <option value="" disabled={database.vendor !== ""}>Select Vendor</option>
                            <option value="Oracle">Oracle</option>
                            <option value="Sybase">Sybase</option>
                            <option value="MSSQL">MSSQL</option>
                            <option value="Postgres">Postgres</option>
                          </select>
                        </div>
                      </div>
                      <div className={`col-sm-6 ${database.sid.length > 35 ? "col-md-6" : "col-md-3"}`}>
                        <div className="form-group">
                          <label className="form-label">SID</label>
                          <input id="sid" className="form-control" type="text" name="sid" placeholder="SID" value={database.sid} onChange={(e) => handleOnChange(e.target.value, "sid")} />
                        </div>
                      </div>
                      <div className={`col-sm-6 ${database.databaseversion.length > 35 ? "col-md-6" : "col-md-3"}`}>
                        <div className="form-group">
                          <label className="form-label">Database Version</label>
                          <input id="databaseversion" className="form-control" type="text" name="databaseversion" placeholder="Database Version" value={database.databaseversion} onChange={(e) => handleOnChange(e.target.value, "databaseversion")} />
                        </div>
                      </div>
                      <div className={`col-sm-6 ${database.databaseedition.length > 35 ? "col-md-6" : "col-md-3"}`}>
                        <div className="form-group">
                          <label className="form-label">Database Edition</label>
                          <input id="databaseedition" className="form-control" type="text" name="databaseedition" placeholder="Database Edition" value={database.databaseedition} onChange={(e) => handleOnChange(e.target.value, "databaseedition")} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label">Port</label>
                          <input id="port" className="form-control" type="number" name="port" placeholder="Port" value={parseInt(database.port || "-1")} onChange={(e) => handleOnChange(e.target.value.toString(), "port")} />
                        </div>
                      </div>
                      <div className={`col-sm-6 ${database.ip.length > 35 ? "col-md-6" : "col-md-3"}`}>
                        <div className="form-group">
                          <label className="form-label">Host</label>
                          <input id="ip" className="form-control" type="text" name="ip" placeholder="Host" value={database.ip} onChange={(e) => handleOnChange(e.target.value, "ip")} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label">Cluster Type</label>
                          <select id="clustertype" className="form-control" name="clustertype" value={database.clustertype} onChange={(e) => handleOnChange(e.target.value, "clustertype")}>
                            <option value="" disabled={database.clustertype !== ""}>Select Cluster Type</option>
                            <option value="Non-RAC">Non-RAC</option>
                            <option value="RAC">RAC</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label">Cluster Node Count</label>
                          <input id="clusternodecount" className="form-control" type="number" name="clusternodecount" placeholder="Cluster Node Count" value={parseInt(database.clusternodecount || "-1")} onChange={(e) => handleOnChange(e.target.value.toString(), "clusternodecount")} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label">Backup Type</label>
                          <select id="backuptype" className="form-control" name="backuptype" value={database.backuptype} onChange={(e) => handleOnChange(e.target.value, "backuptype")}>
                            <option value="" disabled={database.backuptype !== ""}>Select Backup Type</option>
                            <option value="Non-RMAN">Non-RMAN</option>
                            <option value="RMAN">RMAN</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label">Storage (GB)</label>
                          <input id="storage" className="form-control" type="number" name="storage" placeholder="Storage" value={parseInt(database.storage || "-1")} onChange={(e) => handleOnChange(e.target.value.toString(), "storage")} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label">Multitenant Type</label>
                          <select id="multitenanttype" className="form-control" name="multitenanttype" value={database.multitenanttype} onChange={(e) => handleOnChange(e.target.value, "multitenanttype")}>
                            <option value="" disabled={database.multitenanttype !== ""}>Select Multitenant Type</option>
                            <option value="Container Database (CDB)">Container Database (CDB)</option>
                            <option value="Pluggable Database (PDB)">Pluggable Database (PDB)</option>
                          </select>
                        </div>
                      </div>
                      <div className={`col-sm-6 ${database.containerdatabase.length > 35 ? "col-md-6" : "col-md-3"}`}>
                        <div className="form-group">
                          <label className="form-label">Container Database</label>
                          <input id="containerdatabase" className="form-control" type="text" name="containerdatabase" placeholder="Container Database" value={database.containerdatabase} onChange={(e) => handleOnChange(e.target.value, "containerdatabase")} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label">Bit</label>
                          <select id="bit" className="form-control" name="bit" value={database.bit} onChange={(e) => handleOnChange(e.target.value, "bit")}>
                            <option value="" disabled={database.bit !== ""}>Select Bit</option>
                            <option value="32-bit">32-bit</option>
                            <option value="64-bit">64-bit</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label">Tablespaces</label>
                          <input id="tablespaces" className="form-control" type="number" name="tablespaces" placeholder="Tablespaces" value={parseInt(database.tablespaces || "-1")} onChange={(e) => handleOnChange(e.target.value.toString(), "tablespaces")} />
                        </div>
                      </div>
                      <div className={`col-sm-6 ${database.cloudlink.length > 35 ? "col-md-6" : "col-md-3"}`}>
                        <div className="form-group">
                          <label className="form-label">Cloud Link</label>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <input id="cloudlink" className="form-control" type="text" name="cloudlink" placeholder="Cloud Link" value={database.cloudlink} onChange={(e) => handleOnChange(e.target.value, "cloudlink")} />
                            <button id={`cloud-link-${database._id}`} className="btn btn-pill" style={{ margin: "5px 10px 10px 10px", backgroundColor: "#007bff" }} disabled={!new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&\/\/=]*)/).test(database.cloudlink)} onClick={() => window.open(database.cloudlink, "_blank")}>
                              <ExternalLink style={{ height: "15px", width: "auto", color: "white" }} />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className={`col-sm-6 ${database.datadoglink.length > 35 ? "col-md-6" : "col-md-3"}`}>
                        <div className="form-group">
                          <label className="form-label">Datadog Link</label>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <input id="datadoglink" className="form-control" type="text" name="datadoglink" placeholder="Datadog Link" value={database.datadoglink} onChange={(e) => handleOnChange(e.target.value, "datadoglink")} />
                            <button id={`datadog-link-${database._id}`} className="btn btn-pill" style={{ margin: "5px 10px 10px 10px", backgroundColor: "#007bff" }} disabled={!new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&\/\/=]*)/).test(database.datadoglink)} onClick={() => window.open(database.datadoglink, "_blank")}>
                              <ExternalLink style={{ height: "15px", width: "auto", color: "white" }} />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className={`col-sm-6 ${database.transformationphase.length > 35 ? "col-md-6" : "col-md-3"}`}>
                        <div className="form-group">
                          <label className="form-label">Transformation Phase</label>
                          <input id="transformationphase" className="form-control" type="text" name="transformationphase" placeholder="Transformation Phase" value={database.transformationphase} onChange={(e) => handleOnChange(e.target.value, "transformationphase")} />
                        </div>
                      </div>
                      <div className={`col-sm-6 ${database.transformationstrategy.length > 35 ? "col-md-6" : "col-md-3"}`}>
                        <div className="form-group">
                          <label className="form-label">Transformation Strategy</label>
                          <input id="transformationstrategy" className="form-control" type="text" name="transformationstrategy" placeholder="Transformation Strategy" value={database.transformationstrategy} onChange={(e) => handleOnChange(e.target.value, "transformationstrategy")} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label" style={{ marginBottom: "12px" }}>Transformation Group</label>
                          <Select id="transformationgroup" name="transformationgroup" onChange={(e) => {
                            /* istanbul ignore next */
                            handleOnChange(e, "transformationgroup");
                          }} value={database.transformationgroup} options={transformationgroups} placeholder={"Transformation Group"} isDisabled={
                            database.transformationgroup.value === "" && database.jirakey !== "INVALID"} />
                          {database.transformationgroup.value === "" && database.jirakey !== "INVALID" && <p style={{ textAlign: "center", marginTop: "10px" }}>Already a part of an application component.</p>}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label">Priority</label>
                          <select id="priority" className="form-control" name="priority" value={database.priority} onChange={(e) => handleOnChange(e.target.value, "priority")}>
                            <option value="" disabled={database.priority !== ""}>Select Priority</option>
                            <option value="LOW">Low</option>
                            <option value="MEDIUM">Medium</option>
                            <option value="HIGH">High</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label">Migration Pattern</label>
                          <select id="migrationpattern" className="form-control" name="migrationpattern" value={database.migrationpattern} onChange={(e) => handleOnChange(e.target.value, "migrationpattern")}>
                            <option value="" disabled={database.migrationpattern !== ""}>Select Migration Pattern</option>
                            {migrationPatterns.map((migrationPattern) => {
                              return (
                                <option key={migrationPattern.migrationPattern} value={migrationPattern.migrationPattern}>{migrationPattern.migrationPattern}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label" style={{ marginBottom: "12px" }}>Tags</label>
                          <CreatableSelect id="tags" name="tags" isMulti onChange={(e) => {
                            /* istanbul ignore next */
                            handleOnChange(e, "tags");
                          }} value={database.tags} components={{ DropdownIndicator: null }} placeholder="Tags" />
                        </div>
                      </div>
                      {id && <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label">Jira Ticket</label>
                          {database.jirakey && database.jirakey !== "" && database.jirakey !== "INVALID" ?
                            <div style={{
                              display: "flex",
                              justifyContent: "space-between"
                            }}>
                              <button id={`jira-view-${database._id}`} className="btn btn-pill" style={{ margin: "5px 0px 5px 0px", backgroundColor: "#007bff", width: "47%" }} onClick={() => window.open(`${jiradomain}/browse/${database.jirakey}`, "_blank")}>
                                <ExternalLink style={{ height: "15px", width: "auto", color: "white" }} />
                              </button>
                              <button id={`jira-delete-${database._id}`} className="btn btn-pill" style={{ margin: "5px 0px 5px 0px", backgroundColor: "#e83845", width: "47%" }} onClick={() => deleteJiraTicket()}>
                                <Trash style={{ height: "15px", width: "auto", color: "white" }} />
                              </button>
                            </div>
                            :
                            <div>
                              <button id={`jira-create-${database._id}`} className="btn form-control btn-primary" disabled={database.jirakey === "INVALID"} onClick={() => createJiraTicket()}>Create</button>
                              {database.jirakey === "INVALID" && <p style={{ textAlign: "center" }}>Must be assigned to a migration group.</p>}
                            </div>
                          }
                        </div>
                      </div>
                      }
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label">Discovered By</label>
                          <input id="discovered" readOnly className="form-control" type="text" name="discovered" placeholder="Discovered" value={database.discovered} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Notes</label>
                          <textarea id="notes" className="form-control" rows={5} type="text" name="notes" placeholder="Notes" value={database.notes} onChange={(e) => handleOnChange(e.target.value, "notes")} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Merge Strategy</label>
                          {["Global", "Discovery", "Manual"].map((mergeStrategy) => {
                            return (
                              <div key={mergeStrategy} className="radio col-sm-6 col-md-12">
                                <input
                                  className="form-control"
                                  id={`mergestrategy-${mergeStrategy.toLowerCase()}`}
                                  type="radio"
                                  value={mergeStrategy}
                                  checked={database.mergestrategy === mergeStrategy}
                                  onChange={() => handleOnChange(mergeStrategy, "mergestrategy")}
                                  name={mergeStrategy.toLowerCase()}
                                  disabled={["Global", "Discovery"].includes(mergeStrategy) && database.discovered === "Manual"}
                                />
                                <label htmlFor={`mergestrategy-${mergeStrategy.toLowerCase()}`}>{mergeStrategy}</label>
                                {mergeStrategy === "Global" &&
                                  <p style={{ width: "100%", color: "grey" }}>Merge according to the global setting set by administrators.</p>
                                }

                                {mergeStrategy === "Discovery" &&
                                  <p style={{ width: "100%", color: "grey" }}>Allow new discovery data to override these values.</p>
                                }

                                {mergeStrategy === "Manual" &&
                                  <p style={{ width: "100%", color: "grey" }}>Override discovery data with these values.</p>
                                }
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <Link id="back" to={"/discovery/databases"}><button className="btn btn-primary text-left" type="submit">Back</button></Link>
                    <button id="submit" className="btn btn-primary float-right" onClick={() => handleOnSubmit()} type="submit">{id ? "Update" : "Create"}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment >
  );
};
export default DatabaseEdit;




