import React, { Fragment, useEffect, useCallback } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import axios from "axios";

import $ from "jquery";
import * as Survey from "survey-core";
import * as SurveyReact from "survey-react-ui";
import * as widgets from "surveyjs-widgets";
import "select2/dist/js/select2.js";

import "survey-react/survey.css";
import "survey-core/survey.css";
import "select2/dist/css/select2.min.css";
import { useAuthUser } from "../../authUserContext";
import { Link } from "react-router-dom";
import { CustomInput, FormGroup } from "reactstrap";
import * as xlsx from "xlsx";
import { setToast } from "../../components/common/Toast";
import { toast } from "react-toastify";

//Survey.StylesManager.applyTheme("modern");
window["$"] = window["jQuery"] = $;
widgets.select2(Survey, $);
widgets.select2tagbox(Survey, $);

export function testReadySendDataToServer(sender, authUser, setShowButton, showButton) {
  axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/add-completed-sustainability-assessment`, {
    user: authUser.name,
    completedAssessment: sender.data
  })
    .catch(() => {
      setToast(toast.error, "Unable to save assessment");
    });
  setShowButton(!showButton);
}

export function testReadyProcessUploadedData(survey, surveyJSON, event) {
  const fileReader = new FileReader();
  /* istanbul ignore next */
  fileReader.onload = (e) => {
    const workbook = xlsx.read(e.target.result, { WTF: 1 });
    let tempFoundServers = [];
    for (const compareServer of xlsx.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { headers: 1 })) {
      surveyJSON.pages[0].elements[3].choices.forEach((element) => {
        if (compareServer["Install Status"] === (element.text)) {
          tempFoundServers.push({ "equipment": [element.text], "quantity": compareServer["(Multiple Items)"].toString() });
        }
      });
    }
    let moddedSurvey = surveyJSON;
    let newDropDownQ3 = [];
    moddedSurvey.pages[0].elements[3].choices.forEach((e) => {
      tempFoundServers.forEach((element) => {
        if (element.equipment.includes(e.text)) {
          newDropDownQ3.push(e);
        }
      }
      );
    });
    survey.pages[0].elements[3].choices = newDropDownQ3;
    survey.setValue("question3", tempFoundServers);
  };
  /* istanbul ignore next */
  fileReader.onerror = () => {
    setToast(toast.error, "Unable to read file");
  };
  fileReader.readAsArrayBuffer(event.target.files[0]);
}

const SustainabilityAssessment = () => {
  const { authUser } = useAuthUser();
  const [surveyJSON, setSurveyJSON] = React.useState({});
  const [showButton, setShowButton] = React.useState(false);
  const [survey, setSurvey] = React.useState(new Survey.Model(surveyJSON));

  /* istanbul ignore next */
  const sendDataToServer = (sender) => {
    testReadySendDataToServer(sender, authUser, setShowButton, showButton);
  };

  React.useEffect(() => {
    let pueList = [];
    let co2List = [];
    let co2ListEdited = [];
    let tempAssessment = {};
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/factor-repository/get-all-factors`)
      .then((res) => {
        res.data.forEach((factor) => {
          if (factor.type === "PUE") pueList.push({ value: factor.description, text: `${factor.description} (${factor.value}) ref: ${factor.reference ? factor.reference : "No reference"} [${factor.date}]` });
          else if (factor.type === "CO2/KWh") co2List.push({ value: factor.description, text: `${factor.description} (${factor.value},) ${factor.reference} [${factor.date}]` });
        });
        res.data.map((factor) => {
          co2List.map((co2) => {
            if (new RegExp("\\b" + factor.reference + "\\b").test(co2.text.toString()) && factor.type === "CO2e/KWh") {
              co2ListEdited.push({ value: `${factor.description}`, text: `${co2.text.replace(",", `, ${factor.value}`)}` });
            }
          });
        });
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve factors");
      });
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/assessments/get-all`)
      .then((res) => {
        res.data[1].assessment.pages[0].elements[1].choices = pueList;
        res.data[1].assessment.pages[0].elements[4].choices = co2ListEdited;
        tempAssessment = res.data[1].assessment;
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve assessments");
      });
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/sustainability/server-specs`)
      .then((res) => {
        for (const serverObj of res.data) {
          tempAssessment.pages[0].elements[3].choices.push({ value: serverObj.MaterialName, text: serverObj.MaterialName });
        }
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/sustainability/server-power-rating`)
          .then((res) => {
            for (const serverObj of res.data) {
              if (serverObj.Type === "SERVER") {
                tempAssessment.pages[0].elements[3].choices.push({ value: serverObj.Model, text: serverObj.Model });
              }
            }
            setSurveyJSON(tempAssessment);
          }
          )
          .catch(() => {
            setToast(toast.error, "Unable to retrieve server power ratings");
          });
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve server specs");
      });
  }, []);

  //const survey = new Survey.Model(surveyJSON);

  //add tagbox into matrix columns (run-time)
  /* istanbul ignore next */
  Survey.matrixDropdownColumnTypes.tagbox = {
    onCellQuestionUpdate: function (cellQuestion, column, question, data) {
      Survey.matrixDropdownColumnTypes.checkbox.onCellQuestionUpdate(
        cellQuestion,
        column,
        question,
        data
      );
    }
  };

  /* istanbul ignore next */
  const processUploadedData = useCallback(async (event) => {
    testReadyProcessUploadedData(survey, surveyJSON, event);
  }, [survey]);

  useEffect(() => {
    setSurvey(new Survey.Model(surveyJSON));
  }, [surveyJSON]);

  return (
    <Fragment>
      <Breadcrumb title="Sustainability Assessment" parent="Sustainability" />
      {!showButton ? (
        <Fragment>
          <FormGroup>
            <CustomInput
              type="file"
              id="fileUpload"
              name="customFileUpload"
              onChange={processUploadedData}
            />
          </FormGroup>
          <SurveyReact.Survey model={survey} onComplete={sendDataToServer} />
        </Fragment>) :
        (<div className="card">
          <div className="card-header">
            <h5>{"Thank you for Completing the Assessment"}</h5>
          </div>
          <form>
            <div className="card-footer text-center">
              <Link to={"/sustainability/sustainability-result"}><button className="btn btn-primary">Show Result</button></Link></div>
          </form>
        </div>)}
    </Fragment>
  );
};

export default SustainabilityAssessment;