export const technologyStackOptions = [
  { value: "Java", label: "Java" },
  { value: "JavaScript", label: "JavaScript" },
  { value: "Python", label: "Python" },
  { value: "C#", label: "C#" },
  { value: "C++", label: "C++" },
  { value: "PHP", label: "PHP" },
  { value: "Ruby", label: "Ruby" },
  { value: "Swift", label: "Swift" },
  { value: "Go", label: "Go" },
  { value: "Kotlin", label: "Kotlin" },
  { value: "Scala", label: "Scala" },
  { value: "Objective-C", label: "Objective-C" },
  { value: "C", label: "C" },
  { value: "R", label: "R" },
  { value: "Perl", label: "Perl" },
  { value: "Shell", label: "Shell" },
  { value: "Rust", label: "Rust" },
  { value: "TypeScript", label: "TypeScript" }
];

export const installTypeOptons = [
  { value: "OnPremise", label: "On-Premise" },
  { value: "Cloud", label: "Cloud" },
  { value: "Hybrid", label: "Hybrid" },
  { value: "ThirdPartyHosted", label: "Third-Party Hosted" }
];

export const statusOptions = [
  { value: "Production", label: "Production" },
  { value: "Pilot", label: "Pilot" },
  { value: "Retired", label: "Retired" },
  { value: "UnderEvaluation", label: "Under Evaluation" }
];

export const userBaseOptions = [
  { value: "0-49", label: "0-49" },
  { value: "50-99", label: "50-99" },
  { value: "100-499", label: "100-499" },
  { value: "500-999", label: "500-999" },
  { value: "1000+", label: "1000+" }
];

export const businessCriticalityOptions = [
  { value: "Low", label: "Low" },
  { value: "Medium", label: "Medium" },
  { value: "High", label: "High" }
];

export const dataClassificationOptions = [
  { value: "Public", label: "Public" },
  { value: "Internal", label: "Internal" },
  { value: "HighlySensitive", label: "Highly Sensitive" },
  { value: "Confidential", label: "Confidential" }
];