import React from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import Links from "../../../components/common/Links";

const DiscoveryTools = () => {
  return (
    <div>
      <Breadcrumb title="Tools" parent="Discovery" />
      <Links pathname={window.location.pathname} />
    </div>
  );
};
export default DiscoveryTools;
