import React, { Fragment } from "react";
import { Submit } from "../../../constant";
import axios from "axios";

const Review = (props) => {
  const submitForm = (e) => {
    e.preventDefault();
    axios.post(props.id ? `${process.env.REACT_APP_BACKEND_URL}/api/orders/update-oci-database-order/${props.id}` : `${process.env.REACT_APP_BACKEND_URL}/api/orders/oci-database`, {
      name: props.orderName,
      order: {
        dbName: props.dbName,
        characterSet: props.characterSet,
        dbWorkload: props.dbWorkload,
        cpuCoreCount: props.cpuCoreCount,
        dataStorageSizeinTb: props.dataStorageSizeinTb
      },
      action: props.action,
      type: "ocidatabase"
    })
      .then(() => {
        window.location = "https://gitlab-master.datahubnow.com/terraform-enterprise/oci-database/-/pipelines";
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12">
          <form className="needs-validation" noValidate>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label>{"Database Name:"} {props.dbName}</label>
              </div>

              <div className="col-md-12 mb-3">
                <label>{"Database Character Set:"} {props.characterSet}</label>
              </div>

              <div className="col-md-12 mb-3">
                <label>{"Database Workload Type:"} {props.dbWorkload}</label>
              </div>

              <div className="col-md-12 mb-3">
                <label>{"CPU Core Count:"} {props.cpuCoreCount}</label>
              </div>

              <div className="col-md-12 mb-3">
                <label>{"Disk Size in TB:"} {props.dataStorageSizeinTb}</label>
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="Order Name">{"Order Name"}</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Order Name"
                  value={props.orderName}
                  onChange={(e) => props.setOrderName(e.target.value)}
                  required
                />
              </div>

              <div className="col-md-12 mb-3">
                <label>{"Action"}</label>
                <select
                  className="form-control"
                  value={props.action}
                  onChange={(e) => props.setAction(e.target.value)}
                  required
                >
                  <option value="" disabled>Select</option>
                  <option value="PLAN">Plan</option>
                  <option value="PROVISION">Provision</option>
                  <option value="DESTROY">Destroy</option>
                </select>
              </div>

              <button className="btn btn-primary r-7 btnsubmit" onClick={(e) => submitForm(e)}>{Submit}</button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default Review;