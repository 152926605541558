export const charactersets = [
  "Select",
  "AL32UTF8",
  "AR8ADOS710", 
  "AR8ADOS720", 
  "AR8APTEC715", 
  "AR8ARABICMACS", 
  "AR8ASMO8X", 
  "AR8ISO8859P6", 
  "AR8MSWIN1256", 
  "AR8MUSSAD768"
];