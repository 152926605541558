import React, { Fragment } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import DataTable from "react-data-table-component";
import axios from "axios";
import FilterComponent from "../../../components/common/FilterComponent";
import { toast } from "react-toastify";
import { setToast } from "../../../components/common/Toast";
import DiscoveryDropDown from "../../../components/common/DiscoveryDropDown";
import { tableStyles } from "../../../components/common/CustomStyles";
import { Link } from "react-router-dom";
import { Edit, ExternalLink, Trash } from "react-feather";

const PhysicalServersList = () => {
  const [discoveries, setDiscoveries] = React.useState([]);
  const [discovered, setDiscovered] = React.useState(undefined);
  const [discoveryId, setDiscoveryId] = React.useState(undefined);
  const [physicalServers, setPhysicalServers] = React.useState([]);
  const [jiradomain, setJiradomain] = React.useState(undefined);
  const [filteredItems, setFilteredItems] = React.useState([]);
  const [pending, setPending] = React.useState(true);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [clearRows, setClearRows] = React.useState(false);

  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/physical-servers/get-discoveries`)
      .then((response) => {
        setDiscoveries(response.data.reverse());
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve discoveries");
      });
  }, []);

  React.useEffect(() => {
    if (discoveries.length > 0) {
      setDiscovered(discoveries[0]);
    }
  }, [discoveries]);

  const getServers = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/physical-servers/get/${discovered}`)
      .then((response) => {
        setPhysicalServers(response.data.servers.servers);
        setDiscoveryId(response.data.servers._id);
        setJiradomain(response.data.jiradomain);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve physical servers");
        setPhysicalServers(null);
      });
  };

  React.useEffect(() => {
    if (!discovered) {
      return;
    }

    getServers();

    setPending(false);
  }, [discovered]);

  const createJiraTickets = () => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/jira`, {
      items: selectedRows,
      type: "physicalserver",
      discoveryId: discoveryId
    })
      .then(() => {
        setToast(toast.success, "Jira tickets created");
        setClearRows(true);
        setSelectedRows([]);
        setFilteredItems([]);
        setTimeout(() => getServers(), 1000);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setToast(toast.error, "No longer part of a migration group");
          setClearRows(true);
          setSelectedRows([]);
          setFilteredItems([]);
          setTimeout(() => getServers(), 1000);
        } else {
          setToast(toast.error, "Unable to create Jira tickets");
        }
      });
  };

  const deleteJiraTicket = (data) => {
    axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/jira`, {
      data: {
        serverId: data._id,
        jirakey: data.jirakey,
        type: "physicalserver",
        discoveryId: discoveryId
      }
    })
      .then(() => {
        setToast(toast.success, "Jira ticket deleted");
        setClearRows(true);
        setSelectedRows([]);
        setFilteredItems([]);
        setTimeout(() => getServers(), 1000);
      })
      .catch(() => {
        setToast(toast.error, "Unable to delete Jira ticket");
      });
  };

  /* istanbul ignore next */
  const columns = [
    {
      name: "Name",
      selector: (device) => device.name,
      wrap: true,
      sortable: true,
      center: true,
      width: "20rem",
      style: {
        borderRight: "1px solid #E5E5E5",
        padding: "1rem"
      }
    },
    {
      name: "Edit",
      center: true,
      sortable: true,
      wrap: true,
      width: "6rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) => <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        <Link id={`edit-${data._id}`} to={`/discovery/physical-servers/edit/${discoveryId}/${data._id}`}>
          <button className="btn btn-pill" style={{ backgroundColor: "#22af47" }}>
            <Edit style={{ height: "15px", width: "auto", color: "white" }} />
          </button>
        </Link>
      </div>
    },
    {
      name: "Jira",
      center: true,
      sortable: true,
      wrap: true,
      width: "6rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) =>
        data.jirakey && data.jirakey !== "INVALID" ?
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <button id={`jira-view-${data._id}`} className="btn btn-pill" style={{ margin: "5px 0px 5px 0px", backgroundColor: "#007bff" }} onClick={() => window.open(`${jiradomain}/browse/${data.jirakey}`, "_blank")}>
              <ExternalLink style={{ height: "15px", width: "auto", color: "white" }} />
            </button>
            <button id={`jira-delete-${data._id}`} className="btn btn-pill" style={{ margin: "5px 0px 5px 0px", backgroundColor: "#e83845" }} onClick={() => deleteJiraTicket(data)}>
              <Trash style={{ height: "15px", width: "auto", color: "white" }} />
            </button>
          </div>
          :
          null
    },
    {
      name: "Status",
      selector: (device) => device.status,
      center: true,
      sortable: true,
      wrap: true,
      width: "7rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) => <center><div>
        {data.status ? <i className="fa fa-circle font-success f-12" /> : <i className="fa fa-circle font-danger f-12" />}
        <p>{data.status ? "ONLINE" : "OFFLINE"}</p>
      </div></center>
    },
    {
      name: "IP",
      selector: (device) => device.ip,
      center: true,
      sortable: true,
      wrap: true,
      cell: (data) => {
        return (
          <ul style={{ textAlign: "center" }}>
            {data.ip.map((ip) => {
              return (
                <li key={ip.ip}>{ip.ip}</li>
              );
            })}
          </ul>
        );
      },
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "OS",
      selector: (device) => device.os,
      center: true,
      sortable: true,
      wrap: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Discovered By",
      selector: (device) => device.discovered,
      center: true,
      sortable: true,
      wrap: true,
      width: "11rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    }
  ];

  React.useEffect(() => {
    setFilteredItems((physicalServers || []).filter(
      (item) => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())));
  }, [filterText, physicalServers]);


  React.useEffect(() => {
    setClearRows(false);
  }, [clearRows]);

  const createDiscovery = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/physical-servers/discover`)
      .then(() => {
        setToast(toast.success, "Discovery started");
      })
      .catch(() => {
        setToast(toast.error, "Unable to start discovery");
      });
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div style={{ display: "flex", justifyContent: "space-between", alignitems: "center", width: "100%" }}>
        <div>
          <button id="create-discovery" key="discovery" style={{ marginLeft: "5px", marginRight: "5px" }} className="btn btn-primary" onClick={() => createDiscovery()} type="button">Create Discovery</button>
          <Link id="create-server" to={`/discovery/physical-servers/add/${discoveryId}`}>
            <button key="create" style={{ marginLeft: "5px", marginRight: "5px" }} className="btn btn-primary" type="button">Create Server</button>
          </Link>
          <button id="jira-create" key="jira" style={{ marginLeft: "5px", marginRight: "5px" }} disabled={selectedRows.length === 0} className="btn btn-primary" onClick={() => createJiraTickets()} type="button">Create Jira Tickets</button>
        </div>
        <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholder={"Name"} />
      </div>

    );
  }, [filterText, resetPaginationToggle, selectedRows, discoveryId]);


  return (
    <Fragment>
      <Breadcrumb title="Physical Servers" parent="Discovery" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{"Table of Physical Servers"}</h5>
              </div>
              <div className="card-body datatable-react">
                <DiscoveryDropDown
                  discovered={discovered}
                  setDiscovered={setDiscovered}
                  discoveries={discoveries}
                />
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  striped={true}
                  center={true}
                  customStyles={tableStyles}
                  progressPending={pending}
                  subHeaderComponent={subHeaderComponentMemo}
                  subHeader
                  persistTableHead
                  pagination
                  responsive
                  selectableRows
                  onSelectedRowsChange={(e) => {
                    /* istanbul ignore next */
                    setSelectedRows(e.selectedRows);
                  }}
                  clearSelectedRows={clearRows}
                  selectableRowDisabled={(row) => {
                    /* istanbul ignore next */
                    return row.jirakey !== "";
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default PhysicalServersList;
