import React, { Fragment } from "react";

const SelectSpecification = (props) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12">

          <form className="needs-validation" noValidate>
            <div className="form-row">
              
              <div className="col-md-12 mb-3">
                <label htmlFor="CPU Core Count">{"CPU Core Count"}</label>
                <select
                  className="form-control"
                  value={props.cpuCoreCount}
                  onChange={(e) => props.setCpuCoreCount(e.target.value)}
                  required
                >
                  <option key="" value="" disabled>Select</option>
                  <option key="1" value="1">1</option>
                  <option key="2" value="2">2</option>
                  <option key="3" value="3">3</option>
                  <option key="4" value="4">4</option>
                </select>
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="Disk Size in TB">{"Disk Size in TB"}</label>
                <select
                  className="form-control"
                  value={props.dataStorageSizeinTb}
                  onChange={(e) => props.setDataStorageSizeinTb(e.target.value)}
                  required
                >
                  <option key="" value="" disabled>Select</option>
                  <option key="1" value="1">1</option>
                  <option key="2" value="2">2</option>
                </select>
              </div>

            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default SelectSpecification;