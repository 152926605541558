import React, { Fragment } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import axios from "axios";
import "react-dropzone-uploader/dist/styles.css";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { setToast } from "../../../components/common/Toast";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { businessCriticalityOptions, dataClassificationOptions, installTypeOptons, statusOptions, technologyStackOptions, userBaseOptions } from "./options";

const BusinessApplicationEdit = () => {
  const [businessApplication, setBusinessApplication] = React.useState({
    application: "",
    vendor: "",
    status: "",
    version: "",
    description: "",
    numoftestsystems: 0,
    applicationowners: [],
    userbase: "",
    technologystack: [],
    installtype: "",
    dataclassification: "",
    businesscriticality: "",
    rpo: "",
    rto: "",
    applicationcomponents: [],
    tags: []
  });
  const [applicationComponents, setApplicationComponents] = React.useState(undefined);
  const { id } = useParams();

  React.useEffect(() => {
    axios.get(id ? `${process.env.REACT_APP_BACKEND_URL}/api/business-applications/application-components/${id}` : `${process.env.REACT_APP_BACKEND_URL}/api/business-applications/application-components`)
      .then((response) => {
        setApplicationComponents(response.data.map((applicationComponent) => {
          return {
            value: applicationComponent._id,
            label: applicationComponent.component
          };
        }));
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve application components");
        setApplicationComponents(null);
      });
  }, []);

  React.useEffect(() => {
    if (id && applicationComponents) {
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/business-applications/${id}`)
        .then((response) => {
          /* istanbul ignore next */
          setBusinessApplication({
            ...response.data,
            status: response.data.status ? { value: response.data.status, label: response.data.status } : "",
            installtype: response.data.installtype ? { value: response.data.installtype, label: response.data.installtype } : "",
            userbase: response.data.userbase ? { value: response.data.userbase, label: response.data.userbase } : "",
            dataclassification: response.data.dataclassification ? { value: response.data.dataclassification, label: response.data.dataclassification } : "",
            businesscriticality: response.data.businesscriticality ? { value: response.data.businesscriticality, label: response.data.businesscriticality } : "",
            technologystack: response.data.technologystack ? response.data.technologystack.map((item) => {
              return {
                value: item,
                label: item
              };
            }) : [],
            applicationowners: response.data.applicationowners ? response.data.applicationowners.split(",").map((item) => {
              return {
                value: item,
                label: item
              };
            }) : [],
            tags: response.data.tags ? response.data.tags.split(",").map((item) => {
              return {
                value: item,
                label: item
              };
            }) : [],
            applicationcomponents: response.data.applicationcomponents.map((item) => {
              const applicationComponent = applicationComponents.find((appComp) => appComp.value === item);

              if (applicationComponent) {
                return applicationComponent;
              }
            })
          });
        })
        .catch(() => {
          setToast(toast.error, "Unable to retrieve business application");
        });
    }
  }, [id, applicationComponents]);

  const handleOnChange = (value, name) => {
    setBusinessApplication({ ...businessApplication, [name]: value });
  };

  const handleOnSubmit = () => {
    axios.post(id ? `${process.env.REACT_APP_BACKEND_URL}/api/business-applications/${id}` : `${process.env.REACT_APP_BACKEND_URL}/api/business-applications`, {
      application: businessApplication.application,
      vendor: businessApplication.vendor,
      version: businessApplication.version,
      description: businessApplication.description,
      numoftestsystems: businessApplication.numoftestsystems,
      rpo: businessApplication.rpo,
      rto: businessApplication.rto,
      status: businessApplication.status.value,
      installtype: businessApplication.installtype.value,
      userbase: businessApplication.userbase.value,
      technologystack: businessApplication.technologystack.map((item) => item.value),
      dataclassification: businessApplication.dataclassification.value,
      businesscriticality: businessApplication.businesscriticality.value,
      applicationowners: businessApplication.applicationowners.map((item) => item.value).join(","),
      applicationcomponents: businessApplication.applicationcomponents.map((item) => item.value),
      tags: businessApplication.tags.map((item) => item.value).join(",")
    })
      .then(() => {
        window.location.assign("/discovery/business-applications");
      })
      .catch(() => {
        setToast(toast.error, `Unable to ${id ? "update" : "create"} business application`);
      });
  };

  return (
    <Fragment>
      <Breadcrumb title={id ? "Edit" : "Create"} parent="Migration Groups" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{id ? "Edit" : "Create"} Business Application Information</h5>
              </div>
              <div className="card-body datatable-react">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title mb-0">{`Business Application: ${businessApplication.application}`}</h4>
                    <div className="card-options">
                      <a className="card-options-collapse" href="javascript" data-toggle="card-collapse">
                        <i className="fe fe-chevron-up"></i>
                      </a>
                      <a className="card-options-remove" href="javascript" data-toggle="card-remove">
                        <i className="fe fe-x"></i>
                      </a>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Name</label>
                          <input id="application" className="form-control" type="text" name="application" placeholder="Business Application" value={businessApplication.application} onChange={(e) => handleOnChange(e.target.value, "application")} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Description</label>
                          <input id="description" className="form-control" type="text" name="description" placeholder="Description" value={businessApplication.description} onChange={(e) => handleOnChange(e.target.value, "description")} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label" style={{ marginBottom: "12px" }}>Status</label>
                          <CreatableSelect id="status" name="status" onChange={(e) => {
                            /* istanbul ignore next */
                            handleOnChange(e, "status");
                          }} value={businessApplication.status} placeholder="Status" options={statusOptions} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label">Vendor</label>
                          <input id="vendor" className="form-control" type="text" name="vendor" placeholder="Vendor" value={businessApplication.vendor} onChange={(e) => handleOnChange(e.target.value, "vendor")} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label">Version</label>
                          <input id="version" className="form-control" type="text" name="version" placeholder="Version" value={businessApplication.version} onChange={(e) => handleOnChange(e.target.value, "version")} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label" style={{ marginBottom: "12px" }}>Owner(s)</label>
                          <CreatableSelect id="applicationowners" name="applicationowners" isMulti onChange={(e) => {
                            /* istanbul ignore next */
                            handleOnChange(e, "applicationowners");
                          }} value={businessApplication.applicationowners} components={{ DropdownIndicator: null }} placeholder="Owner(s)" />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label" style={{ marginBottom: "12px" }}>User Base</label>
                          <Select id="userbase" name="userbase" onChange={(e) => {
                            /* istanbul ignore next */
                            handleOnChange(e, "userbase");
                          }} value={businessApplication.userbase} placeholder="User Base" options={userBaseOptions} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label" style={{ marginBottom: "12px" }}>Business Criticality</label>
                          <Select id="businesscriticality" name="businesscriticality" onChange={(e) => {
                            /* istanbul ignore next */
                            handleOnChange(e, "businesscriticality");
                          }} value={businessApplication.businesscriticality} placeholder="Business Criticality" options={businessCriticalityOptions} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label" style={{ marginBottom: "12px" }}>Data Classification</label>
                          <Select id="dataclassification" name="dataclassification" onChange={(e) => {
                            /* istanbul ignore next */
                            handleOnChange(e, "dataclassification");
                          }} value={businessApplication.dataclassification} placeholder="Data Classification" options={dataClassificationOptions} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label" style={{ marginBottom: "12px" }}>Technology Stack</label>
                          <CreatableSelect id="technologystack" name="technologystack" isMulti onChange={(e) => {
                            /* istanbul ignore next */
                            handleOnChange(e, "technologystack");
                          }} value={businessApplication.technologystack} placeholder="Technology Stack" options={technologyStackOptions} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label" style={{ marginBottom: "12px" }}>Install Type</label>
                          <Select id="installtype" name="installtype" onChange={(e) => {
                            /* istanbul ignore next */
                            handleOnChange(e, "installtype");
                          }} value={businessApplication.installtype} placeholder="Install Type" options={installTypeOptons} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label">Number of Test Systems</label>
                          <input id="numoftestsystems" className="form-control" type="number" name="numoftestsystems" placeholder={0} value={businessApplication.numoftestsystems} onChange={(e) => handleOnChange(e.target.value, "numoftestsystems")} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label" style={{ marginBottom: "12px" }}>Recovery Point Objective</label>
                          <input id="rpo" className="form-control" type="text" name="rpo" placeholder="0w, 1d, 6h, 0m" value={businessApplication.rpo} onChange={(e) => handleOnChange(e.target.value, "rpo")} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label" style={{ marginBottom: "12px" }}>Recovery Time Objective</label>
                          <input id="rto" className="form-control" type="text" name="rto" placeholder="1w, 4d, 5h, 30m" value={businessApplication.rto} onChange={(e) => handleOnChange(e.target.value, "rto")} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label className="form-label" style={{ marginBottom: "12px" }}>Tags</label>
                          <CreatableSelect id="tags" name="tags" isMulti onChange={(e) => {
                            /* istanbul ignore next */
                            handleOnChange(e, "tags");
                          }} value={businessApplication.tags} components={{ DropdownIndicator: null }} placeholder="Tags" />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label" style={{ marginBottom: "12px" }}>Application Components</label>
                          <Select id="applicationcomponents" name="applicationcomponents" isMulti options={applicationComponents} isLoading={applicationComponents === undefined} onChange={(e) => {
                            /* istanbul ignore next */
                            handleOnChange(e, "applicationcomponents");
                          }} value={businessApplication.applicationcomponents} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <Link id="back" to={"/discovery/business-applications"}><button className="btn btn-primary text-left" type="submit">Back</button></Link>
                    <button id="submit" className="btn btn-primary float-right" onClick={handleOnSubmit} type="submit">{id ? "Update" : "Create"}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default BusinessApplicationEdit;
