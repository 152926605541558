import { all } from "redux-saga/effects";
import { WatcherEcommerceApp } from "../redux/ecommerce";
import { WatcherChatApp } from "../redux/chat";
import { watchTodoList } from "../redux/todo";
import { watcherTaskApp } from "../redux/task";

export default function* rootSagas() {
  yield all([
    WatcherEcommerceApp(),
    WatcherChatApp(),
    watchTodoList(),
    watcherTaskApp()
  ]);
}