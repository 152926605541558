import React from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import DataTable from "react-data-table-component";
import axios from "axios";
import FilterComponent from "../../../components/common/FilterComponent";
import { tableStyles } from "../../../components/common/CustomStyles";
import { setToast } from "../../../components/common/Toast";
import { toast } from "react-toastify";

const PostgresToPostgres = () => {
  const [migrationProjects, setMigrationProjects] = React.useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");

  const getAllMigrationProjects = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/postgres-2-postgres/migrations`)
      .then((res) => {
        setMigrationProjects(res.data);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve migration projects");
      });
  };

  React.useEffect(() => {
    getAllMigrationProjects();
  }, []);

  const runMigration = (id) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/postgres-2-postgres/run-migration`, { id })
      .then((res) => {
        window.open(res.data.url, "_blank");
        getAllMigrationProjects();
      })
      .catch(() => {
        setToast(toast.error, "Unable to run migration");
      });
  };

  const deleteMigration = (id) => {
    axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/postgres-2-postgres/migrations/${id}`)
      .then(() => {
        setToast(toast.success, "Migration deleted");
        getAllMigrationProjects();
      })
      .catch(() => {
        setToast(toast.error, "Unable to delete migration");
      });
  };

  /* istanbul ignore next */
  const columns = [
    {
      name: "Name",
      selector: (migration) => migration.name,
      wrap: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Status",
      selector: (migration) => migration.status,
      center: true,
      wrap: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Start Date",
      selector: (migration) => migration.startDate,
      center: true,
      wrap: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Stop Date",
      selector: (migration) => migration.stopDate,
      center: true,
      wrap: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Actions",
      center: true,
      width: "13rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) => <div>
        <button id={`edit-${data._id}`} className="btn btn-primary" style={{ margin: "5px 0px 5px 0px", width: "175px" }} onClick={() => window.location.assign(`/database-factory/postgres-to-postgres/create/${data._id}`)}>Edit</button>
        <button id={`run-${data._id}`} className="btn btn-primary" style={{ margin: "5px 0px 5px 0px", width: "175px" }} onClick={() => runMigration(data._id)}>Run</button>
        <button id={`delete-${data._id}`} className="btn btn-primary" style={{ margin: "5px 0px 5px 0px", width: "175px" }} onClick={() => deleteMigration(data._id)}>Delete</button>
      </div>
    }
  ];

  const filteredItems = migrationProjects.filter(
    (item) => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <div>
          <button id="create-migration" className="btn btn-primary" onClick={() => window.location.assign("/database-factory/postgres-to-postgres/create")} type="button">Create Migration</button>
        </div>
        <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholder={"Project"} />
      </div>

    );
  }, [filterText, resetPaginationToggle]);

  return (
    <div>
      <Breadcrumb title="Postgres-To-Postgres Migrations" parent="Database Factory" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{"Migrations"}</h5>
              </div>
              <div className="card-body datatable-react">
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  striped={true}
                  center={true}
                  customStyles={tableStyles}
                  subHeaderComponent={subHeaderComponentMemo}
                  subHeader
                  persistTableHead
                  pagination
                  responsive
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostgresToPostgres;
