import React, { Fragment } from "react";

const ConfigureSecretType = (props) => {
  const secretTypeList = [
    "PEM key",
    "Database Credentials",
    "Single input"
  ];

  const databaseTypeList = [
    "Oracle",
    "SQL Server",
    "Sybase",
    "PostgreSQL"
  ];

  const onFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsText(file);

    reader.onload = () => {
      /* istanbul ignore next */
      props.setPemSecret(reader.result);
    };

    props.setPemName(file.name);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-row">
            <div className="col-md-12 mb-3">
              <label>Select Secret Type:</label>
              <select
                className="form-control"
                id="secretType"
                value={props.secretType}
                onChange={(e) => {
                  /* istanbul ignore next */
                  props.setSecretType(e.target.value);
                }}
                required
              >
                <option value="" disabled hidden>Choose:</option>
                {secretTypeList.map((region, index) => (
                  <option key={index} value={region}>{region}</option>
                ))}
              </select>
            </div>
            {props.secretType === "PEM key" ?
              <div className="col-md-12 mb-3">
                <label>Username:</label>
                <input
                  className="form-control"
                  id="username"
                  type="text"
                  placeholder="Enter Username"
                  value={props.username}
                  onChange={(e) => props.setUsername(e.target.value)}
                  required
                />
                <label>Import Pem:</label>
                <input
                  className="form-control"
                  type="file"
                  id="pemFile"
                  placeholder="No File Chosen"
                  onChange={(e) => onFileChange(e)}
                  required
                />
              </div>
              : props.secretType === "Single input" ?
                <div className="col-md-12 mb-3">
                  <label>Input text:</label>
                  <input
                    className="form-control"
                    id="singleSecretType"
                    type="text"
                    placeholder="Enter text"
                    value={props.singleTypeSecret}
                    onChange={(e) => props.setSingleTypeSecret(e.target.value)}
                    required
                  />
                </div>
                :
                <div className="col-md-12 mb-3">
                  <label>Select Database Type:</label>
                  <select
                    className="form-control"
                    id="databaseType"
                    value={props.databaseType}
                    onChange={(e) => props.setDatabaseType(e.target.value)}
                    required
                  >
                    <option value="" disabled hidden>Choose:</option>
                    {databaseTypeList.map((type, index) => (
                      <option key={index} value={type}>{type}</option>
                    ))}
                  </select>
                  <label>Username:</label>
                  <input
                    className="form-control"
                    type="text"
                    id="databaseUsername"
                    placeholder="Enter Username"
                    value={props.databaseUsername}
                    onChange={(e) => props.setDatabaseUsername(e.target.value)}
                    required
                  />
                  <label>Password:</label>
                  <input
                    className="form-control"
                    type="text"
                    id="databasePassword"
                    placeholder="Enter Password"
                    value={props.databasePassword}
                    onChange={(e) => props.setDatabasePassword(e.target.value)}
                    required
                  />
                  <label>Port:</label>
                  <input
                    className="form-control"
                    type="text"
                    id="databasePort"
                    placeholder="Enter Database Port"
                    value={props.databasePort}
                    onChange={(e) => props.setDatabasePort(e.target.value)}
                    required
                  />
                  <label>Database Name:</label>
                  <input
                    className="form-control"
                    type="text"
                    id="databaseName"
                    placeholder="Enter Database Name"
                    value={props.databaseName}
                    onChange={(e) => props.setDatabaseName(e.target.value)}
                    required
                  />
                </div>
            }
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ConfigureSecretType;