import React from "react";
import Modal from "react-responsive-modal";
import axios from "axios";
import { setToast } from "../../../components/common/Toast";
import { toast } from "react-toastify";

const MigrationProjectModal = (props) => {
  const [projectName, setProjectName] = React.useState("");
  const [projectDescription, setProjectDescription] = React.useState("");

  React.useEffect(() => {
    if (props.projectModal && props.currentProject) {
      setProjectName(props.currentProject.migrationProjectName);
      setProjectDescription(props.currentProject.migrationProjectDescription);
    }
  }, [props.projectModal, props.currentProject]);

  const handleProjectModalClose = () => {
    props.setProjectModal(false);
    setProjectName("");
    setProjectDescription("");
    props.setCurrentProject({});
  };

  const handleEditProjectSubmit = () => {
    axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/fdm/migration-projects`, {
      currentProject: props.currentProject,
      projectName: projectName,
      projectDescription: projectDescription
    })
      .then(() => {
        handleProjectModalClose();
        props.getMigrationProjects();
      })
      .catch(() => {
        setToast(toast.error, "Unable to update migration project");
      });
  };

  const handleCreateProjectSubmit = () => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/fdm/migration-projects`, {
      projectName: projectName,
      projectDescription: projectDescription
    })
      .then(() => {
        handleProjectModalClose();
        props.getMigrationProjects();
      })
      .catch(() => {
        setToast(toast.error, "Unable to create migration project");
      });
  };

  return (
    <Modal open={props.projectModal} onClose={handleProjectModalClose}>
      <div className="modalCustom">
        <h6 className="f-w-600 text-align-centre">Create Migration Project</h6>

        <p className="form-text">Project Name</p>
        <input
          id="name"
          type="text"
          className="form-control"
          maxLength={20}
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
        />

        <p className="form-text">Project Description</p>
        <textarea
          id="description"
          type="text"
          className="form-control"
          value={projectDescription}
          onChange={(e) => setProjectDescription(e.target.value)}
        />

        <button id="submit" className="btn btn-pill btn-primary btn-air-primary btn-modal" onClick={Object.keys(props.currentProject).length > 0 ? handleEditProjectSubmit : handleCreateProjectSubmit} type="button">Submit</button>
      </div>
    </Modal>
  );
};

export default MigrationProjectModal;