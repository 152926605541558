import React, { Fragment } from "react";
import { cloudsqltypes } from "../gcp-list/CloudSQLTypes";
import { cloudsqldbversion } from "../gcp-list/CloudSQLDBVersions";

const ConfigureResource = (props) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12">
          <form className="needs-validation" noValidate>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="CloudSQL Instance Name">{"CloudSQL Instance Name"}</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter CloudSQL Instance Name"
                  value={props.vmName}
                  onChange={(e) => props.setVmName(e.target.value)}
                  required
                />
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="CloudSQL Machine Type">{"CloudSQL Machine Type"}</label>
                <select
                  className="form-control"
                  value={props.instanceMachineType}
                  onChange={(e) => props.setInstanceMachineType(e.target.value)}
                  required
                >
                  {cloudsqltypes.map((instance, index) => (
                    <option key={index} value={instance}>{instance}</option>
                  ))}
                </select>
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="Database Version">{"Database Version"}</label>
                <select
                  className="form-control"
                  value={props.cloudSqlDBVersion}
                  onChange={(e) => props.setCloudSqlDBVersion(e.target.value)}
                  required
                >
                  {cloudsqldbversion.map((version, index) => (
                    <option key={index} value={version}>{version}</option>
                  ))}
                </select>
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="Boot Disk Size in GB">{"Boot Disk Size in GB"}</label>
                <input
                  className="form-control"
                  type="number"
                  placeholder="Minimum 10, Maximum 50"
                  maxLength={2}
                  max={50}
                  min={10}
                  required
                  value={props.bootDiskSize}
                  onChange={(e) => props.setBootDiskSize(e.target.value)}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default ConfigureResource;