import React, { Fragment } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { Container,Row,Col } from "reactstrap";
import CustomerBoard from "./CustomerBoard";

const kanbanBoard = () => {
  return (
    <Fragment>
      <Breadcrumb parent="Apps" title="Kanban Board" />
      <Container fluid={true} className="jkanban-container">
        <Row>
          <Col xs="12">
            <CustomerBoard/>
          </Col>
        </Row> 
      </Container>
    </Fragment>
  );
};

export default kanbanBoard;