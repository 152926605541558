import React, { Fragment } from "react";
import { dbtypes } from "../azr-list/DBTypes";
import { dbversions } from "../azr-list/DBVersions";

const ConfigureResource = (props) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12">
          <form className="needs-validation" noValidate>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="Database Instance Name">{"Database Instance Name"}</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Database Instance Name"
                  value={props.deploymentName}
                  onChange={(e) => props.setDeploymentName(e.target.value)}
                  required
                />
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="Database SKU">{"Database SKU"}</label>
                <select
                  className="form-control"
                  value={props.databaseSku}
                  onChange={(e) => props.setDatabaseSku(e.target.value)}
                  required
                >
                  <option key="" value="" disabled>Select</option>
                  {dbtypes.map((db, index) => (
                    <option key={index} value={db}>{db}</option>
                  ))}
                </select>
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="Database Version">{"Database Version"}</label>
                <select
                  className="form-control"
                  value={props.databaseVersion}
                  onChange={(e) => props.setDatabaseVersion(e.target.value)}
                  required
                >
                  <option key="" value="" disabled>Select</option>
                  {dbversions.map((dbversion, index) => (
                    <option key={index} value={dbversion}>{dbversion}</option>
                  ))}
                </select>
              </div>

            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default ConfigureResource;