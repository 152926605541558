import React, { Fragment } from "react";
import { region, region_zones } from "../gcp-list/PackagedDeploymentProps";

const ConfigureRegion = (props) => {
  let clusterZones;
  if (props.region === "europe-west1") {
    props.setClusterZones(region_zones[props.region]);
    clusterZones = (
      <div className="col-md-12 mb-3">
        <label htmlFor="Cluster Zones">{"Cluster Zones"}</label>
        <input
          className="form-control"
          type="string"
          readOnly
          value={props.clusterZones}
        />
      </div>
    );

  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12">
          <form className="needs-validation" noValidate>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label>{"Region"}</label>
                <select
                  className="form-control"
                  value={props.region}
                  onChange={(e) => props.setRegion(e.target.value)}
                  required
                >
                  <option key="" value="" disabled>
                    Select
                  </option>
                  {region.map((region, index) => (
                    <option key={index} value={region}>
                      {region}
                    </option>
                  ))}
                </select>
              </div>
              {clusterZones}

            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default ConfigureRegion;
