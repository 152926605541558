import React from "react";

const DiscoveryDropDown = (props) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <label>{"Discovered:"}</label>
      <select
        id="discoveries"
        style={{ width: "300px", marginLeft: "10px", marginRight: "10px" }}
        className="form-control"
        value={props.discovered}
        onChange={(e) => props.setDiscovered(e.target.value)}
        required
      >
        {props.discoveries.map((discovery) => {
          return (
            <option key={discovery} value={discovery}>
              {new Date(discovery).toUTCString()}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default DiscoveryDropDown;