import React, { Fragment } from "react";

const SelectType = (props) => {
  const credentialChoiceList = [
    "Database",
    "Server"
  ];

  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-row">
            <div className="col-md-12 mb-3">
              <label htmlFor="Credential Choice">Credential Choice:</label>
              <select
                className="form-control"
                id="credentialChoice"
                value={props.credentialChoice}
                onChange={(e) => props.setCredentialChoice(e.target.value)}
                required
              >
                <option value="" disabled hidden>Choose:</option>
                {credentialChoiceList.map((choice, index) => (
                  <option key={index} value={choice}>{choice}</option>
                ))}
              </select>
            </div>
            {props.credentialChoice === "Database" ?
              <div className="col-md-12 mb-3">
                <label htmlFor="Input a Database">Input a Database:</label>
                <input
                  className="form-control"
                  type="text"
                  id="database"
                  placeholder="Enter IP Address"
                  value={props.database}
                  onChange={(e) => props.setDatabase(e.target.value)}
                  required
                />
              </div>
              :
              <div className="col-md-12 mb-3">
                <label htmlFor="Input a Server">Input a Server:</label>
                <input
                  className="form-control"
                  type="text"
                  id="server"
                  placeholder="Enter IP Address"
                  value={props.server}
                  onChange={(e) => props.setServer(e.target.value)}
                  required
                />
              </div>
            }
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SelectType;