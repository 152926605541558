import React, { Fragment } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import DataTable from "react-data-table-component";
import axios from "axios";
import FilterComponent from "../../../components/common/FilterComponent";
import "react-dropzone-uploader/dist/styles.css";
import { toast } from "react-toastify";
import { setToast } from "../../../components/common/Toast";
import UploadModal from "./UploadModal";
import SurveyModal from "./SurveyModal";
import { tableStyles } from "../../../components/common/CustomStyles";
import { Link } from "react-router-dom";
import { Edit, Trash } from "react-feather";

const BusinessApplicationsList = () => {
  const [applications, setApplications] = React.useState([]);
  const [openResult, setOpenResult] = React.useState(false);
  const [openUpload, setOpenUpload] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [completedAssessment, setCompletedAssessment] = React.useState({});
  const [allCompletedAssessment, setAllCompletedAssessment] = React.useState([]);
  const [rating, setRating] = React.useState("");

  const onOpenModalResult = (data) => {
    setOpenResult(true);
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/get-completed-application-assessment/${data._id}`)
      .then((res) => {
        setCompletedAssessment(res.data.completedAssessment);
        setRating(res.data.rating);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve application assessment");
      });
  };

  const getBusinessApplications = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/business-applications`)
      .then(async (response) => {
        for (const busApps of response.data) {
          const applicationComps = [];
          for await (let appComp of busApps.applicationcomponents) {
            await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/application-components/${appComp}`)
              .then((response) => {
                /* istanbul ignore next */
                applicationComps.push({
                  _id: response.data._id,
                  component: response.data.component
                });
              }).catch(() => {
                setToast(toast.error, "Unable to retrieve application component");
              });
          }

          busApps.applicationcomponents = applicationComps;
        }

        setApplications(response.data);

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/business-applications/get-all-completed-assessments`)
          .then((res) => {
            setAllCompletedAssessment(res.data);
          })
          .catch(() => {
            setToast(toast.error, "Unable to retrieve completed assessments");
          });
      }).catch(() => {
        setToast(toast.error, "Unable to retrieve business applications");
      });
  };

  React.useEffect(() => {
    getBusinessApplications();
  }, []);

  const filteredItems = applications.filter(
    (item) => item.application && item.application.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText !== "") {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
        <div>
          <Link id="create-business-application" className="btn btn-primary" style={{ marginLeft: "5px", marginRight: "5px" }} to="/discovery/business-applications/add">Create Business Application</Link>
          <button id="import-business-applications" className="btn btn-primary" style={{ marginLeft: "5px", marginRight: "5px" }} onClick={() => setOpenUpload(true)} type="button">Import Business Applications</button>
        </div>
        <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholder={"Application"} />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const deleteBusinessApplication = (data) => {
    if (window.confirm("Are you sure you want to delete this business application?")) {
      axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/business-applications/${data._id}`)
        .then(() => {
          setToast(toast.success, "Business application deleted");
          getBusinessApplications();
        }).catch(() => {
          setToast(toast.error, "Unable to delete business application");
        });
    }
  };

  /* istanbul ignore next */
  const columns = [
    {
      name: "Application",
      selector: (database) => database.application,
      center: true,
      sortable: true,
      wrap: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Actions",
      center: true,
      sortable: true,
      wrap: true,
      width: "7rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) =>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <button id={`edit-${data._id}`} className="btn btn-pill" style={{ margin: "5px 0px 5px 0px", backgroundColor: "#22af47" }} onClick={() => window.location.assign(`/discovery/business-applications/edit/${data._id}`)}>
            <Edit style={{ height: "15px", width: "auto", color: "white" }} />
          </button>
          <button id={`delete-${data._id}`} className="btn btn-pill" style={{ margin: "5px 0px 5px 0px", backgroundColor: "#e83845" }} onClick={() => deleteBusinessApplication(data)}>
            <Trash style={{ height: "15px", width: "auto", color: "white" }} />
          </button>
        </div>
    },
    {
      name: "Description",
      selector: (database) => database.description,
      center: true,
      sortable: true,
      wrap: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Survey",
      center: true,
      sortable: true,
      wrap: true,
      width: "10rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) => allCompletedAssessment.map((assesment) => assesment.applicationId === data._id ? <div key={data._id}><button id={`assessment-${data._id}`} className="btn btn-primary" style={{ margin: "5px 0px 5px 0px", width: "125px" }} onClick={() => onOpenModalResult(data)}>View</button><p style={{ margin: 0, textAlign: "center" }}>R Rating:</p><p style={{ margin: 0, textAlign: "center", fontWeight: "bold" }}>{(allCompletedAssessment.find((assesment) => assesment.applicationId === data._id) || { rating: "" }).rating}</p></div> : null)
    },
    {
      name: "Components",
      selector: (database) => database.applicationcomponents,
      center: true,
      sortable: true,
      wrap: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) => {
        return (
          <ul style={{ textAlign: "center" }}>
            {data.applicationcomponents.map((applicationComponent) => {
              return (
                <li style={{ margin: "15px" }} key={applicationComponent._id}>
                  <Link to={`/discovery/application-components/edit/${applicationComponent._id}`} style={{ color: "rgba(0,0,0,0.87)" }}>
                    {applicationComponent.component}
                  </Link>
                </li>
              );
            })}
          </ul>
        );
      }
    },
    {
      name: "Owner(s)",
      selector: (database) => database.applicationowners,
      center: true,
      sortable: true,
      wrap: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) => {
        return (
          <ul style={{ textAlign: "center" }}>
            {data.applicationowners.split(",").map((owner) => {
              return (
                <li key={owner}>{owner}</li>
              );
            })}
          </ul>
        );
      }
    },
    {
      name: "Tags",
      selector: (database) => database.tags,
      center: true,
      sortable: true,
      wrap: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) => {
        return (
          <ul style={{ textAlign: "center" }}>
            {data.tags.split(",").map((tag) => {
              return (
                <li key={tag}>{tag}</li>
              );
            })}
          </ul>
        );
      }
    }
  ];

  return (
    <Fragment>
      <Breadcrumb title="Business Applications" parent="Discovery" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{"Table of Business Applications"}</h5>
              </div>
              <div className="card-body datatable-react">
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  customStyles={tableStyles}
                  striped={true}
                  center={true}
                  subHeaderComponent={subHeaderComponentMemo}
                  subHeader
                  persistTableHead
                  pagination
                  responsive
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SurveyModal
        openResult={openResult}
        setOpenResult={setOpenResult}
        rating={rating}
        completedAssessment={completedAssessment}
      />
      <UploadModal
        openUpload={openUpload}
        setOpenUpload={setOpenUpload}
      />
    </Fragment>
  );
};
export default BusinessApplicationsList;




