import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import StepZilla from "react-stepzilla";
import ConfigureResource from "./ConfigureResource";
import SelectRegion from "./SelectRegion";
import Review from "./Review";
import { useParams } from "react-router";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const OrderEC2Build = () => {  
  const { id } = useParams();
  const [instanceName, setInstanceName] = useState("");
  const [instanceType, setInstanceType] = useState("");
  const [numberOfInstances, setNumberOfInstances] = useState(1);
  const [region, setRegion] = useState("");
  const [orderName, setOrderName] = useState("");
  const [action, setAction] = useState("");
  const [statusId, setStatusId] = useState(uuidv4());
  const [status, setStatus] = useState("Waiting");

  useEffect(() => {
    if (id) {
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/orders/get-ec2-order/${id}`)
        .then((res) => {
          setInstanceName(res.data.order.instanceName);
          setInstanceType(res.data.order.instanceType);
          setNumberOfInstances(res.data.order.numberOfInstances);
          setRegion(res.data.order.region);
          setOrderName(res.data.name);
          setAction(res.data.action);
          setStatusId(res.data.order.statusId);
        })
        .catch((err) => {
          console.log(err);
          window.location = "/infrastructure/aws/ec2-build";
        });
    }
  }, [id]);

  const steps =
    [
      {
        name: "Configure Resource",
        component: <ConfigureResource
          instanceName={instanceName}
          setInstanceName={setInstanceName}
          instanceType={instanceType}
          setInstanceType={setInstanceType}
          numberOfInstances={numberOfInstances}
          setNumberOfInstances={setNumberOfInstances}
        />
      },
      {
        name: "Select Region",
        component: <SelectRegion
          region={region}
          setRegion={setRegion}
        />
      },
      {
        name: "Review",
        component: <Review
          instanceName={instanceName}
          instanceType={instanceType}
          numberOfInstances={numberOfInstances}
          region={region}
          orderName={orderName}
          setOrderName={setOrderName}
          action={action}
          setAction={setAction}
          statusId={statusId}
          setStatusId={setStatusId}
          status={status}
          setStatus={setStatus}
          id={id}
        />
      }
    ];

  return (
    <Fragment>
      <Breadcrumb title="Order" parent="EC2 Build" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Provision AWS Infrastructure</h5>
              </div>
              <div className="card-body">
                <StepZilla
                  steps={steps}
                  showSteps={true}
                  showNavigation={true}
                  stepsNavigation={true}
                  prevBtnOnLastStep={true}
                  dontValidate={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OrderEC2Build;