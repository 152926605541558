import React, { Fragment } from "react";

const Source = (props) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12">
          <form className="needs-validation" noValidate>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label>{"Host"}</label>
                <input
                  id="host"
                  className="form-control"
                  type="text"
                  placeholder="Host"
                  value={props.sourceConnectionParameters.host}
                  onChange={(e) => props.setSourceConnectionParameters({ ...props.sourceConnectionParameters, host: e.target.value })}
                  required
                />
              </div>

              <div className="col-md-12 mb-3">
                <label>{"Port"}</label>
                <input
                  id="port"
                  className="form-control"
                  type="number"
                  required
                  value={props.sourceConnectionParameters.port}
                  onChange={(e) => props.setSourceConnectionParameters({ ...props.sourceConnectionParameters, port: e.target.value })}
                />
              </div>

              <div className="col-md-12 mb-3">
                <label>{"Is Container DB?"}</label>
                <select
                  id="container"
                  className="form-control"
                  value={props.sourceConnectionParameters.container}
                  onChange={(e) => props.setSourceConnectionParameters({ ...props.sourceConnectionParameters, container: JSON.parse(e.target.value) })}
                  required
                >
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>
              </div>

              {props.sourceConnectionParameters.container &&
                <div className="col-md-12 mb-3">
                  <label>{"Container Name"}</label>
                  <input
                    id="containerName"
                    className="form-control"
                    type="text"
                    placeholder="Container Name"
                    value={props.sourceConnectionParameters.containerName}
                    onChange={(e) => props.setSourceConnectionParameters({ ...props.sourceConnectionParameters, containerName: e.target.value })}
                    required
                  />
                </div>
              }

              {props.sourceConnectionParameters.container &&
                <div className="col-md-12 mb-3">
                  <label>{"Pluggable Name"}</label>
                  <input
                    id="pluggableName"
                    className="form-control"
                    type="text"
                    placeholder="Pluggable Name"
                    value={props.sourceConnectionParameters.pluggableName}
                    onChange={(e) => props.setSourceConnectionParameters({ ...props.sourceConnectionParameters, pluggableName: e.target.value })}
                    required
                  />
                </div>
              }

              <div className="col-md-12 mb-3">
                <label>{"SID"}</label>
                <input
                  id="sid"
                  className="form-control"
                  type="text"
                  placeholder="SID"
                  value={props.sourceConnectionParameters.sid}
                  onChange={(e) => props.setSourceConnectionParameters({ ...props.sourceConnectionParameters, sid: e.target.value })}
                  required
                />
              </div>

              <div className="col-md-12 mb-3">
                <label>{"DB Version"}</label>
                <select
                  id="dbVersion"
                  className="form-control"
                  value={props.sourceConnectionParameters.dbVersion}
                  onChange={(e) => props.setSourceConnectionParameters({ ...props.sourceConnectionParameters, dbVersion: e.target.value })}
                  required
                >
                  <option value="" disabled>Select</option>
                  <option value="11.2">11.2</option>
                  <option value="12.2">12.2</option>
                  <option value="19">19</option>
                </select>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default Source;