import React from "react";
import Modal from "react-responsive-modal";
import Dropzone from "react-dropzone-uploader";
import { handleChangeStatus, getUploadParamsDatabase } from "../../../helpers/FileUpload";

const ImportModal = (props) => {
  return (
    <Modal open={props.open} onClose={() => props.setOpen(false)}>
      <div className="container-fluid">
        <div className="row">
          <h6 id="modal-title" className="f-w-600 text-align-centre">Import Database List</h6>
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Click below to upload a excel spreadsheet</h5>
              </div>
              <div className="card-body">
                <form className="dropzone dropzone-primary" id="singleFileUpload" action="/upload.php">
                  <div className="dz-message needsclick">
                    <Dropzone
                      onChangeStatus={handleChangeStatus}
                      getUploadParams={getUploadParamsDatabase}
                      maxFiles={1}
                      multiple={false}
                      canCancel={false}
                      inputContent="Drop A File"
                      accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ImportModal;