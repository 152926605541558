import React, { Fragment } from "react";
import { charactersets } from "../oci-list/CharacterSets";
import { dbworkloads } from "../oci-list/DBWorkloads";

const ConfigureDatabase = (props) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12">
          <form className="needs-validation" noValidate>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="Database Name">{"Database Name"}</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Database Name"
                  value={props.dbName}
                  onChange={(e) => props.setDbName(e.target.value)}
                  required
                />
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="Character Set">{"Character Set"}</label>
                <select
                  className="form-control"
                  value={props.characterSet}
                  onChange={(e) => props.setCharacterSet(e.target.value)}
                  required
                >
                  {charactersets.map((characterset, index) => (
                    <option key={index} value={characterset}>{characterset}</option>
                  ))}
                </select>
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="Database Workload Type">{"Database Workload Type"}</label>
                <select
                  className="form-control"
                  value={props.dbWorkload}
                  onChange={(e) => props.setDbWorkload(e.target.value)}
                  required
                >
                  {dbworkloads.map((dbworkload, index) => (
                    <option key={index} value={dbworkload}>{dbworkload}</option>
                  ))}
                </select>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default ConfigureDatabase;