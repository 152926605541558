import React, { Fragment } from "react";
import { Submit } from "../../../constant";
import axios from "axios";
import { setToast } from "../../../components/common/Toast";
import { toast } from "react-toastify";

const MigrationConfiguration = (props) => {
  const submitForm = () => {
    axios.post(props.id ? `${process.env.REACT_APP_BACKEND_URL}/api/oracle-2-oracle/migrations/${props.id}` : `${process.env.REACT_APP_BACKEND_URL}/api/oracle-2-oracle/migrations`, {
      sourceConnectionParameters: props.sourceConnectionParameters,
      targetConnectionParameters: props.targetConnectionParameters,
      migrationConfiguration: props.migrationConfiguration
    })
      .then(() => {
        window.location.assign("/database-factory/oracle-to-oracle");
      })
      .catch(() => {
        setToast(toast.error, "Unable to create migration");
      });
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-row">
            <div className="col-md-12 mb-3">
              <label>{"Name"}</label>
              <input
                id="name"
                className="form-control"
                type="text"
                placeholder="Name"
                value={props.migrationConfiguration.name}
                onChange={(e) => props.setMigrationConfiguration({ ...props.migrationConfiguration, name: e.target.value })}
                required
              />
            </div>

            <div className="col-md-12 mb-3">
              <label>{"Export Dump Directory"}</label>
              <input
                id="expdpDir"
                className="form-control"
                type="text"
                placeholder="Export Dump Directory"
                value={props.migrationConfiguration.expdpDir}
                onChange={(e) => props.setMigrationConfiguration({ ...props.migrationConfiguration, expdpDir: e.target.value })}
                required
              />
            </div>

            <div className="col-md-12 mb-3">
              <label>{"Export Dump File"}</label>
              <input
                id="exdpDumpFile"
                className="form-control"
                type="text"
                placeholder="Export Dump File"
                value={props.migrationConfiguration.exdpDumpFile}
                onChange={(e) => props.setMigrationConfiguration({ ...props.migrationConfiguration, exdpDumpFile: e.target.value })}
                required
              />
            </div>

            <div className="col-md-12 mb-3">
              <label>{"Export Log File"}</label>
              <input
                id="exdpLogFile"
                className="form-control"
                type="text"
                placeholder="Export Log File"
                value={props.migrationConfiguration.exdpLogFile}
                onChange={(e) => props.setMigrationConfiguration({ ...props.migrationConfiguration, exdpLogFile: e.target.value })}
                required
              />
            </div>

            <div className="col-md-12 mb-3">
              <label>{"Network Link"}</label>
              <input
                id="networkLink"
                className="form-control"
                type="text"
                placeholder="Network Link"
                value={props.migrationConfiguration.networkLink}
                onChange={(e) => props.setMigrationConfiguration({ ...props.migrationConfiguration, networkLink: e.target.value })}
                required
              />
            </div>

            <div className="col-md-12 mb-3">
              <label>{"Cloud Provider"}</label>
              <select
                id="cloudProvider"
                className="form-control"
                value={props.migrationConfiguration.cloudProvider}
                onChange={(e) => props.setMigrationConfiguration({ ...props.migrationConfiguration, cloudProvider: e.target.value })}
                required
              >
                <option value="" disabled>Select</option>
                <option value="aws">AWS</option>
                <option value="gcp">GCP</option>
              </select>
            </div>

            <div className="col-md-12 mb-3">
              <label>{"Bucket Name"}</label>
              <input
                id="bucketName"
                className="form-control"
                type="text"
                placeholder="Bucket Name"
                value={props.migrationConfiguration.bucketName}
                onChange={(e) => props.setMigrationConfiguration({ ...props.migrationConfiguration, bucketName: e.target.value })}
                required
              />
            </div>

            <button id="submit" className="btn btn-primary r-7 btnsubmit" onClick={submitForm}>{Submit}</button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MigrationConfiguration;