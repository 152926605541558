import React, { Fragment } from "react";
import { Submit } from "../../../constant";
import axios from "axios";

const Review = (props) => {
  const submitForm = (e) => {
    e.preventDefault();
    axios
      .post(
        props.id
          ? `${process.env.REACT_APP_BACKEND_URL}/api/orders/update-packaged-deployment-order/${props.id}`
          : `${process.env.REACT_APP_BACKEND_URL}/api/orders/packaged-deployment`,
        {
          name: props.orderName,
          order: {
            projectId: props.projectId,
            clusterName: props.clusterName,
            adminEmail: props.adminEmail,
            domainName: props.domainName,
            domainZone: props.domainZone,
            region: props.region,
            clusterZones: props.clusterZones,
            vaultUser: props.vaultUser,
            vaultPassword: props.vaultPassword,
            status: props.status,
            statusId: props.statusId
          },
          action: props.action,
          type: "packaged-deployment"
        }
      )
      .then(() => {
        window.location = "/infrastructure/google/packaged-deployment";
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12">
          <form className="needs-validation" noValidate>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label>
                  {"Project ID:"} {props.projectId}
                </label>
              </div>

              <div className="col-md-12 mb-3">
                <label>
                  {"Cluster Name:"} {props.clusterName}
                </label>
              </div>

              <div className="col-md-12 mb-3">
                <label>
                  {"Admin Email:"} {props.adminEmail}
                </label>
              </div>

              <div className="col-md-12 mb-3">
                <label>
                  {"Domain Name:"} {props.domainName}
                </label>
              </div>

              <div className="col-md-12 mb-3">
                <label>
                  {"Domain Zone:"} {props.domainZone}
                </label>
              </div>

              <div className="col-md-12 mb-3">
                <label>
                  {"Region:"} {props.region}
                </label>
              </div>

              <div className="col-md-12 mb-3">
                <label>
                  {"Cluster Zones:"} {props.clusterZones}
                </label>
              </div>

              <div className="col-md-12 mb-3">
                <label>
                  {"Vault Username:"} {props.vaultUser}
                </label>
              </div>

              <div className="col-md-12 mb-3">
                <label>
                  {"Vault Password:"} {props.vaultPassword}
                </label>
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="Order Name">{"Order Name"}</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Order Name"
                  value={props.orderName}
                  onChange={(e) => props.setOrderName(e.target.value)}
                  required
                />
              </div>

              <div className="col-md-12 mb-3">
                <label>{"Action"}</label>
                <select
                  className="form-control"
                  value={props.action}
                  onChange={(e) => props.setAction(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="PLAN">Plan</option>
                  <option value="PROVISION">Provision</option>
                  <option value="DESTROY">Destroy</option>
                </select>
              </div>

              <button
                className="btn btn-primary r-7 btnsubmit"
                onClick={(e) => submitForm(e)}
              >
                {Submit}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default Review;
