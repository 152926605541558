import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import StepZilla from "react-stepzilla";
import ConfigureDatabase from "./ConfigureDatabase";
import SelectSpecification from "./SelectSpecification";
import Review from "./Review";
import { FormWizardWithIcon } from "../../../constant";
import { useParams } from "react-router";
import axios from "axios";

const OrderOCIDatabaseBuild = () => {  
  const { id } = useParams();
  const [dbName, setDbName] = useState("");
  const [characterSet, setCharacterSet] = useState("");
  const [dbWorkload, setDbWorkload] = useState("");
  const [cpuCoreCount, setCpuCoreCount] = useState("");
  const [dataStorageSizeinTb, setDataStorageSizeinTb] = useState("");
  const [orderName, setOrderName] = useState("");
  const [action, setAction] = useState("");

  useEffect(() => {
    if (id) {
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/orders/get-oci-database-order/${id}`)
        .then((res) => {
          setDbName(res.data.order.dbName);
          setCharacterSet(res.data.order.characterSet);
          setDbWorkload(res.data.order.dbWorkload);
          setCpuCoreCount(res.data.order.cpuCoreCount);
          setDataStorageSizeinTb(res.data.order.dataStorageSizeinTb);
          setOrderName(res.data.name);
          setAction(res.data.action);
        })
        .catch((err) => {
          console.log(err);
          window.location = "/infrastructure/oracle/oci-database";
        });
    }
  }, [id]);

  const steps =
    [
      {
        name: "Configure Database",
        component: <ConfigureDatabase
          dbName={dbName}
          setDbName={setDbName}
          characterSet={characterSet}
          setCharacterSet={setCharacterSet}
          dbWorkload={dbWorkload}
          setDbWorkload={setDbWorkload}
        />
      },
      {
        name: "Select Specification",
        component: <SelectSpecification
          cpuCoreCount={cpuCoreCount}
          setCpuCoreCount={setCpuCoreCount}
          dataStorageSizeinTb={dataStorageSizeinTb}
          setDataStorageSizeinTb={setDataStorageSizeinTb}
        />
      },
      {
        name: "Review",
        component: <Review
          dbName={dbName}
          characterSet={characterSet}
          dbWorkload={dbWorkload}
          cpuCoreCount={cpuCoreCount}
          orderName={orderName}
          dataStorageSizeinTb={dataStorageSizeinTb}
          setOrderName={setOrderName}
          action={action}
          setAction={setAction}
          id={id}
        />
      }
    ];

  return (
    <Fragment>
      <Breadcrumb title="Order" parent="OCI Build" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{FormWizardWithIcon}</h5>
              </div>
              <div className="card-body">
                <StepZilla
                  steps={steps}
                  showSteps={true}
                  showNavigation={true}
                  stepsNavigation={true}
                  prevBtnOnLastStep={true}
                  dontValidate={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OrderOCIDatabaseBuild;