import React, { Fragment } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { SecondaryButton } from "../../constant";

const handleClick = () => {
  window.open("https://eu-west-2.console.aws.amazon.com/ec2/v2/home?region=eu-west-2#Home:");
};

const AWSCloud = () => {
  return (
    <Fragment>
      <Breadcrumb title="AWS Cloud" parent="Springboard" />
      <div className="container-fluid">
        <div className="card-body btn-showcase">
          <button className="btn btn-pill btn-primary btn-air-primary" onClick={handleClick} type="button">{SecondaryButton}</button>
          <hr />
          <ul id="post-list"></ul>
        </div>
      </div>
    </Fragment>
  );
};

export default AWSCloud;