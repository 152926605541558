import React from "react";
import axios from "axios";
import { setToast } from "../../../components/common/Toast";
import { toast } from "react-toastify";

const FdmButton = () => {
  const handleClick = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/fdm/get-url`)
      .then((response) => {
        window.open(response.data.url, "_blank");
      })
      .catch(() => {
        setToast(toast.error, "Unable to open FDM");
      });
  };

  return (
    <div className="container-fluid">
      <div className="card-body btn-showcase">
        <button id="fdm-link" className="btn btn-pill btn-primary btn-air-primary" onClick={handleClick} type="button">Fujitsu Database Migration</button>
      </div>
    </div>
  );
};

export default FdmButton;