import React, { Fragment } from "react";
import { Submit } from "../../../constant";
import axios from "axios";
import { setToast } from "../../../components/common/Toast";
import { toast } from "react-toastify";

const MigrationConfiguration = (props) => {
  const submitForm = () => {
    axios.post(props.id ? `${process.env.REACT_APP_BACKEND_URL}/api/postgres-2-postgres/migrations/${props.id}` : `${process.env.REACT_APP_BACKEND_URL}/api/postgres-2-postgres/migrations`, {
      sourceConnectionParameters: props.sourceConnectionParameters,
      targetConnectionParameters: props.targetConnectionParameters,
      migrationConfiguration: props.migrationConfiguration
    })
      .then(() => {
        window.location.assign("/database-factory/postgres-to-postgres");
      })
      .catch(() => {
        setToast(toast.error, "Unable to create migration");
      });
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-row">
            <div className="col-md-12 mb-3">
              <label>{"Name"}</label>
              <input
                id="name"
                className="form-control"
                type="text"
                placeholder="Name"
                value={props.migrationConfiguration.name}
                onChange={(e) => props.setMigrationConfiguration({ ...props.migrationConfiguration, name: e.target.value })}
                required
              />
            </div>
            <button id="submit" className="btn btn-primary r-7 btnsubmit" onClick={submitForm}>{Submit}</button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MigrationConfiguration;