export const roundNumberBasedOnSize = (number, digits) => {
  return number >= 1
    ? Number(number.toFixed(digits))
    : Number(number.toExponential(digits - 1));
};

export const sumServiceTotals = (data) => {
  const co2Series = [];
  const kilowattHoursSeries = [];
  const costSeries = [];

  data.forEach((estimationResult) => {
    let total = 0;
    let totalCost = 0;
    let totalWattHours = 0;

    let usesAverageCPUConstant = false;

    estimationResult.serviceEstimates.forEach((serviceEstimate) => {
      if (serviceEstimate.usesAverageCPUConstant === true) {
        usesAverageCPUConstant = true;
      }
      total += serviceEstimate.co2e;
      totalCost += serviceEstimate.cost;
      totalWattHours += serviceEstimate.kilowattHours;
    });

    co2Series.push({
      x: estimationResult.timestamp,
      y: roundNumberBasedOnSize(total, 4),
      usesAverageCPUConstant: usesAverageCPUConstant,
      cost: roundNumberBasedOnSize(totalCost, 2),
      kilowattHours: roundNumberBasedOnSize(totalWattHours, 2)
    });

    kilowattHoursSeries.push({
      x: estimationResult.timestamp,
      y: roundNumberBasedOnSize(totalWattHours, 2)
    });

    costSeries.push({
      x: estimationResult.timestamp,
      y: roundNumberBasedOnSize(totalCost, 2)
    });
  });

  return {
    co2Series,
    kilowattHoursSeries,
    costSeries
  };
};

export const carbonDataToCsv = (data) => {
  const returningArr = [["Date", "C02", "usesAverageCPUConstant", "cost", "kilowattHours"]];
  for (const obj of data["co2Series"]) {
    const tempArr = [];
    for (const objKey in obj) {
      tempArr.push(obj[objKey]);
    }
    returningArr.push(tempArr);
  }
  return returningArr;
};