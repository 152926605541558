import React from "react";
import Modal from "react-responsive-modal";
import Dropzone from "react-dropzone-uploader";
import { handleChangeStatus, getUploadParamsApplications, getUploadParamsApplicationsServiceNow } from "../../../helpers/FileUpload";

const UploadModal = (props) => {
  const [serviceNow, setServiceNow] = React.useState(false);

  const handleClose = () => {
    props.setOpenUpload(false);
    setServiceNow(false);
  };

  return (
    <Modal open={props.openUpload} onClose={handleClose}>
      <div className="container-fluid">
        <div className="row">
          <h6 className="f-w-600 text-align-centre">Upload Application List</h6>
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Click below to upload a excel spreadsheet</h5>
              </div>
              <div className="card-body">
                <form className="dropzone dropzone-primary" id="singleFileUpload" action="/upload.php">
                  <div className="checkbox p-0" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "15px" }}>
                    <input
                      id="servicenowCheckbox"
                      type="checkbox"
                      checked={serviceNow}
                      onChange={(e) => setServiceNow(e.target.checked)}
                      name="servicenowCheckbox"
                    />
                    <label htmlFor="servicenowCheckbox">ServiceNow Export</label>
                  </div>

                  <div className="dz-message needsclick">
                    <Dropzone
                      onChangeStatus={handleChangeStatus}
                      getUploadParams={(e) => {
                        /* istanbul ignore next */
                        return serviceNow ?
                          getUploadParamsApplicationsServiceNow(e)
                          :
                          getUploadParamsApplications(e);
                      }}
                      maxFiles={1}
                      multiple={false}
                      canCancel={false}
                      inputContent="Drop A File"
                      accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UploadModal;