import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import axios from "axios";

import $ from "jquery";
import * as Survey from "survey-core";
import * as SurveyReact from "survey-react-ui";
import * as widgets from "surveyjs-widgets";
import "select2/dist/js/select2.js";

import "survey-react/survey.css";
import "survey-core/survey.css";
import "select2/dist/css/select2.min.css";
import { useAuthUser } from "../../authUserContext";
import { Link } from "react-router-dom";
import { useParams } from "react-router";

//Survey.StylesManager.applyTheme("modern");
window["$"] = window["jQuery"] = $;
widgets.select2(Survey, $);
widgets.select2tagbox(Survey, $);

const SustainabilityEdit = () => {
  const { authUser } = useAuthUser();
  let [showButton, setShowButton] = useState(false);
  let [survey, setSurvey] = useState(new Survey.Model());
  const { id } = useParams();

  const sendDataToServer = (sender) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/update-completed-sustainability-assessment/${id}`, {
      user: authUser.name,
      completedAssessment: sender.data
    });
    setShowButton(!showButton);
  };
  useEffect(() => {
    let pueList = [];
    let co2List = [];
    let co2ListEdited = [];
    let surveyData;
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/factor-repository/get-all-factors`)
      .then((res) => {
        res.data.map((factor) => {
          if (factor.type === "PUE") pueList.push({ value: factor.description, text: `${factor.description} (${factor.value}) ref: ${factor.reference ? factor.reference : "No reference"} [${factor.date}]` });
          else if (factor.type === "CO2/KWh") co2List.push({ value: factor.description, text: `${factor.description} (${factor.value},) ${factor.reference} [${factor.date}]` });

        });
        res.data.map((factor) => {
          co2List.map((co2) => {          
            if (new RegExp("\\b" + factor.reference + "\\b").test(co2.text.toString()) && factor.type === "CO2e/KWh") {
              co2ListEdited.push({ value: factor.description, text: `${co2.text.replace(",", `, ${factor.value}`)}` });
            }
          });
        });
      }).then(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/assessments/get-all`)
          .then((res) => {
            res.data[1].assessment.pages[0].elements[1].choices = pueList;
            res.data[1].assessment.pages[0].elements[4].choices = co2ListEdited;
            surveyData = res.data[1].assessment;
          }).then(() => {
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/sustainability/get/${id}`)
              .then((res) => {
                const tempSurvey = new Survey.Model(JSON.stringify(surveyData));
                tempSurvey.data = res.data.completedAssessment; 
                setSurvey(tempSurvey);
              }).catch((err) => {
                console.log(err);
              });
          }).catch((err) => {
            console.log(err);
          });
      }).catch((err) => {
        console.log(err);
      });

  }, []);

  //add tagbox into matrix columns (run-time)
  Survey.matrixDropdownColumnTypes.tagbox = {
    onCellQuestionUpdate: function (cellQuestion, column, question, data) {
      Survey.matrixDropdownColumnTypes.checkbox.onCellQuestionUpdate(
        cellQuestion,
        column,
        question,
        data
      );
    }
  };


  return (
    <Fragment>
      <Breadcrumb title="Sustainability Assessment" parent="Sustainability" />
      {!showButton ? (<SurveyReact.Survey model={survey} onComplete={sendDataToServer} />) :
        (<div className="card">
          <div className="card-header">
            <h5>{"Thank you for Completing the Assessment"}</h5>
          </div>
          <form>
            <div className="card-footer text-center">
              <Link to={"/sustainability/sustainability-result"}><button className="btn btn-primary">Show Result</button></Link></div>
          </form>
        </div>)}
    </Fragment>
  );
};

export default SustainabilityEdit;