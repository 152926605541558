import React from "react";
import Modal from "react-responsive-modal";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { SingleFileUpload } from "../../constant";
import { getUploadParamsFactors, handleChangeStatus } from "../../helpers/FileUpload";

const ImportFactorsModal = (props) => {
  const onCloseModalUpload = () => {
    props.setOpenUpload(false);
    props.getFactorsRepository();
  };

  return (
    <Modal open={props.openUpload} onClose={onCloseModalUpload}>
      <div className="container-fluid">
        <div className="row">
          <h6 className="f-w-600 text-align-centre">Upload Factors Repository List</h6>
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{SingleFileUpload}</h5>
              </div>
              <div className="card-body">
                <form className="dropzone dropzone-primary" id="singleFileUpload" action="/upload.php">
                  <div className="dz-message needsclick">
                    <Dropzone
                      onChangeStatus={handleChangeStatus}
                      getUploadParams={getUploadParamsFactors}
                      maxFiles={1}
                      multiple={false}
                      canCancel={false}
                      inputContent="Drop A File"
                      accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ImportFactorsModal;