import React, { Fragment } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import DataTable from "react-data-table-component";
import axios from "axios";
import "react-dropzone-uploader/dist/styles.css";
import { toast } from "react-toastify";
import FilterComponent from "../../../components/common/FilterComponent";
import { Link } from "react-router-dom";
import { setToast } from "../../../components/common/Toast";
import ImportModal from "./ImportModal";
import DiscoveryDropDown from "../../../components/common/DiscoveryDropDown";
import { tableStyles } from "../../../components/common/CustomStyles";
import { Edit, ExternalLink, Trash } from "react-feather";

const DatabaseList = () => {
  const [discoveries, setDiscoveries] = React.useState([]);
  const [discovered, setDiscovered] = React.useState(undefined);
  const [discoveryId, setDiscoveryId] = React.useState(undefined);
  const [databases, setDatabases] = React.useState([]);
  const [jiradomain, setJiradomain] = React.useState(undefined);
  const [filteredItems, setFilteredItems] = React.useState([]);
  const [pending, setPending] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [clearRows, setClearRows] = React.useState(false);

  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/databases/get-discoveries`)
      .then((response) => {
        setDiscoveries(response.data.reverse());
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve discoveries");
      });
  }, []);

  React.useEffect(() => {
    if (discoveries.length > 0) {
      setDiscovered(discoveries[0]);
    }
  }, [discoveries]);

  const onOpenModal = () => {
    setOpen(true);
  };

  const getDatabases = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/databases/get/${discovered}`)
      .then((response) => {
        setDatabases(response.data.databases.databases);
        setDiscoveryId(response.data.databases._id);
        setJiradomain(response.data.jiradomain);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve databases");
        setDatabases(null);
      });

    setPending(false);
  };

  React.useEffect(() => {
    if (!discovered) {
      return;
    }

    getDatabases();
  }, [discovered]);

  React.useEffect(() => {
    setFilteredItems((databases || []).filter(
      (item) => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()))
    );
  }, [filterText, databases]);

  const createJiraTickets = () => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/jira`, {
      items: selectedRows,
      type: "database",
      discoveryId: discoveryId
    })
      .then(() => {
        setToast(toast.success, "Jira tickets created");
        setClearRows(true);
        setSelectedRows([]);
        setFilteredItems([]);
        setTimeout(() => getDatabases(), 1000);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setToast(toast.error, "No longer part of a migration group");
          setClearRows(true);
          setSelectedRows([]);
          setFilteredItems([]);
          setTimeout(() => getDatabases(), 1000);
        } else {
          setToast(toast.error, "Unable to create Jira tickets");
        }
      });
  };

  React.useEffect(() => {
    setClearRows(false);
  }, [clearRows]);

  const createDiscovery = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/databases/discover`)
      .then(() => {
        setToast(toast.success, "Discovery started");
      })
      .catch(() => {
        setToast(toast.error, "Unable to start discovery");
      });
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
        <div>
          <button id="create-discovery" key="discovery" style={{ marginLeft: "5px", marginRight: "5px" }} className="btn btn-primary" onClick={() => createDiscovery()} type="button">Create Discovery</button>
          <button id="import-databases" style={{ marginLeft: "5px", marginRight: "5px" }} key="import" className="btn btn-primary" onClick={() => onOpenModal()} type="button">Import Databases</button>
          <Link id="create-database" to={`/discovery/databases/add/${discoveryId}`}>
            <button key="create" style={{ marginLeft: "5px", marginRight: "5px" }} className="btn btn-primary" type="button">Create Database</button>
          </Link>
          <button id="jira-create" key="jira" style={{ marginLeft: "5px", marginRight: "5px" }} disabled={selectedRows.length === 0} className="btn btn-primary" onClick={() => createJiraTickets()} type="button">Create Jira Tickets</button>
        </div>
        <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholder={"Name"} />
      </div>
    );
  }, [filterText, resetPaginationToggle, selectedRows, discoveryId]);

  const deleteJiraTicket = (data) => {
    axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/jira`, {
      data: {
        databaseId: data._id,
        jirakey: data.jirakey,
        type: "database",
        discoveryId: discoveryId
      }
    })
      .then(() => {
        setToast(toast.success, "Jira ticket deleted");
        setClearRows(true);
        setSelectedRows([]);
        setFilteredItems([]);
        setTimeout(() => getDatabases(), 1000);
      })
      .catch(() => {
        setToast(toast.error, "Unable to delete Jira ticket");
      });
  };

  const downloadAssessmentReport = async (data, type) => {
    setToast(toast.success, `Downloading ${type} report...`);
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/assessments/${data.assessmentId}/report/${type}`, {
      responseType: "arraybuffer"
    })
      .then((res) => {
        setToast(toast.success, `Downloaded ${type} report`);
        const blob = new Blob([res.data], { type: type === "PDF" ? "application/pdf" : "text/csv" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", type === "PDF" ? `${data.name} Summary.pdf` : `${data.name} Incompatibilities.csv`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        setToast(toast.error, `Unable to download ${type} report`);
      });
  };

  const columns = [
    {
      name: "Name",
      selector: (database) => database.name,
      center: true,
      sortable: true,
      wrap: true,
      width: "13rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Edit",
      center: true,
      sortable: true,
      wrap: true,
      width: "6rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) => <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        <Link id={`edit-${data._id}`} to={`/discovery/databases/edit/${discoveryId}/${data._id}`}>
          <button className="btn btn-pill" style={{ backgroundColor: "#22af47" }}>
            <Edit style={{ height: "15px", width: "auto", color: "white" }} />
          </button>
        </Link>
      </div>
    },
    {
      name: "Jira",
      center: true,
      sortable: true,
      wrap: true,
      width: "6rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) =>
        data.jirakey && data.jirakey !== "INVALID" ?
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <button id={`jira-view-${data._id}`} className="btn btn-pill" style={{ margin: "5px 0px 5px 0px", backgroundColor: "#007bff" }} onClick={() => window.open(`${jiradomain}/browse/${data.jirakey}`, "_blank")}>
              <ExternalLink style={{ height: "15px", width: "auto", color: "white" }} />
            </button>
            <button id={`jira-delete-${data._id}`} className="btn btn-pill" style={{ margin: "5px 0px 5px 0px", backgroundColor: "#e83845" }} onClick={() => deleteJiraTicket(data)}>
              <Trash style={{ height: "15px", width: "auto", color: "white" }} />
            </button>
          </div>
          :
          null
    },
    {
      name: "Assessment",
      center: true,
      sortable: true,
      wrap: true,
      width: "13rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) =>
        data.assessmentId ?
          <div>
            <button id={`download-assessment-pdf-${data._id}`} className="btn btn-primary" style={{ margin: "5px 0px 5px 0px", width: "175px" }} onClick={() => downloadAssessmentReport(data, "PDF")}>Summary</button>
            <button id={`download-assessment-incompatibilities-${data._id}`} className="btn btn-primary" style={{ margin: "5px 0px 5px 0px", width: "175px" }} onClick={() => downloadAssessmentReport(data, "incompatibilities")}>Incompatibilities</button>
          </div>
          :
          null
    },
    {
      name: "Vendor",
      selector: (database) => database.vendor,
      center: true,
      sortable: true,
      wrap: true,
      width: "10rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "IP",
      selector: (database) => database.ip,
      center: true,
      sortable: true,
      wrap: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Discovered By",
      selector: (database) => database.discovered,
      center: true,
      sortable: true,
      wrap: true,
      width: "11rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    }
  ];

  return (
    <Fragment>
      <Breadcrumb title="Databases" parent="Discovery" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{"Table of Databases"}</h5>
              </div>
              <div className="card-body datatable-react">
                <DiscoveryDropDown
                  discovered={discovered}
                  setDiscovered={setDiscovered}
                  discoveries={discoveries}
                />
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  customStyles={tableStyles}
                  progressPending={pending}
                  striped={true}
                  center={true}
                  subHeaderComponent={subHeaderComponentMemo}
                  subHeader
                  persistTableHead
                  pagination
                  responsive
                  selectableRows
                  onSelectedRowsChange={(e) => {
                    /* istanbul ignore next */
                    setSelectedRows(e.selectedRows);
                  }}
                  clearSelectedRows={clearRows}
                  selectableRowDisabled={(row) => {
                    /* istanbul ignore next */
                    return row.jirakey !== "";
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ImportModal
        open={open}
        setOpen={setOpen}
      />
    </Fragment>
  );
};
export default DatabaseList;