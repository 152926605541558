import React from "react";
import { SecureRoute } from "@okta/okta-react";
import { Redirect } from "react-router-dom";
import { useAuthUser } from "../authUserContext";
import { useOktaAuth } from "@okta/okta-react";

const RoleRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useAuthUser();
  const { authState } = useOktaAuth();

  if (!authState) {
    return null;
  }

  if (authState && !authState.isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if (!authUser) {
    return null;
  }

  if (authUser.sections.includes(rest.section)) {
    return <SecureRoute {...rest} render={(props) => <Component {...props} />} />;
  } else {
    return <SecureRoute {...rest} render={() => <Redirect to="/" />} />;
  }
};

export default RoleRoute;