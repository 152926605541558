import React, { Fragment } from "react";
import Countup from "react-countup";
import axios from "axios";
import { setToast } from "../../../components/common/Toast";
import { toast } from "react-toastify";

const HomeComponent = () => {
  const [projects, setProjects] = React.useState([]);
  const [projectIndex, setProjectIndex] = React.useState(0);
  const [swimlanes, setSwimlanes] = React.useState(undefined);

  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/jira/projects`)
      .then((res) => {
        setProjects(res.data);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve Jira projects");
      });
  }, []);

  React.useEffect(() => {
    if (projects.length > 0) {
      setSwimlanes(undefined);
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/jira/swimlanes/${projects[projectIndex].jiraproject}`)
        .then((res) => {
          setSwimlanes(res.data);
        })
        .catch(() => {
          setToast(toast.error, "Unable to retrieve Jira swimlanes");
          setSwimlanes(null);
        });
    }
  }, [projects, projectIndex]);

  return (
    <Fragment>
      <div className="tab-content active default" id="tab-1">
        <div className="row">
          <div style={{ display: "flex", alignItems: "center" }}>
            <select style={{ width: "300px", marginLeft: "10px", marginRight: "10px" }} id="projects" className="form-control" name="projects" value={projectIndex} onChange={(e) => setProjectIndex(e.target.value)}>
              {projects.map((project, index) => {
                return (
                  <option key={index} value={index}>{project.name}</option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="row">
          {swimlanes ?
            swimlanes.map((item) => {
              return (
                <div key={item.swimlane} className="col-xl-3 col-sm-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <h5 className="mb-0">{item.swimlane}</h5>
                      </div>
                      <div className="project-widgets text-center">
                        <h1 className="font-primary counter">
                          <Countup end={item.issues} /></h1>
                      </div>
                    </div>
                    <div className="card-footer project-footer">
                      <center>
                        <h6 className="mb-0">
                          <span className="counter ml-1">
                            {item.description}
                          </span>
                        </h6>
                      </center>
                    </div>
                  </div>
                </div>
              );
            })
            :
            swimlanes === undefined ?
              <div id="error" className="col-xl-3 col-sm-6">
                <p>Loading...</p>
              </div>
              :
              <div id="error" className="col-xl-3 col-sm-6">
                <p>Unable to retrieve Jira swimlanes.</p>
                <p>Please try again.</p>
              </div>
          }
        </div>
      </div>
    </Fragment>
  );
};

export default HomeComponent;