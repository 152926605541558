import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import DataTable from "react-data-table-component";
import axios from "axios";
import FilterComponent from "../../../components/common/FilterComponent";
import { tableStyles } from "../../../components/common/CustomStyles";

const AzureDevopsGCPBuild = () => {
  const [orders, setOrders] = useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");

  const getAllOrders = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/orders/get-all-azuredevopsvm-orders`)
      .then((response) => {
        setOrders(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllOrders();
  }, []);

  const deleteOrder = (id) => {
    if (window.confirm("Are you sure you want to delete this order?")) {
      axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/orders/delete-azuredevopsvm-order/${id}`)
        .then(() => {
          getAllOrders();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const columns = [
    {
      name: "Name",
      selector: "name",
      center: true,
      wrap: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Action",
      selector: "action",
      center: true,
      wrap: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Create Date",
      selector: "dateCreated",
      center: true,
      wrap: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Update Date",
      selector: "dateUpdated",
      center: true,
      wrap: true,
      sortable: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Actions",
      selector: "actions",
      center: true,
      width: "13rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) => <div>
        <button className="btn btn-primary" style={{ margin: "5px 0px 5px 0px", width: "175px" }} onClick={() => window.location = `/infrastructure/google/azuredevops-gcp-vm/order/${data._id}`}>Edit</button>
        <button className="btn btn-primary" disabled={data.taskAllCount} style={{ margin: "5px 0px 5px 0px", width: "175px" }} onClick={() => deleteOrder(data._id)}>Delete</button>
      </div>
    }
  ];

  const filteredItems = orders.filter(
    (item) => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <div>
          <button className="btn btn-primary" onClick={() => window.location = "/infrastructure/google/azuredevops-gcp-vm/order"} type="button">Order</button>
        </div>
        <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholder={"Project"} />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <Fragment>
      <Breadcrumb title="GCP Build" parent="Infrastructure" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{"GCP Build"}</h5>
              </div>
              <div className="card-body datatable-react">
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  striped={true}
                  center={true}
                  customStyles={tableStyles}
                  subHeaderComponent={subHeaderComponentMemo}
                  subHeader
                  persistTableHead
                  pagination
                  responsive
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AzureDevopsGCPBuild;