import React from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import { setToast } from "../../components/common/Toast";
import { tableStyles } from "../../components/common/CustomStyles";

function DatabaseAssessmentResults(props) {
  const [assessments, setAssessments] = React.useState(undefined);
  const [pending, setPending] = React.useState(true);

  const getAssessments = React.useCallback(() => {
    setPending(true);
    if (!props.migrationProjects || props.migrationProjectIndex === undefined) {
      setAssessments(undefined);
      return;
    }

    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/fdm/assessments/${props.migrationProjects[props.migrationProjectIndex].migrationProjectId}`, {})
      .then((res) => {
        setAssessments(res.data);
        setPending(false);
      })
      .catch(() => {
        setToast(toast.error, "Unable to retrieve assessments");
        setAssessments([]);
        setPending(false);
      });
  }, [props.migrationProjects, props.migrationProjectIndex]);

  React.useEffect(() => {
    getAssessments();
  }, [props.migrationProjects, props.migrationProjectIndex]);

  const downloadAssessmentReport = (data, type) => {
    setToast(toast.success, `Downloading ${type} report...`);
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/assessments/${data.assessmentProcessId}/report/${type}`, {
      responseType: "arraybuffer"
    })
      .then((res) => {
        setToast(toast.success, `Downloaded ${type} report`);
        toast.success(`Downloaded ${type} report`);
        const blob = new Blob([res.data], { type: type === "PDF" ? "application/pdf" : "text/csv" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", type === "PDF" ? `${data.description.replace(/^Database /, "")} Summary.pdf` : `${data.description.replace(/^Database /, "")} Incompatibilities.csv`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        setToast(toast.error, `Unable to download ${type} report`);
      });
  };

  const deleteAssessment = (data) => {
    axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/assessments/${data.assessmentProcessId}`)
      .then(() => {
        setToast(toast.success, "Assessment deleted");
        getAssessments();
      })
      .catch(() => {
        setToast(toast.error, "Unable to delete assessment");
      });
  };

  /* istanbul ignore next */
  const columns = [
    {
      name: "Description",
      selector: (assessment) => assessment.description,
      center: true,
      sortable: true,
      wrap: true,
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "State",
      selector: (assessment) => assessment.state,
      center: true,
      sortable: true,
      wrap: true,
      width: "10rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Start Date",
      selector: (assessment) => assessment.startDate,
      center: true,
      sortable: true,
      wrap: true,
      width: "15rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Stop Date",
      selector: (assessment) => assessment.processStopDate,
      center: true,
      sortable: true,
      wrap: true,
      width: "15rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Duration",
      selector: (assessment) => assessment.processDuration,
      center: true,
      sortable: true,
      wrap: true,
      width: "15rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      }
    },
    {
      name: "Actions",
      center: true,
      sortable: true,
      wrap: true,
      width: "13rem",
      style: {
        borderRight: "1px solid #E5E5E5"
      },
      cell: (data) => <div>
        <button id={`download-assessment-pdf-${data.assessmentProcessId}`} className="btn btn-primary" style={{ margin: "5px 0px 5px 0px", width: "175px" }} disabled={data.state !== "SUCCESS"} onClick={() => downloadAssessmentReport(data, "PDF")}>Summary</button>
        <button id={`download-assessment-incompatibilities-${data.assessmentProcessId}`} className="btn btn-primary" style={{ margin: "5px 0px 5px 0px", width: "175px" }} disabled={data.state !== "SUCCESS"} onClick={() => downloadAssessmentReport(data, "incompatibilities")}>Incompatibilities</button>
        <button id={`delete-${data.assessmentProcessId}`} className="btn btn-primary" style={{ margin: "5px 0px 5px 0px", width: "175px" }} onClick={() => deleteAssessment(data)}>Delete</button>
      </div >
    }
  ];

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <div>
          <button className="btn btn-primary" onClick={getAssessments} type="button">Refresh</button>
        </div>
      </div>
    );
  }, []);

  if (!props.migrationProjects || props.migrationProjectIndex === undefined) {
    return null;
  } else {
    return (
      <DataTable
        columns={columns}
        data={assessments}
        customStyles={tableStyles}
        progressPending={pending}
        striped={true}
        center={true}
        subHeaderComponent={subHeaderComponentMemo}
        subHeader
        persistTableHead
        pagination
        responsive
      />
    );
  }
}

export default DatabaseAssessmentResults;