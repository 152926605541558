import React, { Fragment } from "react";
import { projectId } from "../gcp-list/PackagedDeploymentProps";

const ConfigureProject = (props) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12">
          <form className="needs-validation" noValidate>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="Project ID">{"Project ID"}</label>
                <select
                  className="form-control"
                  value={props.projectId}
                  onChange={(e) => props.setProjectId(e.target.value)}
                  required
                >
                  <option key="" value="" disabled>
                    Select
                  </option>
                  {projectId.map((projectId, index) => (
                    <option key={index} value={projectId}>
                      {projectId}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-12 mb-3">
                <label htmlFor="Cluster Name">{"Cluster Name"}</label>
                <input
                  className="form-control"
                  type="string"
                  placeholder="example-name"
                  required
                  value={props.clusterName}
                  onChange={(e) => props.setClusterName(e.target.value)}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label htmlFor="Admin Email">{"Admin Email"}</label>
                <input
                  className="form-control"
                  type="string"
                  placeholder="admin@fujitsu.com"
                  required
                  value={props.adminEmail}
                  onChange={(e) => props.setAdminEmail(e.target.value)}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label htmlFor="Domain Name">{"Domain Name"}</label>
                <input
                  className="form-control"
                  type="string"
                  placeholder="fj-packaged-deployment.net."
                  readOnly
                  value={props.domainName}
                  onChange={(e) => props.setDomainName(e.target.value)}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label htmlFor="Domain Zone">{"Domain Zone"}</label>
                <input
                  className="form-control"
                  type="string"
                  placeholder="fj-packaged-deployment-zone"
                  readOnly
                  value={props.domainZone}
                  onChange={(e) => props.setDomainZone(e.target.value)}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default ConfigureProject;
